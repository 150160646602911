export const teacherCreateGame = (object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_CREATE_GAME',
    payload: {
      question_set_object_id: object_id,
    },
  });
};

export const teacherEditGame = (object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_EDIT_GAME',
    payload: {
      question_set_object_id: object_id,
    },
  });
};

export const teacherDeleteGame = (object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_DELETE_GAME',
    payload: {
      question_set_object_id: object_id,
    },
  });
};

export const teacherDeletePlayer = (object_id: string, team_object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_REMOVE_PLAYER',
    payload: {
      player_object_id: object_id,
      team_object_id: team_object_id,
    },
  });
};

export const teacherEditPlayer = (object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_UPDATE_PLAYER_NAME',
    payload: {
      player_object_id: object_id,
    },
  });
};

export const teacherGameCreated = (object_id: string) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_GAME_CREATED',
    payload: {
      question_set_object_id: object_id,
    },
  });
};

export const teacherStartGame = (gameDetails: { object_id: string; model_id: string; rounds: string[] }) => (
  dispatch: any
) => {
  dispatch({
    type: 'TEACHER_START_GAME',
    payload: {
      gameDetails,
    },
  });
};

export const teacherEndGame = (gameDetails: { object_id: string; model_id: string }) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_END_GAME',
    payload: {
      gameDetails,
    },
  });
};

export const teacherStartedGame = (gameDetails: { object_id: string; model_id: string }) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_STARTED_GAME',
    payload: {
      gameDetails,
    },
  });
};

export const teacherUpdateRound = (gameDetails: { model_id: string; object_id: string; round_id: string }) => (
  dispatch: any
) => {
  dispatch({
    type: 'TEACHER_UPDATE_ROUND',
    payload: {
      gameDetails,
    },
  });
};

export const teacherIncreaseRoundTimeLimit = (gameDetails: { model_id: string; time: number; round_id: string }) => (
  dispatch: any
) => {
  dispatch({
    type: 'TEACHER_UPDATE_ROUND_TIME_LIMIT',
    payload: {
      gameDetails,
    },
  });
};

export const teacherAwardPoints = (awardPointsDetails: {
  team_object_id: string;
  points: number;
  startASteal?: string | boolean;
  partiallyCorrect?: boolean;
}) => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_AWARD_POINTS',
    payload: {
      awardPointsDetails,
    },
  });
};

export const teacherAllowSteal = () => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_ALLOW_STEAL',
  });
};

export const teacherForceUpdateZoinkster = () => (dispatch: any) => {
  dispatch({
    type: 'TEACHER_FORCE_UPDATE_ZOINKSTER',
  });
};
