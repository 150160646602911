import { updatePlayerStatus } from './../../graphql/mutations';
import { getGameById } from './../../graphql/queries';
import { eventChannel } from 'redux-saga';
import { put, select, takeEvery, take, call, delay } from 'redux-saga/effects';
import { StudentStore, TeacherStore } from '../interfaces/storeInterface';
import { API, graphqlOperation } from 'aws-amplify';
import { onGameUpdated, onRoundUpdated, onTeamUpdated } from '../../graphql/subscriptions';
import { Observable } from 'redux';
import { SubscriptionLike } from 'rxjs';
import { getGame, getRound } from '../../graphql/queries';

const getCurrentStudentState = (state: any) => state.StudentStore;
const getCurrentTeacherState = (state: any) => state.TeacherStore;

const websockets: SubscriptionLike[] = [];
const setIntervals: any[] = [];

const onGameUpdatedWebsocketRequest = async (gameModelId?: string, gameObjectId?: string) => {
  return API.graphql(
    graphqlOperation(onGameUpdated, {
      model_id: gameModelId,
      object_id: gameObjectId,
    })
  ) as unknown as Observable<any>;
};

const onRoundUpdatedWebsocketRequest = async (gameModelId?: string) => {
  return API.graphql(
    graphqlOperation(onRoundUpdated, {
      model_id: gameModelId,
    })
  );
};

const onTeamUpdatedWebsocketRequest = async (gameObjectId?: string) => {
  return API.graphql(
    graphqlOperation(onTeamUpdated, {
      object_id: gameObjectId,
    })
  );
};

const getLatestGame = async (currentGameCode: string, currentModelId: string) => {
  return API.graphql(
    graphqlOperation(getGameById, {
      input: {
        model_id: currentModelId,
        object_id: currentGameCode,
      },
    })
  );
};

const getLatestRound = async (currentRoundObjectId: string) => {
  if (currentRoundObjectId !== 'READY') {
    return API.graphql(
      graphqlOperation(getRound, {
        input: {
          object_id: currentRoundObjectId,
        },
      })
    );
  } else {
    return new Promise((resolve) => {
      resolve({});
    });
  }
};

const putPlayerStatus = async (game_model_id: string, player_object_id: string) => {
  return API.graphql(
    graphqlOperation(updatePlayerStatus, {
      input: {
        game_model_id,
        player_object_id,
      },
    })
  );
};

function closeAllWebSocketsAndCancelIntervals(): any {
  websockets?.forEach((websocket) => {
    websocket?.unsubscribe();
  });

  setIntervals?.forEach((setInterval) => {
    clearInterval(setInterval);
  });
}

function monitorGameViaPolling(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    getLatestGame(backendGameInfo?.object_id || '', backendGameInfo?.model_id || '').then(
      (getGameResponse: any) => {
        if (getGameResponse.data.get_Game_By_Id && isTeacher) {
          return emitter({
            type: 'TEACHER_GAME_UPDATED',
            payload: {
              backendGameInfo: getGameResponse.data.get_Game_By_Id,
            },
          });
        } else if (getGameResponse.data.get_Game_By_Id) {
          return emitter({
            type: 'STUDENT_GAME_UPDATED',
            payload: {
              backendGameInfo: getGameResponse.data.get_Game_By_Id,
              errors: getGameResponse?.errors,
            },
          });
        }
      },
      (data) => {
        //If the player has been removed/kicked from the game by a teacher
        if (!isTeacher && data.errors?.length && data.errors[0]?.message?.indexOf('not in game!') !== -1) {
          return emitter({
            type: 'STUDENT_KICKED',
            payload: {},
          });
        } else if (!isTeacher) {
          return emitter({
            type: 'STUDENT_GAME_UPDATED_FAILED',
            payload: {},
          });
        }
      }
    );

    return () => {
      console.log('unsub req');
    };
  });
}

function monitorOnGameUpdatedWebsocket(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    onGameUpdatedWebsocketRequest(backendGameInfo?.model_id, backendGameInfo?.object_id).then(
      (onGameUpdatedWebsocketResponse: any) => {
        // @ts-ignore
        websockets.push(
          onGameUpdatedWebsocketResponse.subscribe(
            (subscriptionResponse: any) => {
              if (subscriptionResponse.value.data.on_Game_Updated && isTeacher) {
                return emitter({
                  type: 'TEACHER_GAME_UPDATED',
                  payload: {
                    backendGameInfo: subscriptionResponse.value.data.on_Game_Updated,
                  },
                });
              } else if (subscriptionResponse.value.data.on_Game_Updated) {
                return emitter({
                  type: 'STUDENT_GAME_UPDATED',
                  payload: {
                    backendGameInfo: subscriptionResponse.value.data.on_Game_Updated,
                  },
                });
              }
            },
            () => {
              // If something bad happens to this websocket start a new one
              monitorWebsocket(monitorOnGameUpdatedWebsocket);
            }
          )
        );
      }
    );

    return () => {
      console.log('unsub req');
    };
  });
}

function monitorRoundViaPolling(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    const lastRoundId = backendGameInfo?.rounds[backendGameInfo?.rounds.length - 1];
    // If all rounds are done, get the last one instead
    getLatestRound(
      (backendGameInfo?.status.substring(backendGameInfo?.status.indexOf(':') + 1) === 'DONE'
        ? lastRoundId
        : backendGameInfo?.status.substring(backendGameInfo?.status.indexOf(':') + 1)) || ''
    ).then(
      (getRoundResponse: any) => {
        if (!getRoundResponse.data) {
          setTimeout(() => {
            return emitter({
              type: 'GET_ROUND_POLL',
            });
          }, 2000);
        } else if (getRoundResponse.data.get_Round && isTeacher && backendGameInfo) {
          if (getRoundResponse.data.get_Round.status === 'DONE') {
            //If round is done clear out any steals that happened ahead of the next round
            return emitter({
              type: 'TEACHER_ROUND_UPDATED',
              payload: {
                currentRound: getRoundResponse.data.get_Round,
                stealInProgress: false,
              },
            });
          } else {
            return emitter({
              type: 'TEACHER_ROUND_UPDATED',
              payload: {
                currentRound: getRoundResponse.data.get_Round,
              },
            });
          }
        } else if (getRoundResponse.data.get_Round && backendGameInfo) {
          if (getRoundResponse.data.get_Round.status === 'DONE') {
            //If round is done clear out the last answer for the student
            return emitter({
              type: 'STUDENT_ROUND_UPDATED',
              payload: {
                currentRound: getRoundResponse.data.get_Round,
                myLastAnswer: null,
              },
            });
          } else {
            return emitter({
              type: 'STUDENT_ROUND_UPDATED',
              payload: {
                currentRound: getRoundResponse.data.get_Round,
              },
            });
          }
        }
      },
      () => {
        if (!isTeacher) {
          return emitter({
            type: 'STUDENT_ROUND_UPDATED_FAILED',
            payload: {},
          });
        }
      }
    );

    return () => {
      console.log('unsub req');
    };
  });
}

function setStudentAliveViaPolling(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    const playerIdWithoutName = sessionStorage
      .getItem('myPlayerId')
      ?.substring(0, sessionStorage.getItem('myPlayerId')?.lastIndexOf(':'));
    putPlayerStatus(backendGameInfo?.model_id ?? '', playerIdWithoutName ?? '').then(
      () => {
        return emitter({
          type: 'STUDENT_ALIVE',
          payload: {},
        });
      },
      () => {
        return emitter({
          type: 'STUDENT_ALIVE_FAILED',
          payload: {},
        });
      }
    );

    return () => {
      console.log('unsub req');
    };
  });
}

function monitorOnRoundUpdatedWebsocket(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    onRoundUpdatedWebsocketRequest(backendGameInfo?.model_id).then((onRoundUpdatedWebsocketResponse) => {
      websockets.push(
        // @ts-ignore
        onRoundUpdatedWebsocketResponse.subscribe(
          (subscriptionResponse: any) => {
            if (subscriptionResponse.value.data.on_Round_Updated && isTeacher && backendGameInfo) {
              if (subscriptionResponse.value.data.on_Round_Updated.status === 'DONE') {
                //If round is done clear out any steals that happened ahead of the next round
                return emitter({
                  type: 'TEACHER_ROUND_UPDATED',
                  payload: {
                    currentRound: subscriptionResponse.value.data.on_Round_Updated,
                    stealInProgress: false,
                  },
                });
              } else {
                return emitter({
                  type: 'TEACHER_ROUND_UPDATED',
                  payload: {
                    currentRound: subscriptionResponse.value.data.on_Round_Updated,
                  },
                });
              }
            } else if (subscriptionResponse.value.data.on_Round_Updated && backendGameInfo) {
              if (subscriptionResponse.value.data.on_Round_Updated.status === 'DONE') {
                //If round is done clear out the last answer for the student
                return emitter({
                  type: 'STUDENT_ROUND_UPDATED',
                  payload: {
                    currentRound: subscriptionResponse.value.data.on_Round_Updated,
                    myLastAnswer: null,
                  },
                });
              } else {
                return emitter({
                  type: 'STUDENT_ROUND_UPDATED',
                  payload: {
                    currentRound: subscriptionResponse.value.data.on_Round_Updated,
                  },
                });
              }
            }
          },
          () => {
            // If something bad happens to this websocket start a new one
            monitorWebsocket(monitorOnRoundUpdatedWebsocket);
          }
        )
      );
    });

    return () => {
      console.log('unsub req');
    };
  });
}

function monitorOnTeamUpdatedWebsocket(
  backendGameInfo: StudentStore['backendGameInfo'] | TeacherStore['backendGameInfo'],
  isTeacher: boolean
): any {
  // @ts-ignore
  return eventChannel((emitter) => {
    //Subscribe to team 1
    onTeamUpdatedWebsocketRequest(
      backendGameInfo?.teams[0].substring(0, backendGameInfo?.teams[0].lastIndexOf(':'))
    ).then((onTeamUpdatedWebsocketResponse) => {
      websockets.push(
        // @ts-ignore
        onTeamUpdatedWebsocketResponse.subscribe(
          (subscriptionResponse: any) => {
            return emitter({
              type: 'TEACHER_TEAM_UPDATED',
              payload: {
                teamStatus: subscriptionResponse.value.data.on_Team_Updated,
              },
            });
          },
          () => {
            // If something bad happens to this websocket start a new one
            monitorWebsocket(monitorOnTeamUpdatedWebsocket);
          }
        )
      );
    });

    //Subscribe to team 2
    onTeamUpdatedWebsocketRequest(
      backendGameInfo?.teams[1].substring(0, backendGameInfo?.teams[1].lastIndexOf(':'))
    ).then(
      (onTeamUpdatedWebsocketResponse) => {
        websockets.push(
          // @ts-ignore
          onTeamUpdatedWebsocketResponse.subscribe((subscriptionResponse) => {
            return emitter({
              type: 'TEACHER_TEAM_UPDATED',
              payload: {
                teamStatus: subscriptionResponse.value.data.on_Team_Updated,
              },
            });
          })
        );
      },
      () => {
        // If something bad happens to this websocket start a new one
        monitorWebsocket(monitorOnTeamUpdatedWebsocket);
      }
    );

    return () => {
      console.log('unsub req');
    };
  });
}

function* monitorWebsocket(websocketEvents: any): any {
  const currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
  const currentStudentState: StudentStore = yield select(getCurrentStudentState);

  const channel = yield call(
    websocketEvents,
    currentTeacherState?.backendGameInfo || currentStudentState?.backendGameInfo,
    !!currentTeacherState?.backendGameInfo
  );

  while (true) {
    const action = yield take(channel);
    yield put(action);

    if (action.type === 'STUDENT_GAME_UPDATED' || action.type === 'STUDENT_GAME_UPDATED_FAILED') {
      yield delay(2000);
      yield put({
        type: 'GET_GAME_POLL',
      });
    } else if (action.type === 'STUDENT_ROUND_UPDATED' || action.type === 'STUDENT_ROUND_UPDATED_FAILED') {
      yield delay(2000);
      yield put({
        type: 'GET_ROUND_POLL',
      });
    } else if (action.type === 'STUDENT_ALIVE' || action.type === 'STUDENT_ALIVE_FAILED') {
      yield delay(10000);
      yield put({
        type: 'SET_STUDENT_ALIVE',
      });
    }
  }
}

function* wsSagas(): any {
  yield takeEvery('STUDENT_CLOSE_WEBSOCKETS', closeAllWebSocketsAndCancelIntervals);
  yield takeEvery('STUDENT_ADDED_TO_GAME', monitorWebsocket, monitorGameViaPolling);
  yield takeEvery('GET_GAME_POLL', monitorWebsocket, monitorGameViaPolling);
  yield takeEvery('STUDENT_ADDED_TO_GAME', monitorWebsocket, monitorRoundViaPolling);
  yield takeEvery('GET_ROUND_POLL', monitorWebsocket, monitorRoundViaPolling);
  yield takeEvery('STUDENT_ADDED_TO_GAME', monitorWebsocket, setStudentAliveViaPolling);
  yield takeEvery('SET_STUDENT_ALIVE', monitorWebsocket, setStudentAliveViaPolling);

  yield takeEvery('TEACHER_GAME_CREATED', monitorWebsocket, monitorOnGameUpdatedWebsocket);
  yield takeEvery('TEACHER_GAME_CREATED', monitorWebsocket, monitorOnRoundUpdatedWebsocket);
  yield takeEvery('TEACHER_GAME_CREATED', monitorWebsocket, monitorOnTeamUpdatedWebsocket);
}

export default wsSagas;
