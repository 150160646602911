import styles from './AvatarNameTeachersView.module.scss';
import alien from '../../images/Alien_Half.svg';
import { useState } from 'react';
import EditNameModal from '../EditNameModal/EditNameModal';

function AvatarNameTeachersView(props: {
  studentName: string;
  players?: any;
  editPlayerCallback: (object_id: string, team_object_id: string) => void;
  deletePlayerCallback: (object_id: string, team_object_id: string) => void;
  objectId: string;
  teamId: string;
}) {
  const [isShown, setIsShown] = useState(false);
  const [openPopup, setPopup] = useState(false);
  const modalToggle = () => {
    setPopup(!openPopup);
  };
  // const dispatch = useDispatch();
  // const editPlayer = async (object_id: string) => {
  //   teacherEditPlayer(object_id)(dispatch);
  // };
  // const saveEdit = () => {
  //   modalToggle();
  // };
  return (
    <div className={`${styles.wrapper}`} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      {openPopup && (
        <div className={`${openPopup ? styles.popUpModal : ''}`}>
          <EditNameModal
            placeholderTextF={'First Name'}
            placeholderTextL={'Last Initial'}
            clickCallback={() => props.editPlayerCallback(props.objectId, props.teamId)}
            clickCallbackClose={modalToggle}
            objectId={props.objectId}
          />
        </div>
      )}
      <div className={`${styles.avatar}`}>
        <img src={alien} className={styles.avatar} alt={'avatar'} />
      </div>
      <div className={`${styles.nameCard}`}>
        <p className={`${styles.studentName}`}>{props.studentName}</p>
      </div>
      {isShown && (
        <div className={`${styles.hoverEdit}`}>
          {/* <button className={`${styles.editButton}`} onClick={modalToggle}>Edit</button> */}
          <button
            className={`${styles.deleteButton}`}
            onClick={() => props.deletePlayerCallback(props.objectId, props.teamId)}>
            Delete/Remove Player
          </button>
        </div>
      )}
    </div>
  );
}

export default AvatarNameTeachersView;
