import styles from './QuestionBox.module.scss';

function createMarkup(question: string) {
  return {
    __html: question,
  };
}
function QuestionBox(text: { question: string; roundedCorners?: boolean; title?: string }) {
  return (
    <div className={`${styles.wrapper} ${text.roundedCorners ? styles.rounded : ''}`}>
      <div className={`${styles.large}`}>
        {text.title && <p className={`${styles.title}`}>{text.title}</p>}
        <div dangerouslySetInnerHTML={createMarkup(text.question)}></div>
      </div>
    </div>
  );
}

export default QuestionBox;
