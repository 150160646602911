import React, { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { wowzaCreateCheckoutSession } from '../graphql/queries';

function PurchaseManager(props: any) {
  const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const getCheckoutSession = async (productId: string) => {
    return API.graphql(
      graphqlOperation(wowzaCreateCheckoutSession, {
        input: {
          planId: productId,
        },
      })
    );
  };

  useEffect(() => {
    if (getParameterByName('productId', props.location.search)) {
      getCheckoutSession(getParameterByName('productId', props.location.search) || '').then((response: any) => {
        window.location.href = response.data.wowza_Create_Checkout_Session.url;
      });
    } else {
      window.location.href = 'https://wowzalearning.com';
    }
  }, []);

  return (
    <div>
      <h1 className={'centerText textWhite'}>Wowza Learning</h1>
      <h2 className={'centerText textWhite marginTopLarge'}>
        Please wait, we're redirecting you to our secure payment platform.
      </h2>
    </div>
  );
}

export default PurchaseManager;
