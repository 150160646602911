import styles from './StudentGetReady.module.scss';
import React from 'react';
import Rocket from '../../atoms/Rocket/Rocket';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
import { connect } from 'react-redux';

function StudentGetReady(props: any) {
  const getUserTeamName = () => {
    let teamName = '';
    props.StudentStore.fullTeamInfo?.forEach((fullTeam: any) => {
      fullTeam.players.forEach((player: any) => {
        if (player.player_object_id === props.StudentStore.myPlayerId) {
          teamName = fullTeam.name;
        }
      });
    });

    return teamName === 'Lightning' ? 'Meteor' : 'Comet';
  };

  return (
    <div>
      <StudentHeader />
      <div className={`${styles.wrapper} centerText`}>
        <h2 className={`${styles.large}`}>Team {getUserTeamName()}</h2>
        <h3 className={`${styles.subheader}`}>Get Ready!</h3>
        <Rocket />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(StudentGetReady);
