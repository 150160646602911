import styles from './AvatarEnterName.module.scss';
import React, { useEffect, useState } from 'react';
import AvatarHolding from '../../atoms/AvatarHolding/AvatarHolding';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';

const AvatarEnterName = (props: { submitStudentDetailsCallback: (firstName: string, lastInitial: string) => void }) => {
  const [firstName, setFirstName] = useState('');
  const [lastInitial, setLastInitial] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const capitalizeFirstLetter = (word: string) => {
    if (word.charAt(0) && word.slice(1)) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  };

  const submitStudentDetails = () => {
    if (!isLoading && firstName && lastInitial) {
      setIsLoading(true);
      props.submitStudentDetailsCallback(capitalizeFirstLetter(firstName), lastInitial.toUpperCase());
    }
  };

  return (
    <div>
      <div className={`${styles.logo}`}>
        <ZoinkLogo></ZoinkLogo>
      </div>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.body}`}>
          <AvatarHolding></AvatarHolding>
          <input
            type={'text'}
            name={'text'}
            className={styles.nameField}
            placeholder={'First Name'}
            onChange={(event) => setFirstName(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                submitStudentDetails();
              }
              if (event.key === ' ') {
                event.preventDefault();
              }
              if (!new RegExp('[A-Za-z]').test(event.key)) {
                event.preventDefault();
              }
            }}
            aria-autocomplete={'none'}
            autoComplete={'off'}
            autoFocus={true}
          />
          <input
            type={'text'}
            name={'text'}
            className={styles.lastNameField}
            placeholder={'Last Initial'}
            onChange={(event) => setLastInitial(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                submitStudentDetails();
              }
              if (event.key === ' ') {
                event.preventDefault();
              }
              if (!new RegExp('[A-Za-z]').test(event.key)) {
                event.preventDefault();
              }
            }}
            maxLength={1}
            aria-autocomplete={'none'}
            autoComplete={'off'}
          />
          <button className={`${styles.button}`} onClick={submitStudentDetails}>
            {isLoading ? 'Joining...' : 'Join Game'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarEnterName;
