import React, { useEffect, useState } from 'react';
// import AvatarChooser from '../../atoms/AvatarChooser/AvatarChooser';
import { connect, useDispatch } from 'react-redux';
import { teacherForceUpdateZoinkster } from '../../redux/actions/teacherActions';
import TeacherStatusHeader from '../../atoms/TeacherStatusHeader/TeacherStatusHeader';
import studentChooser from '../../images/chooserStudent.png';
import AvatarName from '../../atoms/AvatarName/AvatarName';

function AnnounceChooser(props: any) {
  const dispatch = useDispatch();
  const [studentName, setStudentName] = useState({ index: -1, name: '' });

  useEffect(() => {
    const chooserPlayerId = props.TeacherStore?.currentRound?.chooser?.substring(
      0,
      props.TeacherStore?.currentRound?.chooser.lastIndexOf(':')
    );
    const chooserTeamId = props.TeacherStore?.allPlayers?.find(
      (player: any) => player.object_id === chooserPlayerId
    )?.team;
    const zoinksterTeam = props.TeacherStore?.teamStatus?.find((team: any) => team.object_id !== chooserTeamId);
    const studentNames: string[] = [];

    if (zoinksterTeam && zoinksterTeam.players) {
      zoinksterTeam.players.forEach((player: any) => {
        if (player.answer_status === 1 && player.player_object_id) {
          studentNames.push(
            player.player_object_id.substring(
              player.player_object_id.lastIndexOf(':') + 1,
              player.player_object_id.length
            )
          );
        }
      });
    }
    // If noone answered show all names
    if (studentNames.length === 0) {
      zoinksterTeam?.players?.forEach((player: any) => {
        studentNames.push(
          player.player_object_id.substring(
            player.player_object_id.lastIndexOf(':') + 1,
            player.player_object_id.length
          )
        );
      });
    }
    const theSecondTimeout = setInterval(() => {
      setStudentName((sn) => {
        let newIndex = sn.index + 1;
        if (newIndex >= studentNames.length) {
          newIndex = 0;
        }
        return { index: newIndex, name: studentNames[newIndex] };
      });
    }, 500);
    const theTimeout = setTimeout(() => {
      //If after 18 seconds a zoinkster still hasn't been chosen force choose them
      if (!props.TeacherStore.currentRound?.zoinkster) {
        teacherForceUpdateZoinkster()(dispatch);
      }
    }, 18000);

    return () => {
      clearTimeout(theTimeout);
      clearInterval(theSecondTimeout);
    };
  }, []);

  document.body.classList.remove('teacherArea');
  return (
    <div>
      <TeacherStatusHeader />
      <div className="fullWidth centerText marginTop">
        <h2 className="textWhite centerText fullWidth fontSize36 noMarginTop">
          <img src={studentChooser} height="80" alt="" className="chooserAlien rightPadding" />
          <span className="whoWill paddingTopLarge vAlignTextTop">
            Who will{' '}
            <span className="underlineText">
              {props.TeacherStore.currentRound?.chooser.substring(
                props.TeacherStore.currentRound?.chooser.lastIndexOf(':') + 1
              )}
            </span>{' '}
            choose?
          </span>
        </h2>
        <div className="paddingTopExtraLarge rightPadding leftPadding">
          <AvatarName isCenter={true} studentName={studentName.name} isSelectable={false} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(AnnounceChooser);
