import styles from './EditNameModal.module.scss';
import React, { useState } from 'react';

const EditNameModal = (props: {
  placeholderTextF?: string;
  placeholderTextL?: string;
  objectId: string;
  clickCallback: () => void;
  clickCallbackClose: () => void;
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <span className={styles.close} onClick={props.clickCallbackClose}>Close</span>
          <div className={styles.modalContent}>
            <h2>Edit Player Name</h2>
            <input type={"text"} name={"text"} className={styles.inputField} placeholder={props.placeholderTextF} aria-autocomplete={'none'}
                   autoComplete={'off'}
                   autoFocus={true}/>
            <input type={"text"} name={"text"} className={styles.inputField} placeholder={props.placeholderTextL}  maxLength={1}
                   aria-autocomplete={'none'}
                   autoComplete={'off'}/>
            {/*<button className={`${styles.button}`} onClick={props.clickCallback(props.objectId) }>*/}
            {/*  {' '}*/}
            {/*  Save Name Change*/}
            {/*</button>*/}
          </div>
      </div>
    </div>
  );
};

export default EditNameModal;
