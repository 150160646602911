import styles from './AnswerSelected.module.scss';
import React from 'react';
import StudentHeader from '../StudentHeader/StudentHeader';
import alien from '../../images/Alien_Bottom.svg';
import { StudentStore } from '../../redux/interfaces/storeInterface';

function AnswerSelected(props: {
  answer: string;
  answerType?: any;
  teacherView?: boolean;
  hideHeader?: boolean;
  personAnswered?: string;
  stealInProgress?: string | boolean;
}) {
  return (
    <div>
      {props.teacherView || props.hideHeader ? '' : <StudentHeader />}
      <div className={`${styles.selectedAnswer}`}>
        {props.teacherView ? (
          props.stealInProgress ? (
            <h3>
              <span className={`${styles.largeQuestion}`}>
                Will {props.personAnswered?.substring(0, props.personAnswered?.length - 2)} steal the points?{' '}
              </span>
              {props.personAnswered?.substring(0, props.personAnswered?.length - 2)} answered...
            </h3>
          ) : (
            <h3>{props.personAnswered?.substring(0, props.personAnswered?.length - 2)} answered...</h3>
          )
        ) : (
          <h3>Your answer is...</h3>
        )}
        {props.answerType[0] === 'DRAW' ? (
          <h2 className={`${styles.answer}`}>
            {<img src={props.answer} alt="Drawn Image" />}
            <img src={alien} className={`${styles.avatar}`} alt={'avatar'} />
          </h2>
        ) : (
          <h2 className={`${styles.answer}`}>
            {props.answer}
            <img src={alien} className={`${styles.avatar}`} alt={'avatar'} />
          </h2>
        )}
        {props.teacherView ? '' : <p className={`${styles.instruction}`}>You might be chosen!</p>}
      </div>
    </div>
  );
}

export default AnswerSelected;
