import React, { useEffect, useState } from 'react';
import LargeText from '../../molecules/LargeText/LargeText';
import { connect } from 'react-redux';
import StandoutTitle from '../../atoms/StandoutTitle/StandoutTitle';
import { TeamEnum } from '../../molecules/TeamBox/TeamBox';
import rocksBackground from '../../images/rocksBackground.png';
import confetti from 'canvas-confetti';

function StudentPickAvatar(props: any) {
  document.body.classList.remove('teacherArea');
  const [callToAction, setCallToAction] = useState<string>('Go sit with your team!');

  const renderTeam = () => {
    let teamName = '';
    props.StudentStore.fullTeamInfo?.forEach((fullTeam: any) => {
      fullTeam.players.forEach((player: any) => {
        if (player.player_object_id === props.StudentStore.myPlayerId) {
          teamName = fullTeam.name;
        }
      });
    });

    if (teamName === 'Thunder') {
      return <StandoutTitle text={"You're on Team Comet"} team={TeamEnum.Thunder} />;
    } else if (teamName === 'Lightning') {
      return <StandoutTitle text={"You're on Team Meteor"} team={TeamEnum.Lightning} />;
    } else {
      return <span></span>;
    }
  };
  useEffect(() => {
    const callToActionMessages = [
      'Go sit with your team!',
      'Work with your classmates',
      'Use your notes and materials',
      'Share answers with your team',
      'Each answer is worth 2 points...',
      '...even if you bluff ;)',
      "Try to steal opponent's incorrect and partially correct answers",
      'HAVE FUN!',
    ];
    let index = 0;
    setInterval(() => {
      if (callToActionMessages.length === index) {
        index = 0;
      }
      setCallToAction(callToActionMessages[index]);
      index = index + 1;
    }, 3000);
  }, []);

  return (
    <div className="rocksBackgroundPositioning" style={{ backgroundImage: 'url(' + rocksBackground + ')' }}>
      <div className="grid-container centerText paddingTopLarge">
        <LargeText smallText={'Game is Starting!'} />
        <div className="marginTopLarge">{renderTeam()}</div>
        <StandoutTitle text={callToAction} small={true} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(StudentPickAvatar);
