import styles from './ZoinksterSelected.module.scss';
import image from '../../images/yellowRaisedAvatar.png';
import ribbon from '../../images/ribbon.png';
import React from 'react';
import StudentHeader from '../StudentHeader/StudentHeader';

function ZoinksterSelected(props: { zoinksterName: string; zoinksterTeam?: any; pointsToWin?: number }) {
  return (
    <div>
      <StudentHeader />
      <div className={`${styles.container}`}>
        <div className={`${styles.text}`}>
          <h1 className={`${styles.header}`}>{props.zoinksterName} is the Zoinkster!</h1>
        </div>
        <div className={`${styles.ribbon}`}>
          <img src={ribbon} className={styles.ribbon} alt=" " />
          <div className={`${styles.avatar}`}>
            <img src={image} className={styles.image} alt="zoinkster avatar!" />
            <div className={`${styles.avatarName}`}>
              <p>{props.zoinksterName}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.text}`}>
          <p className={`${styles.smallText}`}>View {props.zoinksterName}'s answer on your teachers screen. </p>
        </div>
      </div>
    </div>
  );
}

export default ZoinksterSelected;
