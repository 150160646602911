import styles from './TeamButtons.module.scss';

export enum TeamEnum {
    Thunder,
    Lightning
}

function TeamButtons(props: {
    text: string;
    team?: TeamEnum;
}) {
    return (
        <button
            className={
                `${styles.button}
                ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}
                `
            }
        >{props.text}</button>
    );
}

export default TeamButtons;
