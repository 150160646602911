import styles from './CreateQuestions.module.scss';
import noQuestionSets from '../../images/noQuestionSets.png';

function CreateQuestions(props: { clickCallback: () => void }) {
  return (
    <div className={`${styles.container}`}>
      <p className={`${styles.heading}`}>You have no Question Sets yet</p>
      <img src={noQuestionSets} className={styles.trees} alt="trees!" />
      <button className={`${styles.button}`} onClick={props.clickCallback}>
        {' '}
        Create New Set
      </button>
    </div>
  );
}

export default CreateQuestions;
