import { useEffect, useState } from 'react';
import styles from './Slider.module.scss';
import purple from '../../images/purpleRaisedAvatar.png';
import red from '../../images/redRaisedAvatar.png';

import MeteorShower from '../MeteorShower/MeteorShower';

function Slider(props: { chooser: string }) {
  const [isActive, setActive] = useState(false);
  const [showVersus, setVersus] = useState(false);

  useEffect(() => {
    //This is same as inside the ngOnInit
    toggleClass();
    setTimeout(() => {
      toggleClass();
    }, 4000);
  }, []);

  useEffect(() => {
    //This is same as inside the ngOnInit
    if (isActive) {
      setTimeout(() => {
        toggleClass();
      }, 4000);
    }
  }, [isActive, showVersus]);

  const toggleClass = () => {
    setActive(!isActive);
    setTimeout(() => {
      setVersus(!showVersus);
    }, 2000);
  };

  return (
    <div>
      {/* <div className={`${styles.box} ${styles.fadeIn} ${isActive ? `${styles.show}` : `${styles.dontShow}`}`}></div> */}
      <div className={`${styles.versusScreen}`}>
        <div className={`${styles.split}  ${styles.teamLightning} ${isActive ? `${styles.splitShow}` : ``} `}>
          <div className={`${styles.centered2}`}>
            <img src={red} alt="Avatar Meteor" />
            <div className={`${styles.teamWrapper}`}>
              <p>Team Meteor</p>
              {props.chooser.split(':')[1] === 'Thunder' ? <h2>Chooser</h2> : <h2>Zoinkster</h2>}
            </div>
          </div>
          {showVersus ? <p className={`${styles.versus2}`}>S.</p> : ''}
        </div>

        <div
          className={`${styles.split} 
                ${styles.teamThunder} ${isActive ? `${styles.splitShow}` : ``}`}>
          <div className={`${styles.centered}`}>
            <img src={purple} alt="Avatar Comet" />
            <div className={`${styles.teamWrapper}`}>
              <p>Team Comet</p>
              {props.chooser.split(':')[1] === 'Lightning' ? <h2>Chooser</h2> : <h2>Zoinkster</h2>}
            </div>
          </div>
          {showVersus ? <p className={`${styles.versus}`}>V</p> : ''}
        </div>

        {/* <MeteorShower></MeteorShower> */}
      </div>
    </div>
  );
}

export default Slider;
