import styles from './AvatarChooser.module.scss';
import image from '../../images/chooser.png';
import studentImage from '../../images/chooserStudent.png';
import React from 'react';

function AvatarChooser(props: { studentScreen?: boolean }) {
  return (
    <div className={`${styles.container}`}>
      {props.studentScreen ? (
        <img src={studentImage} className={styles.image} alt="moonMan!" />
      ) : (
        <img src={image} className={styles.image} alt="moonMan!" />
      )}
    </div>
  );
}

export default AvatarChooser;
