import React from "react";
import IntroMusic from "../../atoms/IntroMusic/IntroMusic";
import TeamCorrect from "../../atoms/TeamCorrect/TeamCorrect";
import MultiChoiceButtons from "../../atoms/MultiChoiceButtons/MultiChoiceButtons";
import IncorrectAnswerPage from "../../pages/TeacherFlow/IncorrectAnswerPage";

export function PointsAward(props: { team: string; numberOfPoints: number; isCorrect?: boolean;}) {
  return (
    <div className={'pointsWrapperOuter'}>
        {(props.numberOfPoints && props.numberOfPoints >= 1) ? (<div className={'pointsAwarded'}>
            <TeamCorrect team={props.team === 'Thunder' ? 0 : 1} />
            <div className={'pointsWrapper'}>
                {!props.isCorrect && ( <div>
                    <h1>+{props.numberOfPoints} points</h1>
                    <IntroMusic soundEffect={'PARTIALCORRECT'} isLoop={false} hideButton={true}/>
                </div> )}
                {props.isCorrect && ( <div>
                    <h1>+{props.numberOfPoints} points</h1>
                    <IntroMusic soundEffect={'WIN'} isLoop={false} hideButton={true}/>
                </div> )}
            </div>
        </div>) : ""}
        {(props.numberOfPoints === 0) ? (<div className={'incorrect'}>
            <IncorrectAnswerPage inline={true} />
        </div>) : "" }
    </div>
  );
}
export const MemoizedPointsAward = React.memo(PointsAward);
