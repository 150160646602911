import styles from './QuestionView.module.scss';
import React, { useState } from 'react';
import QuestionBox from '../../atoms/QuestionBox/QuestionBox';
import TeamTable from '../../atoms/TeamTable/TeamTable';
import MultiChoiceButtons from '../../atoms/MultiChoiceButtons/MultiChoiceButtons';
import IntroMusic from '../../atoms/IntroMusic/IntroMusic';

export enum TeamEnum {
  Thunder,
  Lightning,
}

function QuestionView(props: {
  question: string;
  thunderPlayersAnswered: number;
  lightningPlayersAnswered: number;
  fakeAnswers?: string[];
  timeLimit?: number;
  timeLeft?: any;
}) {
  const [text, setText] = useState<string>('');

  const updateTypedAnswer = (textValue: string) => {
    setText(textValue);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.question}`}>
        <QuestionBox question={props.question} roundedCorners={true}></QuestionBox>
        {!props.fakeAnswers ? (
          <div>
            <IntroMusic soundEffect={'WRITEGAMEPLAYNOTICKING'} isLoop={true} />
          </div>
        ) : (
          ' '
        )}
        {props.fakeAnswers && props.fakeAnswers.length ? (
          <div>{props.timeLeft <= 10 && <IntroMusic soundEffect={'CLOCKTICKING'} isLoop={true} />}</div>
        ) : (
          ''
        )}
        {props.fakeAnswers && props.fakeAnswers.length ? (
          <div>{props.timeLeft <= 1 && <IntroMusic soundEffect={'BELL'} isLoop={true} />}</div>
        ) : (
          ''
        )}
        {!props.fakeAnswers ? (
          <div>{props.timeLeft <= 10 && <IntroMusic soundEffect={'CLOCKTICKING'} isLoop={true} />}</div>
        ) : (
          ' '
        )}
        {!props.fakeAnswers ? (
          <div>{props.timeLeft <= 1 && <IntroMusic soundEffect={'BELL'} isLoop={true} />}</div>
        ) : (
          ' '
        )}
        {props.fakeAnswers && props.fakeAnswers.length ? (
          <div>
            {props.timeLimit && props.timeLimit > 30 && <IntroMusic soundEffect={'MULTIGAMEPLAY'} isLoop={true} />}
            {props.timeLimit && props.timeLimit <= 30 && <IntroMusic soundEffect={'10MULTIGAMEPLAY'} isLoop={true} />}
            <MultiChoiceButtons
              buttons={props.fakeAnswers}
              buttonSelectedCallback={updateTypedAnswer}></MultiChoiceButtons>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.teams}`}>
          <TeamTable name={'Comet'} team={TeamEnum.Thunder} playersAnswered={props.thunderPlayersAnswered}></TeamTable>
          <h3>vs.</h3>
          <TeamTable
            name={'Meteor'}
            team={TeamEnum.Lightning}
            playersAnswered={props.lightningPlayersAnswered}></TeamTable>
        </div>
      </div>
    </div>
  );
}

export default QuestionView;
