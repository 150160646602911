import { SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './Feedback.module.scss';
import purple from '../../images/purpleRaisedAvatar.png';
import feedbackIcon from '../../images/Feedback.png';
import bug from '../../images/bugIcon.png';
import feedbackzoinks from '../../images/feedbackZoinks.png';
const delay = 15;

function Feedback() {
  const [isOpen, setOpen] = useState(false);
  const [isDone, setDone] = useState(false);
  const [isGoodbye, setGoodbye] = useState(false);
  const [isActive, setActive] = useState('');
  const [feedbackId, setFeedbackId] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isContinue, setContinue] = useState('');
  const [message, setMessage] = useState('');

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const getGameId = () => {
    let sessionData = sessionStorage.getItem('gameCode');

    if (!sessionData) {
      sessionData = '{}';
    }

    return sessionData;
  };
  function getCurrentDate() {
    let newDate = new Date();
    let time = newDate.getHours();
    let minutes = newDate.getMinutes();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return `${time}:${minutes} | ` + monthNames[newDate.getMonth()];
  }

  const [array, setArray] = useState<string[]>([]);

  const handleChange = (event: { target: { value: SetStateAction<any> } }) => {
    setMessage(event.target.value);
  };

  const toggleEmojis = (index: string) => {
    setActive((isActive) => (isActive === index ? '' : index));
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter' && message !== '') {
      setArray((array) => [...array, message]);
      if (inputRef.current != null && messagesEndRef.current != null) {
        if (array.length < 2) {
          setDone(true);
        }
        inputRef.current.value = '';
        setMessage('');
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 1);
      }
    }
  };
  //start a timer, when timer hits 0, display message, if this function is called again reset timer.

  useEffect(() => {
    let timer1: string | number | NodeJS.Timeout | undefined;
    if (feedback) {
      timer1 = setTimeout(() => setGoodbye(true), delay * 1000);
    }
    getCurrentDate();
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    return () => {
      clearTimeout(timer1);
    };
  }, [feedback, message]);

  //Shitty solution, but If no emojis are clicked it fucks with the css width, so I just set it as the middle emoji as default
  useEffect(() => {
    toggleEmojis('2');
  }, []);

  useEffect(() => {
    const baseUrl =
      window.location.hostname.indexOf('zoinks') !== -1 ? 'https://api.zoinks.app' : 'https://api.wowzalearning.com';
    fetch(baseUrl + '/feedback', {
      method: 'POST',
      body: JSON.stringify({
        // Add parameters here
        feedbackId: feedbackId,
        gameId: getGameId(),
        message: array,
        rating: isActive,
        type: 'feedback',
      }),
      headers: {
        'Content-Type': 'Accept: application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFeedbackId(data.feedbackId);
      })
      .catch((err) => {});
  }, [array]);

  function Feedback(choice: string) {
    let feedbackString =
      'You have chosen ' +
      "'" +
      choice +
      "'" +
      (choice === 'Report a problem'
        ? '. Please outline what the problem is down below!'
        : '. Please type your feedback below.');
    setFeedback(feedbackString);
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function Continue(choice: string) {
    let continueString = 'Thanks a lot! If you do not mind, choose how useful this has been for you!';
    if (choice === 'no') {
      setContinue(continueString);
    }
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
          <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
        </symbol>
        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
          <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
        </symbol>
      </svg>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.feedbackImg}`}>
          <img className={`${styles.pointer} ${styles.feedbackZoinks}`} src={feedbackzoinks} onClick={toggleOpen} />
        </div>

        <div className={`${styles.container} ${isOpen ? `${styles.active}` : `${styles.inactive}`}`}>
          <div className={`${styles.msgHeader}`}>
            <p onClick={toggleOpen} className={`${styles.x} ${styles.pointer}`}>
              X
            </p>
          </div>

          <div className={`${styles.chatPage}`}>
            <div className={`${styles.msgInbox}`}>
              <div className={`${styles.chats}`}>
                <div className={`${styles.msgPage}`}>
                  <div className={`${styles.receivedChats}`}>
                    <div className={`${styles.receivedChatsImg}`}>
                      <img className={`${styles.astronaut}`} src={purple} />
                    </div>
                    <div className={`${styles.receivedMsg}`}>
                      <div className={`${styles.receivedMsgInbox}`}>
                        <p>Hi, thanks for playing Zoinks! Please select an option below.</p>
                        <p>
                          Please rate your experience using Zoinks.
                          <div onClick={() => Continue('no')} className={`${styles.right}`}>
                            <ul className={`${styles.feedback}`}>
                              <li
                                onClick={() => toggleEmojis('0')}
                                key={'angry'}
                                className={`${styles.angry} ${isActive === '0' ? `${styles.active}` : ''}`}>
                                <div>
                                  <svg className={`${styles.eye} ${styles.left}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.eye} ${styles.right}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.mouth}`}>
                                    <use xlinkHref="#mouth" />
                                  </svg>
                                </div>
                              </li>
                              <li
                                key={'sad'}
                                onClick={() => toggleEmojis('1')}
                                className={`${styles.sad} ${isActive === '1' ? `${styles.active}` : ''}`}>
                                <div>
                                  <svg className={`${styles.eye} ${styles.left}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.eye} ${styles.right}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.mouth}`}>
                                    <use xlinkHref="#mouth" />
                                  </svg>
                                </div>
                              </li>
                              <li
                                key={'ok'}
                                onClick={() => toggleEmojis('2')}
                                className={`${styles.ok} ${isActive === '2' ? `${styles.active}` : ''}`}>
                                <div></div>
                              </li>
                              <li
                                key={'good'}
                                onClick={() => toggleEmojis('3')}
                                className={`${styles.good} ${isActive === '3' ? `${styles.active}` : ''}`}>
                                <div>
                                  <svg className={`${styles.eye} ${styles.left}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.eye} ${styles.right}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.mouth}`}>
                                    <use xlinkHref="#mouth" />
                                  </svg>
                                </div>
                              </li>
                              <li
                                key={'happy'}
                                onClick={() => toggleEmojis('4')}
                                className={`${styles.happy} ${isActive === '4' ? `${styles.active}` : ''}`}>
                                <div>
                                  <svg className={`${styles.eye} ${styles.left}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                  <svg className={`${styles.eye} ${styles.right}`}>
                                    <use xlinkHref="#eye" />
                                  </svg>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </p>

                        <div className={`${styles.options}`}>
                          <div className={`${styles.left}`}>
                            <p onClick={() => Feedback('Leave feedback')} className={`${styles.imageWrapper}`}>
                              <img className={`${styles.feedbackIcon}`} src={feedbackIcon} />
                              Leave Feedback
                            </p>
                          </div>
                          <div className={`${styles.right}`}>
                            <p onClick={() => Feedback('Report a problem')} className={`${styles.imageWrapper}`}>
                              Report a problem
                              <img className={`${styles.bug}`} src={bug} />
                            </p>
                          </div>
                        </div>

                        {feedback && <p>{feedback}</p>}

                        <span className={`${styles.time}`}>{getCurrentDate()}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.outgoingChats}`}>
                    {array.map((link) => (
                      <div className={`${styles.outgoingChats}`}>
                        <div className={`${styles.outgoingChatsMsg}`}>
                          <p key={link} className={`${styles.multiMsg}`}>
                            {link}
                          </p>
                        </div>
                      </div>
                    ))}
                    {array.length > 0 && <span className={`${styles.time}`}>{getCurrentDate()}</span>}
                  </div>
                  {isDone && (
                    <div className={`${styles.receivedMsg}`}>
                      <div className={`${styles.receivedMsgInbox}`}>
                        <p>Thanks! Is there anything else you would like to add?</p>
                        <span className={`${styles.time}`}>{getCurrentDate()}</span>
                      </div>
                    </div>
                  )}
                  {isGoodbye && (
                    <div className={`${styles.receivedMsg}`}>
                      <div className={`${styles.receivedMsgInbox}`}>
                        <p>
                          Thank you for the feedback! This message will be sent to a very capable astronaut. See you
                          again!
                        </p>
                        <span className={`${styles.time}`}>{getCurrentDate()}</span>
                      </div>
                    </div>
                  )}

                  <div ref={messagesEndRef} />
                </div>
              </div>

              <div className={`${styles.msgBottom}`}>
                <div className={`${styles.inputGroup}`}>
                  <input
                    type="text"
                    style={feedback && !isGoodbye ? {} : { opacity: '0.4', cursor: 'not-allowed' }}
                    className={`${styles.formControl}`}
                    id="message"
                    disabled={feedback && !isGoodbye ? false : true}
                    name="message"
                    ref={inputRef}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Write message..."
                  />

                  <span className={`${styles.inputGroupText} ${styles.sendIcon}`}>
                    <i className="bi bi-send"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
