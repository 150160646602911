import styles from './CorrectionLabel.module.scss';

export enum AnswerEnum {
    Correct,
    PartiallyCorrect,
    Incorrect
}

function CorrectionLabel(response: {
    text: string;
    answer?: AnswerEnum;
}) {
    return (
        <div className={`${styles.wrapper}`}>
            <div className={`${styles.boxContainer}  
            ${response.answer === AnswerEnum.Correct ? styles.correct : ''}
            ${response.answer === AnswerEnum.PartiallyCorrect ? styles.partiallyCorrect : ''}
            ${response.answer === AnswerEnum.Incorrect ? styles.incorrect : ''}`}>
                <p className={`${styles.title}
                ${response.answer === AnswerEnum.Correct ? styles.correct : ''}
                ${response.answer === AnswerEnum.PartiallyCorrect ? styles.partiallyCorrect : ''}
                ${response.answer === AnswerEnum.Incorrect ? styles.incorrect : ''}`}>
                {response.text}</p>
            </div>
            <div className={`${styles.rightSide}  
            ${response.answer === AnswerEnum.Correct ? styles.correct : ''}
            ${response.answer === AnswerEnum.PartiallyCorrect ? styles.partiallyCorrect : ''}
            ${response.answer === AnswerEnum.Incorrect ? styles.incorrect : ''}`}>
            </div>
            <div className={`${styles.leftSide}  
            ${response.answer === AnswerEnum.Correct ? styles.correct : ''}
            ${response.answer === AnswerEnum.PartiallyCorrect ? styles.partiallyCorrect : ''}
            ${response.answer === AnswerEnum.Incorrect ? styles.incorrect : ''}`}>
            </div>
        </div>

    );
}

export default CorrectionLabel;
