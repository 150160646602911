import React from 'react';
import Button, { ButtonTypeEnum } from '../../atoms/Button/Button';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import { API, graphqlOperation } from 'aws-amplify';
import { updateGameStatus } from '../../graphql/mutations';
import StudentList from '../../molecules/StudentList/StudentList';

const TeacherLaunchGame = () => {
  document.body.classList.add('teacherArea');

  const startCurrentGame = async (gameDetails: { object_id: string; model_id: string }) => {
    return API.graphql(
      graphqlOperation(updateGameStatus, {
        input: {
          game_model_id: gameDetails.model_id,
          game_object_id: gameDetails.object_id,
        },
      })
    );
  };

  const startGame = async () => {
    try {
      let sessionData = sessionStorage.getItem('currentGameDetails');

      if (!sessionData) {
        sessionData = '{}';
      }

      startCurrentGame(JSON.parse(sessionData)).then((gameStatusChangeResponse: any) => {
        // history.push('/teacher/startGame');
      });
    } catch (err) {}
  };

  const getGameId = () => {
    let sessionData = sessionStorage.getItem('currentGameDetails');
    if (!sessionData) {
      sessionData = '{}';
    }

    return <span>{JSON.parse(sessionData)?.object_id}</span>;
  };

  return (
    <div className="grid-container">
      <div className="grid-x">
        <div className="small-12 medium-3 cell">
          <ZoinkLogo />
        </div>
        <div className="small-12 medium-6 cell centerText textWhite">
          <p>Go to wowza.com/play and enter code</p>
          <h1>{getGameId()}</h1>
        </div>
        <div className="small-12 medium-3 cell">
          <Button text="Start Game" type={ButtonTypeEnum.Primary} clickCallback={startGame} />
        </div>
      </div>
      <div className="studentList">
        <StudentList></StudentList>
      </div>
    </div>
  );
};

export default TeacherLaunchGame;
