import QuestionBox from '../../atoms/QuestionBox/QuestionBox';
import ZoinkAnswer from '../../molecules/ZoinkAnswer/ZoinkAnswer';
import CorrectionBar, { CorrectStatus } from '../../atoms/CorrectionBar/CorrectionBar';
import Button, { ButtonAreaEnum, ButtonTypeEnum } from '../../atoms/Button/Button';
import { useState } from 'react';

function ZoinksterAnswer(props: {
  keyValue: string;
  question: string;
  answer: string;
  answerExplanation?: string;
  stealInProgress?: string | boolean;
  answerType: string;
  isMultiChoice?: boolean;
  personAnswered?: string;
  personChoosing?: string;
  answerStatusCallback: (amountOfPoints: number) => void;
}) {
  document.body.classList.remove('teacherArea');

  const [accordionOpen, setAccordionOpen] = useState(false);

  const startASteal = () => {
    props.answerStatusCallback(CorrectStatus.Incorrect);
  };

  const showNextSteps = () => {
    if (props.answer === "(Oops, they didn't answer the question!)") {
      return (
        <div className="centerText marginBottomLarge">
          <Button
            key={props.keyValue}
            text={'Continue'}
            clickCallback={startASteal}
            type={ButtonTypeEnum.Primary}
            area={ButtonAreaEnum.Student}
          />
          {/*<IntroMusic soundEffect={'LOSE'} isLoop={false} hideButton={true}/>*/}
        </div>
      );
    } else {
      return (
        <div className="fullWidth fixedBottom">
          <CorrectionBar
            key={props.keyValue}
            answerStatusCallback={props.answerStatusCallback}
            hidePartialCorrect={!!props.stealInProgress || props.isMultiChoice}
          />
        </div>
      );
    }
  };

  const showAnswerExplanation = () => {
    if (props.answerExplanation) {
      return (
        <div className="accordion">
          <button
            onClick={() => setAccordionOpen(!accordionOpen)}
            className={accordionOpen ? 'accordion-title open' : 'accordion-title'}>
            Show Answer Explanation
          </button>
          <div className={accordionOpen ? 'accordion-document open' : 'accordion-document'}>
            {props.answerExplanation}
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="fullWidth">
      <QuestionBox
        question={props.question}
        title={
          !props.stealInProgress
            ? props.personChoosing + ' chose ' + props.personAnswered + ' to answer...'
            : 'Will ' + props.personChoosing + ' steal all the points?'
        }
      />
      <div className="grid-container">
        <ZoinkAnswer
          answerType={props.answerType}
          answer={props.answer}
          stealInProgress={props.stealInProgress}
          personAnswered={props.personAnswered}
        />
      </div>
      {showAnswerExplanation()}
      {showNextSteps()}
    </div>
  );
}

export default ZoinksterAnswer;
