import { useHistory, useLocation } from 'react-router-dom';
import { teacherCreateGame } from '../../redux/actions/teacherActions';
import { useDispatch } from 'react-redux';

const TeacherLaunchPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  document.body.classList.add('teacherArea');

  const launchGame = async (object_id: string) => {
    // @ts-ignore
    window.soundRef = 'intro';
    teacherCreateGame(object_id)(dispatch);
    history.push('/teacher/startGame');
  };

  const launchGameId = new URLSearchParams(useLocation().search).get('gameId');

  if (launchGameId) {
    launchGame(launchGameId.replace(' ', '+'));
  }

  return (
    <div></div>
  );
};

export default TeacherLaunchPage;
