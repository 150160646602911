import styles from './AvatarName.module.scss';
import avatar from '../../images/avatarGreen.png';
import alien from '../../images/Alien_Half.svg';
import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { addPlayerToGame } from '../../graphql/mutations';

function AvatarName(props: { studentName: string; isSelectable?: boolean; isCenter?: boolean }) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={`${styles.wrapper} ${props.isCenter ? styles.isCenter : ''}`}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
      {props.isSelectable && (
        <div className={`${styles.selectWrap}`}>
          <div className={`${styles.avatar}`}>
            <div className={`${styles.pulsatingCircle}`}></div>

            <img src={alien} className={styles.avatar} alt={'avatar'} />
          </div>

          <div className={`${styles.nameCard}`}>
            <p className={`${styles.studentName}`}>{props.studentName}.</p>
          </div>
        </div>
      )}
      {!props.isSelectable && (
        <div className={`${styles.notSelectable}`}>
          <div className={`${styles.avatar}`}>
            <img src={alien} className={styles.avatar} alt={'avatar'} />
          </div>
          <div className={`${styles.nameCard}`}>
            <p className={`${styles.studentName}`}>{props.studentName}.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AvatarName;
