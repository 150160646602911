import styles from './AvatarHolding.module.scss';
import avatar from '../../images/Alien_Half.svg';

function AvatarHolding() {
  return (
    <div className={`${styles.card}`}>
      <img src={avatar} className={styles.avatar} alt={'avatar'} />
    </div>
  );
}

export default AvatarHolding;
