import AvatarMoon from '../../atoms/AvatarMoon/AvatarMoon';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';

function NoAnswerPage(props: { headerText?: string; footerText?: string; inline?: boolean }) {
  document.body.classList.remove('teacherArea');
  return (
    <div>
      {!props.inline ? <StudentHeader /> : ''}
      <div className="container">
        <div className={'fullWidth centerText' + (props.inline ? '' : ' marginTopLarge')}>
          <div className="grid-x">
            <div className="small-10 small-offset-1 medium-8 medium-offset-2 cell">
              <h1 className="textWhite fontSize36 marginTopSmall">
                {props.headerText ? props.headerText : 'No Answer'}
              </h1>
              <div className="marginTop">
                <AvatarMoon />
              </div>
              <h2 className="fontSize26 lineHeight36 textWhite">
                {props.footerText ? props.footerText : 'Earn points for your team when you submit an answer!'}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoAnswerPage;
