import styles from './TeamCorrect.module.scss';
import blue from '../../images/blueCelebrate.png';
import green from '../../images/greenCelebrate.png';
import yellow from '../../images/maroonCelebrate.png';
import purple from '../../images/purpleCelebrate.png';
import red from '../../images/redCelebrate.png';

export enum TeamEnum {
  Thunder,
  Lightning,
}

function TeamCorrect(props: { team?: TeamEnum }) {
  return (
    <div className={`${styles.container}`}>
      <div
        className={`${styles.teamWrapper}
            ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}`}>
        <img src={yellow} alt={'avatar'} className={`${styles.avatar}`} />
        <img src={red} alt={'avatar2'} className={`${styles.avatarTwo}`} />{' '}
        <img src={purple} alt={'avatar3'} className={`${styles.avatarThree}`} />
        <img src={blue} alt={'avatar4'} className={`${styles.avatarFour}`} />
        <img src={green} alt={'avatar5'} className={`${styles.avatarFive}`} />
      </div>
    </div>
  );
}

export default TeamCorrect;
