import styles from './StudentList.module.scss';
import { connect, useDispatch } from 'react-redux';
import { teacherDeletePlayer } from '../../redux/actions/teacherActions';
import AvatarNameTeachersView from '../../atoms/AvatarNameTeachersView/AvatarNameTeachersView';

export enum TeamEnum {
  Thunder,
  Lightning,
}

const StudentList = (props: any) => {
  const dispatch = useDispatch();

  const deletePlayer = async (object_id: string, team_object_id: string) => {
    teacherDeletePlayer(object_id, team_object_id)(dispatch);
  };

  const createAvatars = () => {
    return props.TeacherStore.allPlayers?.map((studentFromList: { name: string; object_id: string; team: string }) => (
      <div className="small-6 medium-2 cell avatar" key={studentFromList.object_id}>
        <AvatarNameTeachersView
          studentName={studentFromList.name}
          deletePlayerCallback={deletePlayer}
          editPlayerCallback={deletePlayer}
          objectId={studentFromList.object_id}
          teamId={studentFromList.team}
        />
      </div>
    ));
  };

  return (
    <div className={`${styles.container}`}>
      <div className="grid-x grid-padding-x">{createAvatars()}</div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(StudentList);
