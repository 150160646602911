import styles from './TeamTable.module.scss';
import team from '../../images/teamThunder.png';
import alien from '../../images/Alien_Sitting.png';
export enum TeamEnum {
  Thunder,
  Lightning,
}

function TeamTable(props: { name: string; team?: TeamEnum; playersAnswered: number }) {
  return (
    <div className={`${styles.container}`}>
      


      <div
        className={`${styles.teamWrapper}
            ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}`}>
        <img src={alien} alt={'avatar'} className={`${styles.avatar}`} />
        <img src={alien} alt={'avatar2'} className={`${styles.avatarTwo}`} />
        <img src={alien} alt={'avatar3'} className={`${styles.avatarThree}`} />
      </div>
      <div className={`${styles.footer}`}>
        <p className={`${styles.teamName}`}>{props.name}</p>
      </div>
      <div className={`${styles.answerSection}`}>
        <div className={`${styles.playersAnswered} fontSize26`}>{props.playersAnswered}</div>
      </div>
    </div>
  );
}

export default TeamTable;
