import styles from './StudentsAnswerSection.module.scss';
import AnswerSelected from '../AnswerSelected/AnswerSelected';

function StudentsAnswerSection(props: { myAnswer?: string; answered?: boolean; answerType?: any }) {
  return (
    <div className={`${styles.container}`}>
      {props.answered && props.myAnswer && props.myAnswer !== ' ' && (
        <div className={props.answered ? 'questionAnswered' : 'questionNotAnswered'}>
          <div className={`${styles.wrapper}`}>
            {/*<div className={`${styles.answerBox}`}>*/}
            {/*    <p>{props.myAnswer}</p>*/}
            {/*</div>*/}
            <AnswerSelected answerType={props.answerType} answer={props.myAnswer ?? ''} hideHeader={false} />

            {/*{props.answered &&  <h2>You might be selected to answer this question</h2>}*/}
            {!props.answered && <h2>You didn't earn any points for your team</h2>}
          </div>
        </div>
      )}
      {!props.answered && !props.myAnswer && (
        <div className={props.answered ? 'questionAnswered' : 'questionNotAnswered'}>
          <div className={`${styles.wrapper}`}>
            <h1>You didn't answer this question</h1>
            <h2>You didn't earn any points for your team</h2>
          </div>
        </div>
      )}

      {props.answered && (!props.myAnswer || props.myAnswer === ' ') && (
        <div className={props.answered ? 'bluffed' : ''}>
          <div className={`${styles.wrapper}`}>
            <h1>You bluffed! Good Luck</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentsAnswerSection;
