import React from 'react';
import StandoutTitle from '../../atoms/StandoutTitle/StandoutTitle';
import { TeamEnum } from '../../atoms/TeamGameEnd/TeamGameEnd';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';

function StealInProgress(props: { stealingTeam: TeamEnum }) {
  return (
    <div>
      <StudentHeader />
      <div className="grid-container marginTop centerText">
        <div className="grid-x">
          <div className="cell marginTop">
            <div className="marginTop">
              <StandoutTitle
                text={props.stealingTeam === TeamEnum.Thunder ? 'Will Team Comet steal?' : 'Will Team Meteor steal?'}
                team={props.stealingTeam}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StealInProgress;
