import styles from './StandoutTitle.module.scss';
import { TeamEnum } from '../TeamGameEnd/TeamGameEnd';

function StandoutTitle(props: { text: string; team?: TeamEnum; small?: boolean; boldText?: string }) {
  return (
    <div
      className={`${styles.standoutTitle}
                ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}
                ${!props.team && props.team !== 0 ? styles.custom : ''}
                ${props.small ? styles.small : ''}
                `}>
      {props.boldText && (
        <span>
          <span className={`${styles.strong}`}>{props.boldText}</span>
          <span>&nbsp;</span>
        </span>
      )}
      {props.text}
    </div>
  );
}

export default StandoutTitle;
