import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import appEffectsSagas from './effects/sagas';
import wsSagas from './effects/websocketSagas';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const storeCreated = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(appEffectsSagas);
  sagaMiddleware.run(wsSagas);
  return storeCreated;
}
