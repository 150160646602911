import { useEffect, useState } from 'react';
import Button, { ButtonTypeEnum } from '../../atoms/Button/Button';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import StudentList from '../../molecules/StudentList/StudentList';
import { connect, useDispatch } from 'react-redux';
import { teacherStartGame } from '../../redux/actions/teacherActions';
import { useHistory } from 'react-router-dom';
import personIcon from '../../images/PersonIcon.svg';
import introScreen from '../../images/introScreen.svg';
import TeacherGameStarting from './TeacherGameStarting';
import IntroMusic from '../../atoms/IntroMusic/IntroMusic';
import LogRocket from 'logrocket';
import { Auth } from 'aws-amplify';
import Feedback from '../../atoms/Feedback/Feedback';

const TeacherStartGame = (props: any) => {
  document.body.classList.add('teacherArea');

  const dispatch = useDispatch();
  const history = useHistory();
  const [hasRequestedGameStart, setHasRequestedGameStart] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    Auth.currentUserInfo().then((userInfo) => {
      if (window.location.host.indexOf('localhost') === -1) {
        LogRocket.identify(userInfo.username);
      }
    });
  }, []);

  const startGame = async () => {
    try {
      setHasRequestedGameStart(true);
      setTimeout(() => {
        setIsLoading(true);
      }, 3000);
      setTimeout(() => {
        let backendGameInfo = props.TeacherStore.backendGameInfo;
        teacherStartGame({
          model_id: backendGameInfo.model_id,
          object_id: backendGameInfo.object_id,
          rounds: backendGameInfo.rounds,
        })(dispatch);
        history.push('/teacher/play');
      }, 6000);
    } catch (err) {}
  };

  const getGameId = () => {
    let backendGameInfo = props.TeacherStore.backendGameInfo;

    return (
      <span>
        {backendGameInfo?.object_id || (
          <div className="loaderContainer largeLoader">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        )}
      </span>
    );
  };

  const showStartButton = () => {
    if (props.TeacherStore.allPlayers && props.TeacherStore.allPlayers.length > 1) {
      return <Button text="Start Game" type={ButtonTypeEnum.Primary} clickCallback={startGame} />;
    } else {
      return (
        <div>
          <p className="textWhite">Waiting for players...</p>
          <div className="loaderContainer">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
      );
    }
  };

  const generateLoadingOrLoadedState = () => {
    if (isLoading) {
      document.body.classList.remove('teacherArea');
      return (
        <div>
          <TeacherGameStarting />
          <IntroMusic soundEffect={'GAMEPLAY'} isLoop={true} />
        </div>
      );
    } else if (hasRequestedGameStart) {
      document.body.classList.remove('teacherArea');
      return (
        <div className="container">
          <img src={introScreen} width="100%" height="100%" alt="Zoinks!" className="objectFitCover" />
          <IntroMusic soundEffect={'ZOINK'} isLoop={true} />
        </div>
      );
    } else {
      document.body.classList.remove('teacherArea');
      return (
        <div className="container">
          <div className="grid-container">
            <div className="grid-x">
              <div className="small-12 medium-2 cell marginTopSmall">
                <ZoinkLogo />
                <IntroMusic soundEffect={'INTRO'} isLoop={true} />
              </div>
              <div
                className={
                  'small-12 medium-6 medium-offset-1 cell fullWidth textWhite centerText gameCodeInstructionWrapper' +
                  (props.TeacherStore.allPlayers && props.TeacherStore.allPlayers.length > 1 ? '' : ' fullScreen')
                }>
                <h2 className="lineHeight26 lineHeight26 marginTopSmall">
                  <span className="gotoText">Go to</span>{' '}
                  <span className="fontSize46">&nbsp;play.zoinks.app&nbsp;</span>
                </h2>
                <h3 className="gameCodeWrapper">{getGameId()}</h3>
              </div>
              <div className="small-12 medium-3 cell rightText marginTopSmall">{showStartButton()}</div>
              <div className="small-12 medium-2 cell marginTopSmall">
                <h3 className="textWhite playerCountWrapper">
                  <span className="playerCountInner">
                    <span>
                      <img src={personIcon} alt={'Players Joined'} />
                    </span>
                    <span>{props?.TeacherStore?.allPlayers?.length || '0'}</span>
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <div className="student-list-padding">
            <StudentList></StudentList>
          </div>
        </div>
      );
    }
  };

  return generateLoadingOrLoadedState();
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeacherStartGame);
