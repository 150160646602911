import { CurrentRound } from '../interfaces/storeInterface';

export const studentJoinGame = (gameCode: string) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_JOIN_GAME',
    payload: {
      activeGameCode: gameCode,
    },
  });
};

export const studentUpdateDetails = (firstName: string, lastInitial: string) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_UPDATE_DETAILS',
    payload: {
      firstName: firstName,
      lastInitial: lastInitial,
    },
  });
};

export const closeWebsockets = () => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_CLOSE_WEBSOCKETS',
  });
};

export const studentAddedToGame = (backendGameInfo: any) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_ADDED_TO_GAME',
    payload: {
      backendGameInfo,
      activeGameCode: backendGameInfo.object_id,
      firstName: backendGameInfo.newest_player?.substring(
        backendGameInfo.newest_player.lastIndexOf(':'),
        backendGameInfo.newest_player.lastIndexOf(' ')
      ),
      lastInitial: backendGameInfo.newest_player?.substring(backendGameInfo.newest_player.lastIndexOf(' ')),
    },
  });
};

export const studentGameUpdated = (onGameUpdatedData: {
  model_id: string;
  object_id: string;
  players: string[];
  rounds: string[];
  status: string;
  teams: string[];
}) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_GAME_UPDATED',
    payload: {
      backendGameInfo: onGameUpdatedData,
    },
  });
};

export const studentRoundUpdated = (onRoundUpdatedData: CurrentRound) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_ROUND_UPDATED',
    payload: {
      currentRound: onRoundUpdatedData,
    },
  });
};

export const studentUpdateRoundAnswer = (answer: string) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_UPDATE_ROUND_ANSWER',
    payload: {
      answer,
    },
  });
};

export const studentUpdateZoinkster = (playerObjectId: string) => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_UPDATE_ZOINKSTER',
    payload: {
      playerObjectId,
    },
  });
};

export const studentKicked = () => (dispatch: any) => {
  dispatch({
    type: 'STUDENT_KICKED',
    payload: { },
  });
};
