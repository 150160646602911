import styles from './QuestionSets.module.scss';
import blue from '../../images/blueRaisedAvatar.png';

function QuestionSets(props: {
  questionSet: any;
  launchGameCallback: (questionSet: any) => void;
  editGameCallback: (questionSet: any) => void;
  deleteGameCallback: (questionSet: any) => void;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.avatarContainer}>
          <img src={blue} alt={'blue avatar'} className={styles.avatar} />
        </div>
        <div className={styles.setName}>
          <h2 className={styles.name}>{props.questionSet.name}</h2>
          {/*<img src={arrow} alt={'arrow'} className={styles.arrow} />*/}
        </div>
        <div className={styles.launchButton}>
          <button className={styles.launch} onClick={() => props.launchGameCallback(props.questionSet)}>
            Launch Game
          </button>
          <button className={styles.delete} onClick={() => props.deleteGameCallback(props.questionSet)}>
            Delete
          </button>
          <button className={styles.edit} onClick={() => props.editGameCallback(props.questionSet)}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestionSets;
