import React from 'react';
import AvatarEnterName from '../../molecules/AvatarEnterName/AvatarEnterName';
import { connect, useDispatch } from 'react-redux';
import { studentUpdateDetails } from '../../redux/actions/studentActions';
import { useHistory } from 'react-router-dom';

const StudentLoginPage = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  document.body.classList.remove('teacherArea');

  const studentHadEnteredName = (firstName: string, lastInitial: string) => {
    studentUpdateDetails(firstName, lastInitial)(dispatch);
    history.push('/selectAvatar');
  };

  return (
    <AvatarEnterName
      submitStudentDetailsCallback={(firstName, lastInitial) => studentHadEnteredName(firstName, lastInitial)}
    />
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(StudentLoginPage);
