import styles from './StudentHeader.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import ZoinkLogo from '../ZoinkLogo/ZoinkLogo';

function StudentHeader(props: any) {
  const getUserTeamName = () => {
    let teamName = '';
    props.StudentStore.fullTeamInfo?.forEach((fullTeam: any) => {
      fullTeam.players.forEach((player: any) => {
        if (player.player_object_id === props.StudentStore.myPlayerId) {
          teamName = fullTeam.name;
        }
      });
    });

    return teamName === 'Lightning' ? 'Meteor' : 'Comet';
  };

  return (
    <div
      className={`${styles.zoinksHeaderWrapper} ${
        getUserTeamName() === 'Meteor' ? `${styles.teamRed}` : `${styles.teamBlue}`
      } grid-x`}>
      <div className={`${styles.logoWrapper} small-6 medium-2 columns`}>
        <ZoinkLogo></ZoinkLogo>
      </div>
      <div className={`${styles.teamNameWrapper} small-6 medium-2 medium-offset-8 columns`}>
        <h2>Team {getUserTeamName()}</h2>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(StudentHeader);
