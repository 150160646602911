import React from 'react';
import Button, { ButtonTypeEnum } from '../../atoms/Button/Button';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import TeamTableSet from '../../molecules/TeamTableSet/TeamTableSet';
import { connect, useDispatch } from 'react-redux';
import { teacherEndGame, teacherStartGame, teacherUpdateRound } from '../../redux/actions/teacherActions';
import TeacherHeader from '../../atoms/TeacherHeader/TeacherHeader';

const TeachersTeamsCreated = (props: any) => {
  document.body.classList.remove('teacherArea');

  return (
    <div>
      <TeacherHeader />
      <div className="fullWidth">
        <TeamTableSet />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeachersTeamsCreated);
