import styles from './TeamTableSet.module.scss';
import React from 'react';
import TeamBox from '../TeamBox/TeamBox';
import { connect } from 'react-redux';
import IntroMusic from '../../atoms/IntroMusic/IntroMusic';

export enum TeamEnum {
  Thunder,
  Lightning,
}

function TeamTableSet(props: any) {
  const getScoreForTeam = (teamName: string) => {
    let teamPoints = 0;
    props.TeacherStore.teamStatus?.forEach((teamWithStatus: any) => {
      if (teamName === teamWithStatus.name) {
        teamPoints = teamWithStatus.awarded_points;
      }
    });

    return teamPoints;
  };

  const getHeaderIfApplicable = () => {
    if (
      props.TeacherStore.currentRound?.object_id?.indexOf(props.TeacherStore.backendGameInfo?.rounds?.[0]) !== -1 &&
      props.TeacherStore.currentRound?.status !== 'FINISHING' &&
      props.TeacherStore.currentRound?.status !== 'DONE'
    ) {
      return <h1 className="textWhite centerText noMarginTop">Go sit with your teams!</h1>;
    } else {
      return <span></span>;
    }
  };

  return (
    <div className={`${styles.container}`}>
      <IntroMusic soundEffect={'PLAYTIME'} isLoop={true} />

      {getHeaderIfApplicable()}
      <div className={`${styles.teams}`}>
        <div className={`${styles.team}`}>
          <TeamBox
            score={getScoreForTeam('Thunder')}
            name={'Comet'}
            team={TeamEnum.Thunder}
            chooserName={
              props.TeacherStore.currentRound?.status === 'FINISHING' && !props.TeacherStore.currentRound?.zoinkster
                ? props.TeacherStore.currentRound?.chooser.substring(
                    props.TeacherStore.currentRound?.chooser.lastIndexOf(':') + 1
                  )
                : ''
            }
          />
        </div>
        <div>
          <h3 className={`${styles.vs}`}>vs.</h3>
        </div>
        <div className={`${styles.team}`}>
          <TeamBox
            score={getScoreForTeam('Lightning')}
            name={'Meteor'}
            team={TeamEnum.Lightning}
            chooserName={
              props.TeacherStore.currentRound?.status === 'FINISHING' && !props.TeacherStore.currentRound?.zoinkster
                ? props.TeacherStore.currentRound?.chooser.substring(
                    props.TeacherStore.currentRound?.chooser.lastIndexOf(':') + 1
                  )
                : ''
            }
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeamTableSet);
