import { useState } from 'react';
import stepOne from '../../images/FirstQuestion.png';
import stepTwo from '../../images/ZoinksterRevealed.png';
import stepThree from '../../images/ZoinksterAnswer.png';
import stepFour from '../../images/ChooserSteal.png';
import stepFive from '../../images/GameEnd.png';
import intro from '../../images/slide1.png';

const Tutorial = () => {
  const [counter, setCounter] = useState<number>(0);
  const incrementCounter = () => {
    if (counter < 5) {
      setCounter(counter + 1);
    } else {
      window.history.back();
    }
  };
  const getCurrentStep = () => {
    if (counter === 0) {
      return (
        <div>
          <div className="startHeader">
            <div className="leftHeader hidden">Tutorial</div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Start
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div id="stepOne" className="intro">
              <img src={intro} className="introImage" alt="intro!" />
            </div>
          </div>
        </div>
      );
    } else if (counter === 1) {
      return (
        <div>
          <div className="tutHeader">
            <div className="leftHeader">
              <h2>{counter} of 5</h2>
            </div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Next
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div id="stepOne" className="stepBody">
              <img src={stepOne} className="stepImage" alt="moonMan!" />
              <p>
                In Zoinks, work with your team to share answers, notes, and ideas. The more teammates who know the
                answer, the more points there are to win!
              </p>
            </div>
          </div>
        </div>
      );
    } else if (counter === 2) {
      return (
        <div>
          <div className="tutHeader">
            <div className="leftHeader">
              <h2>{counter} of 5</h2>
            </div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Next
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div className="stepBody">
              <img src={stepTwo} className="stepImage" alt="moonMan!" />
              <p>
                When time is up to answer each question, Zoinks will pick a Chooser. The Chooser will pick someone from
                the other team who clicked “I Know This” to be the Zoinkster. The Zoinkster’s answer will be displayed.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (counter === 3) {
      return (
        <div>
          <div className="tutHeader">
            <div className="leftHeader">
              <h2>{counter} of 5</h2>
            </div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Next
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div className="stepBody">
              <img src={stepThree} className="stepImage" alt="moonMan!" />
              <p>
                The teacher will decide if the Zoinkster’s answer is correct. A correct answer will earn 2 points per
                student who answered. A partially correct answer is worth 1 point per answer and an incorrect answer is
                worth 0 points.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (counter === 4) {
      return (
        <div>
          <div className="tutHeader">
            <div className="leftHeader">
              <h2>{counter} of 5</h2>
            </div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Next
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div className="stepBody">
              <img src={stepFour} className="stepImage" alt="moonMan!" />
              <p>
                If the Zoinkster is only partially correct or incorrect, the Chooser’s answer will be displayed. The
                Chooser can steal half or all of the points if they have a better answer!{' '}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (counter === 5) {
      return (
        <div>
          <div className="tutHeader">
            <div className="leftHeader">
              <h2>{counter} of 5</h2>
            </div>
            <div className="rightHeader">
              <button className="yellowButton" onClick={() => incrementCounter()}>
                Next
              </button>
            </div>
          </div>
          <div className="tutBody">
            <div className="stepBody">
              <img src={stepFive} className="stepImage" alt="moonMan!" />
              <p>
                After each question, the team with the Zoinkster and the team with the Chooser will rotate. At the end
                of the game, the team with the most points wins! Work together, do your best, and have fun!
              </p>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="tutContainer">
      {/*<div className="tutHeader">*/}
      {/*    <div className="leftHeader"><h2>{counter} of 5</h2></div>*/}
      {/*    <div className="rightHeader"><button className="yellowButton" onClick={() => incrementCounter()}>Next</button></div>*/}
      {/*</div>*/}
      {/*<div className="tutBody">*/}
      {getCurrentStep()}
      {/*</div>*/}
    </div>
  );
};

export default Tutorial;
