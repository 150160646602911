import Sound from 'react-sound';
import React from 'react';
// @ts-ignore
import lobby from '../../audio/intro_2.wav';
// @ts-ignore
import oliveGarden from '../../audio/gameMusic.mp3';
// @ts-ignore
import win from '../../audio/RightAnswerWin.wav';
// @ts-ignore
import timeRunningOut from '../../audio/TimeRunningOut.wav';
// @ts-ignore
import timeUp from '../../audio/TimesUp.mp3';
// @ts-ignore
import funkyZoinks from '../../audio/FunkyZoinksv2.wav';
// @ts-ignore
import loseGame from '../../audio/LoseGame.mp3';
// @ts-ignore
import noAnswer from '../../audio/NoAnswer.wav';
// @ts-ignore
import playtime from '../../audio/Playfulintro.wav';
// @ts-ignore
import writingGame from '../../audio/WritingGame.wav';
// @ts-ignore
import writingGameNoTicking from '../../audio/WritingGameNoTicking.wav';
// @ts-ignore
import bell from '../../audio/BellSound.wav';
// @ts-ignore
import ClockTicking from '../../audio/ClockTicking.wav';
// @ts-ignore
import incorrect from '../../audio/Incorrect.wav';
// @ts-ignore
import correct from '../../audio/Correct.wav';
// @ts-ignore
import multiGame2 from '../../audio/10SecMulti.wav';
// @ts-ignore
import partialCorrect from '../../audio/partialCorrect.wav';
// @ts-ignore
import multiGame from '../../audio/multi.wav';
import play from '../../images/speakers.png';
import mute from '../../images/speakers-mute.png';
import alien from '../../images/Alien_Half.svg';
import styles from '../IntroMusic/IntroMusic.module.scss';

class IntroMusic extends React.Component {
  public musicEffect = [
    { soundEffect: 'INTRO', soundUrl: lobby },
    { soundEffect: 'PLAYTIME', soundUrl: playtime },
    { soundEffect: 'GAMEPLAY', soundUrl: oliveGarden },
    { soundEffect: 'WIN', soundUrl: win },
    { soundEffect: 'TIMERUNNINGOUT', soundUrl: timeRunningOut },
    { soundEffect: 'TIMEUP', soundUrl: timeUp },
    { soundEffect: 'ZOINK', soundUrl: funkyZoinks },
    { soundEffect: 'LOSE', soundUrl: loseGame },
    { soundEffect: 'NOANSWER', soundUrl: noAnswer },
    { soundEffect: 'INCORRECT', soundUrl: incorrect },
    { soundEffect: 'CORRECT', soundUrl: correct },
    { soundEffect: 'PARTIALCORRECT', soundUrl: partialCorrect },
    { soundEffect: 'WRITEGAMEPLAY', soundUrl: writingGame },
    { soundEffect: 'WRITEGAMEPLAYNOTICKING', soundUrl: writingGameNoTicking },
    { soundEffect: 'CLOCKTICKING', soundUrl: ClockTicking },
    { soundEffect: 'BELL', soundUrl: bell },

    { soundEffect: 'MULTIGAMEPLAY', soundUrl: multiGame },
    { soundEffect: '10MULTIGAMEPLAY', soundUrl: multiGame2 },
  ];
  public props: any;
  public state: any;

  constructor(props: { soundEffect: string; isLoop: boolean; hideButton: boolean }) {
    super(props);
    this.state = {
      playStatus: 'PLAYING',
    };
    console.log('State', this.state);
  }
  onChange() {
    this.setState({ playStatus: this.state.playStatus === 'PAUSED' ? 'PLAYING' : 'PAUSED' });
    console.log('STATUS', this.state.playStatus);
  }

  render() {
    const url = this.musicEffect.find((music) => music.soundEffect === this.props.soundEffect)?.soundUrl;

    return (
      <div>
        <Sound url={url ? url : ''} playStatus={this.state.playStatus} loop={this.props.isLoop} />

        {!this.props.hideButton && (
          <div className={`${styles.buttonContainer}`}>
            <button onClick={this.onChange.bind(this)} className={`${styles.speakerButton}`}>
              {this.state.playStatus === 'PLAYING' && (
                <img src={play} className={`${styles.speaker}`} alt={'speaker'} />
              )}
              {this.state.playStatus === 'PAUSED' && <img src={mute} className={`${styles.speaker}`} alt={'speaker'} />}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default IntroMusic;
