/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  //DEV
  // aws_cognito_identity_pool_id: 'us-west-2:33f6a1c2-64ea-4462-8b45-326c10892124',
  // aws_cognito_region: 'us-west-2',
  // aws_user_pools_id: 'us-west-2_RqHJpn6Kx',
  // aws_user_pools_web_client_id: 'crs859s2jmmmlvh6dck5u0b6p',
  //LIVE
  aws_cognito_identity_pool_id: 'us-west-2:d90cea19-46cd-4c59-a742-3d263099b3ab',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_OMHliu8rG',
  aws_user_pools_web_client_id: '7p1ftos2jo4db1718h6asfqfig',
  oauth: {},
  aws_appsync_graphqlEndpoint: 'https://4ebaeyz42vdozlwjsve3oiqihu.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AWS_IAM',
  aws_appsync_apiKey: 'da2-5onwwadbnbh65lpgsxpaxwikfe',
  // storage: sessionStorage,
  cookieStorage: {
    domain: location.host.indexOf('localhost') !== -1 ? 'localhost' : '.' + location.host.split('.').reverse()[1] + '.' + location.host.split('.').reverse()[0],
    secure: location.host.indexOf('localhost') === -1,
    path: '/',
    expires: 7,
  },
};

export default awsmobile;
