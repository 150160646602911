// import React from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



// class SmallQuestionsBox extends React.Component {
//   public props: any;
//   public state: any;

//   constructor(props: { textChangedCallback: any; questionIndex: number; startingText?: string }) {
//     super(props);
//     this.state = {editorState: EditorState.createEmpty()};
//   }

//   onEditorStateChange = (editorState: any) => {
//     this.setState({
//       editorState,
//     });
//     if (this.props.textChangedCallback && editorState?.getCurrentContent()) {
//       this.props.textChangedCallback(draftToHtml(convertToRaw(editorState.getCurrentContent())));
//     }
//   };

//   render() {
//     return (
//       <div>
//         <Editor
//           editorState={this.state.editorState}
//           wrapperClassName="demo-wrapper"
//           editorClassName="editor-body"
//           onChange={this.onEditorStateChange}
//         />
//       </div>
//     );
//   }
// }

// export default SmallQuestionsBox;


import { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class SmallQuestionsBox extends Component {
  public props: any;
  public state = {
    editorState: EditorState.createEmpty(),
  };
  

  componentDidMount() {
    if (this.props.startingText) {
      this.setState({
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.startingText).contentBlocks))
      })
    }
  }

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });

    if (this.props.textChangedCallback) {
      this.props.textChangedCallback(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="editor-body"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: ['inline', 'list', 'link', 'emoji', 'image', 'history'],
          image: {
            defaultSize: {
              height: 'auto',
              width: '50%'
            },
            previewImage: true,
            uploadEnabled: false,
            alignmentEnabled: false
          }
        }}
      />
    )
  }
}

export default SmallQuestionsBox;
