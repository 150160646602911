import styles from './LargeText.module.scss';
import React from 'react';

function LargeText(text: { largeText?: string; smallText?: string; tinyText?: string }) {
  return (
    <div className={`${styles.wrapper}`}>
      {text.largeText && <h2 className={`${styles.large}`}>{text.largeText}</h2>}
      {text.smallText && <p className={`${styles.small}`}>{text.smallText}</p>}
      {text.tinyText && <p className={`${styles.tiny}`}>{text.tinyText}</p>}
    </div>
  );
}

export default LargeText;
