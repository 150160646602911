import styles from './Timer.module.scss';
import { useEffect, useState } from 'react';
import * as moment from 'moment';

const Timer = (props: {
  startTimestamp?: number;
  timeAllowed: number;
  timeUpCallback?: () => void;
  setTimeLeft?: (timeLeft: number) => void;
}) => {
  const [minsLeft, setMinsLeft] = useState<number>(0);
  const [secsLeft, setSecsLeft] = useState<number>(0);
  const [timerEnabled, setTimerEnabled] = useState<boolean>(false);

  const safariRenderHack = { opacity: secsLeft % 2 ? 1 : 0.99 };

  useEffect(() => {
    const startTimestamp = props.startTimestamp || Math.floor(Date.now() / 1000);
    const interval = setInterval(() => {
      const secondsLeft =
        moment.default(moment.unix(startTimestamp).valueOf()).diff(moment.default(), 'seconds') + props.timeAllowed;
      if (secondsLeft >= 0) {
        setMinsLeft(Math.floor(secondsLeft / 60));
        setSecsLeft(secondsLeft - Math.floor(secondsLeft / 60) * 60);
        if (props.setTimeLeft) {
          props.setTimeLeft(secondsLeft);
        }
      } else {
        setMinsLeft(0);
        setSecsLeft(0);
        if (props.timeUpCallback) {
          props.timeUpCallback();
        }
        clearInterval(interval);
      }
      if (!timerEnabled) {
        setTimerEnabled(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.wrapper} style={safariRenderHack}>
      <p className={styles.timer}>
        {timerEnabled ? (
          <span>{minsLeft ? minsLeft + ':' + (secsLeft < 10 ? '0' + secsLeft : secsLeft) : secsLeft}</span>
        ) : (
          <div className="loaderContainer">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        )}
      </p>
      {/* {secsLeft <= 6 && <IntroMusic soundEffect={'TIMERUNNINGOUT'} isLoop={false} hideButton={true}/>} */}
      {/* {secsLeft <= 1 && <IntroMusic soundEffect={'TIMEUP'} isLoop={false} hideButton={true}/>} */}
    </div>
  );
};

export default Timer;
