import React from 'react';
import LargeInput from '../atoms/LargeInput/LargeInput';
import StandoutTitle from '../atoms/StandoutTitle/StandoutTitle';
import ZoinkLogo from '../atoms/ZoinkLogo/ZoinkLogo';
import ColorBox from '../molecules/ColorBox/ColorBox';
import LargeText from '../molecules/LargeText/LargeText';
import Avatar from '../atoms/Avatar/Avatar';
import MoonRock from '../atoms/MoonRock/MoonRock';
import AvatarHolding from '../atoms/AvatarHolding/AvatarHolding';
import AnswerBox from '../atoms/AnswerBox/AnswerBox';
import QuestionBox from '../atoms/QuestionBox/QuestionBox';
import TeamNames from '../atoms/TeamNames/TeamNames';
import AnswerSelected from '../atoms/AnswerSelected/AnswerSelected';
import AvatarName from '../atoms/AvatarName/AvatarName';
import AvatarDisabled from '../atoms/AvatarDisabled/AvatarDisabled';
import { TeamEnum } from '../molecules/TeamBox/TeamBox';
import TeamCorrectSmall from '../atoms/TeamCorrectSmall/TeamCorrectSmall';
import PointsView from '../molecules/PointsView/PointsView';
import CorrectionLogo, { AnswerEnum } from '../atoms/CorrectionLogo/CorrectionLogo';
import AvatarMoon from '../atoms/AvatarMoon/AvatarMoon';
import Clock from '../atoms/Clock/Clock';
import AvatarChooser from '../atoms/AvatarChooser/AvatarChooser';
import AvatarZoinkster from '../atoms/AvatarZoinkster/AvatarZoinkster';
import AvatarQuestionHolding from '../atoms/AvatarQuestionHolding/AvatarQuestionHolding';
import CreateQuestions from '../atoms/CreateQuestions/CreateQuestions';
import SideBanner from '../atoms/SideBanner/SideBanner';
import QuestionSets from '../atoms/QuestionSets/QuestionSets';
import TitleSets from '../atoms/TitleSets/TitleSets';
import SmallQuestionsBox from '../atoms/SmallQuestionsBox/SmallQuestionsBox';
import AnswerFormat from '../atoms/AnswerFormat/AnswerFormat';
import GroupingBox from '../atoms/GroupingBox/GroupingBox';
import CheckboxText from '../atoms/CheckboxText/CheckboxText';
import Attachments from '../atoms/Attachments/Attachments';
import SetTime from '../atoms/SetTime/SetTime';
// import SignIn from '../molecules/SignIn/SignIn';
import ResetPassword from '../atoms/ResetPassword/ResetPassword';
import Rocket from '../atoms/Rocket/Rocket';
import TeamCorrect from '../atoms/TeamCorrect/TeamCorrect';
import TeamGameEnd from '../atoms/TeamGameEnd/TeamGameEnd';
import GameWinner from './StudentFlow/GameWinner';
import Ribbon from '../atoms/Ribbon/Ribbon';
import TeamBox from '../molecules/TeamBox/TeamBox';
import CorrectionBar from '../atoms/CorrectionBar/CorrectionBar';
import CorrectionLabel from '../atoms/CorrectionLabel/CorrectionLabel';
import Button, { ButtonTypeEnum } from '../atoms/Button/Button';
import TeamTable from '../atoms/TeamTable/TeamTable';
import styles from '../molecules/QuestionView/QuestionView.module.scss';
import StudentAnswer from './StudentFlow/StudentAnswer';
import StudentsAnswerSection from '../atoms/StudentsAnswerSection/StudentsAnswerSection';
import ZoinksterAnswerSection from '../atoms/ZoinksterAnswerSection/ZoinksterAnswerSection';
import AnswersSection from '../molecules/AnswersSection/AnswersSection';
import TeachersQuestionView from '../pages/TeacherFlow/TeachersQuestionView';
import ZoinksterSelected from '../atoms/ZoinksterSelected/ZoinksterSelected';
import AvatarLongLine from '../molecules/AvatarLongLine/AvatarLongLine';
import PickZoinkster from '../molecules/PickZoinkster/PickZoinkster';
import ZoinkSelect from './StudentFlow/ZoinkSelect';
import Slider from '../atoms/Slider/Slider';
import Feedback from '../atoms/Feedback/Feedback';
import Canvas from '../atoms/Canvas/Canvas';
import AnnounceChooser from './TeacherFlow/AnnounceChooser';

function TestPage() {
  document.body.classList.remove('teacherArea');
  function handleImageSave(imageData: string): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="grid-container">
      {/* <Canvas onImageSave={handleImageSave} /> */}
      {/* {<Canvas></Canvas>} */}
      {/* <AvatarLongLine></AvatarLongLine> */}
      {/*<StudentsAnswerSection myAnswer={'tester'} answered={true}></StudentsAnswerSection>*/}
      {/*<StudentsAnswerSection myAnswer={' '} answered={true}></StudentsAnswerSection>*/}
      {/*<StudentsAnswerSection myAnswer={''} answered={false}></StudentsAnswerSection>*/}
      {/* {<ZoinksterSelected zoinksterName={'asd'} />} */}
      {/* <p>Didn't Answer Red</p>
        <AnswersSection answered={false} myAnswer={''} zoinksterAnswer={''} zoinksterName={''}></AnswersSection>

        <p>Bluffed Blue</p>
        <AnswersSection answered={true} myAnswer={' '} zoinksterAnswer={''} zoinksterName={''}></AnswersSection>

        <p>Answered Green</p>
        <AnswersSection answered={true} myAnswer={'Answer here'} zoinksterAnswer={''} zoinksterName={''}></AnswersSection> */}
      {/*<div className={`${styles.teams}`}>*/}
      {/*  <TeamTable*/}
      {/*    name={'Thunder'}*/}
      {/*    team={TeamEnum.Thunder}*/}
      {/*    playersAnswered={32}></TeamTable>*/}
      {/*  <h3>vs.</h3>*/}
      {/*  <TeamTable*/}
      {/*    name={'Lightning'}*/}
      {/*    team={TeamEnum.Lightning}*/}
      {/*    playersAnswered={213}></TeamTable>*/}
      {/*</div>*/}
      {/*<Rocket />*/}
      {/*<ResetPassword />*/}
      {/*/!*<SignIn />*!/*/}
      {/*<SetTime minPlaceHolder={'0 min'} secPlaceHolder={'20 sec'} timeChangedCallback={() => {}} />*/}
      {/*<Attachments />*/}
      {/*<CheckboxText text={'Multiple Choice'} />*/}
      {/*<GroupingBox />*/}
      {/*<AnswerFormat optionChangedCallback={() => {}} />*/}
      {/*/!*<SmallQuestionsBox textChangedCallback={() => {}} questionIndex={0} />*!/*/}
      {/*<TitleSets />*/}
      {/*/!*<QuestionSets />*!/*/}
      {/*<SideBanner teacherName={'Alice Doe'} />*/}
      {/*<CreateQuestions clickCallback={() => {}} />*/}
      {/*<ZoinkLogo />*/}
      {/*<ZoinksterAnswerSection zoinksterAnswer='testing'/>*/}
      {/*<TeachersQuestionView question= {'testing'}  startTimestamp= {300} timeAllowed= {300} thunderPlayersAnswered= {5} lightningPlayersAnswered= {5} />*/}
      {/*<TeachersQuestionView question= {'testing'} fakeAnswers={["TestA","Testb","Testc","Testd"]} startTimestamp= {300} timeAllowed= {300} thunderPlayersAnswered= {5} lightningPlayersAnswered= {5} />*/}
      {/*<AvatarZoinkster />*/}
      {/*<AvatarChooser />*/}
      {/*<Clock />*/}
      {/*<AvatarMoon />*/}
      {/*<CorrectionLogo answer={AnswerEnum.Correct} />*/}
      {/*<CorrectionLogo answer={AnswerEnum.Incorrect} />*/}
      {/*<PointsView points={5} />*/}
      {/*<TeamCorrectSmall team={TeamEnum.Thunder} />*/}
      {/*<TeamCorrectSmall team={TeamEnum.Lightning} />*/}
      {/*<TeamCorrect team={TeamEnum.Thunder} />*/}
      {/*<TeamCorrect team={TeamEnum.Lightning} />*/}
      {/* <TeamGameEnd name={'Thunder'} team={TeamEnum.Thunder} />*/}
      {/* <GameWinner winningTeam={TeamEnum.Thunder} />*/}
      {/*<TeamGameEnd name={'Lightning'} team={TeamEnum.Lightning} />*/}
      {/* {<Ribbon />} */}
      {/*<TeamCorrect team={TeamEnum.Thunder} />*/}
      {/*<TeamCorrect team={TeamEnum.Lightning} />*/}
      {/*/!*<TeamBox name={'Thunder'} score={3} team={TeamEnum.Thunder} header={'10 know it!'} />*!/*/}
      {/*/!*<TeamBox name={'Lightning'} score={2} team={TeamEnum.Lightning} header={'And the Chooser is...'} />*!/*/}
      {/*<CorrectionBar answerStatusCallback={() => {}} />*/}
      {/*<CorrectionLabel text={'Incorrect'} answer={AnswerEnum.Incorrect} />*/}
      {/*<CorrectionLabel text={'Correct'} answer={AnswerEnum.Correct} />*/}
      {/* <AnswerSelected answerType={'asd'} answer={'asd' ?? ''} hideHeader={false} /> */}
      <AnnounceChooser></AnnounceChooser>
      {/*<TeamNames text={'Team Thunder'} team={TeamEnum.Thunder} />*/}
      {/*<TeamNames text={'Team Lightning'} team={TeamEnum.Lightning} />*/}
      {/*<QuestionBox question={'Write a sentence that has alliteration and a metaphor'} roundedCorners={true} />*/}
      {/*<AnswerBox placeholderText={'Type your answer here'} />*/}
      {/*/!*<AvatarDisabled />*!/*/}
      {/*<AvatarHolding />*/}
      {/*<AvatarName studentName={'Edward M.'} />*/}
      {/*<ImageTextTutorial />*/}
      {/*<MoonRock />*/}
      {/*<LargeText largeText={"You're in!"} smallText={'Customize your avatar while you wait'} />*/}
      {/*<ColorBox />*/}
      {/*<LargeInput placeholderText={'Enter Code'} />*/}
      {/*<StandoutTitle text={"You're on Team Thunder"} team={TeamEnum.Thunder} />*/}
      {/*<StandoutTitle text={"You're on Team Lightning"} team={TeamEnum.Lightning} />*/}
      {/*<Button text={'Enter'} type={ButtonTypeEnum.Primary} clickCallback={() => {}} />*/}
    </div>
  );
}

export default TestPage;
