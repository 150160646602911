import styles from './AnswerFormat.module.scss';
import React from 'react';

function AnswerFormat(props: { optionChangedCallback: any; autoSelect?: string }) {
  return (
    <div className={styles.body}>
      <label className={styles.checkbox}>
        Write
        <input
          name="answerFormat"
          type="radio"
          value="WRITTEN"
          onChange={props.optionChangedCallback}
          checked={props.autoSelect === 'WRITTEN'}
        />
        <span className={styles.title}></span>
      </label>
      <label className={styles.checkbox}>
        Multiple Choice
        <input
          name="answerFormat"
          type="radio"
          value="MULTI"
          onChange={props.optionChangedCallback}
          checked={props.autoSelect === 'MULTI'}
        />
        <span className={styles.title}></span>
      </label>
      <label className={styles.checkbox}>
        Draw
        <input
          name="answerFormat"
          type="radio"
          value="DRAW"
          onChange={props.optionChangedCallback}
          checked={props.autoSelect === 'DRAW'}
        />
        <span className={styles.title}></span>
      </label>
    </div>
  );
}

export default AnswerFormat;
