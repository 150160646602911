import styles from './SignIn.module.scss';
import React, { useEffect, useState } from 'react';
import wowza from '../../../images/wowzaLogoBlue.svg';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import MoonRock from '../../../atoms/MoonRock/MoonRock';
import Avatar from '../../../atoms/Avatar/Avatar';

const SignIn = () => {
  const history = useHistory();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPasswordRequired, setNewPasswordRequired] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState({});
  const [isAutoLogin, setIsAutoLogin] = useState<boolean>(false);
  const [userDisabled, setUserDisabled] = useState<boolean>(false);
  document.body.classList.add('teacherArea');

  const continueToTeacherPortal = () => {
    history.push('/teacher/portal');
  };

  const loginUser = (authUsername?: any, authPassword?: any) => {
    try {
      let loginUsername = '';
      let loginPassword = '';

      if (authUsername && authPassword) {
        loginUsername = authUsername;
        loginPassword = authPassword;
      } else {
        loginUsername = username;
        loginPassword = password;
      }

      setIsLoading(true);
      Auth.signIn(loginUsername, loginPassword).then(
        (user) => {
          setIsAutoLogin(false);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setPassword('');
            setUser(user);
            setNewPasswordRequired(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            continueToTeacherPortal();
          }
        },
        (error) => {
          if (error.message === 'User is disabled.') {
            //If the account is disabled
            setUserDisabled(true);
          } else {
            //Generic error handling
            setPassword('');
            setFormError(true);
            setIsLoading(false);
          }
        }
      );
    } catch (error) {
      setPassword('');
      setFormError(true);
      setIsLoading(false);
    }
  };

  const checkLoginStatus = () => {
    Auth.currentSession()
      .then((data) => {
        //If in here already logged in, continue
        continueToTeacherPortal();
      })
      .catch();
  };

  useEffect(() => {
    if (window.location.href.indexOf('/token') !== -1) {
      setIsAutoLogin(true);
      setUsername(getParameterByName('email') || '');
      setPassword(getParameterByName('id') || '');
      loginUser(getParameterByName('email') || '', getParameterByName('id') || '');
    } else {
      setIsAutoLogin(false);
      checkLoginStatus();
    }
  }, []);

  const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
  };

  const updatePassword = () => {
    try {
      setIsLoading(true);
      Auth.completeNewPassword(user, password).then(
        (user) => {
          setIsLoading(false);
          continueToTeacherPortal();
        },
        () => {
          setFormError(true);
          setIsLoading(false);
        }
      );
    } catch (err) {
      setFormError(true);
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    if (isAutoLogin && !formError) {
      return (
        <div className={styles.textBody}>
          <p className={'marginTop marginBottom centerText'}>We're setting up your account. Please wait.</p>
        </div>
      );
    } else if (isAutoLogin && formError) {
      return (
        <div className={styles.textBody}>
          <p className={'marginTop marginBottom centerText'}>Oops, this redemption link is no longer valid.</p>
          <ul className={styles.errorList}>
            <li>
              If you need support, please return to <a href={'https://wowzalearning.com'}>our homepage</a>. We're on
              hand to help!
            </li>
            <li className={'marginTopSmall'}>
              Or you can <a href={'https://app.wowzalearning.com'}>login here</a>.
            </li>
          </ul>
        </div>
      );
    } else if (userDisabled) {
      return (
        <div className={styles.textBody}>
          <p>Uh no. Your account has expired! Please renew your subscription to continue.</p>
        </div>
      );
    } else if (formError && !newPasswordRequired) {
      return (
        <div className={styles.textBody}>
          <p>Oops, something is not quite right. Please try again.</p>
          <input
            type="text"
            name="username"
            value={username}
            className={styles.inputField}
            placeholder="Email"
            autoComplete={'email'}
            onChange={(event) => setUsername(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                loginUser();
              }
            }}
          />
          <input
            type="password"
            name="password"
            className={styles.inputFieldPassword}
            placeholder="Password"
            autoComplete={'current-password'}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                loginUser();
              }
            }}
          />
          <button className={'button ' + styles.openButton} onClick={loginUser}>
            {isLoading ? 'Please wait...' : 'Sign In'}
          </button>
          {/*<a href={'#'} className={styles.link}>*/}
          {/*  Forgot Password?*/}
          {/*</a>*/}
        </div>
      );
    } else if (formError && newPasswordRequired) {
      return (
        <div className={styles.textBody}>
          <p>
            Oops, something is not quite right. Your password must have at least 8 characters. It must also include at
            least...
          </p>
          <ul className={styles.errorList}>
            <li>1 uppercase character</li>
            <li>1 lowercase character</li>
            <li>1 number</li>
          </ul>
          <input
            type="password"
            name="password"
            className={styles.inputFieldPassword}
            placeholder="Password"
            autoComplete={'off'}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                updatePassword();
              }
            }}
          />
          <button className={'button ' + styles.openButton} onClick={updatePassword}>
            {isLoading ? 'Please wait...' : 'Update Password'}
          </button>
        </div>
      );
    } else if (newPasswordRequired) {
      return (
        <div className={styles.textBody}>
          <p>To get started please set a new password.</p>
          <input
            type="password"
            name="password"
            className={styles.inputFieldPassword}
            placeholder="Password"
            autoComplete={'off'}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                updatePassword();
              }
            }}
            autoFocus={true}
          />
          <button className={'button ' + styles.openButton} onClick={updatePassword}>
            {isLoading ? 'Please wait...' : 'Update Password'}
          </button>
        </div>
      );
    } else {
      return (
        <div className={styles.textBody}>
          <input
            type="text"
            name="username"
            className={styles.inputField}
            placeholder="Email"
            autoComplete={'email'}
            onChange={(event) => setUsername(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                loginUser();
              }
            }}
            autoFocus={true}
          />
          <input
            type="password"
            name="password"
            className={styles.inputFieldPassword}
            placeholder="Password"
            autoComplete={'current-password'}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                loginUser();
              }
            }}
          />
          <button className={'button ' + styles.openButton} onClick={loginUser}>
            {isLoading ? 'Please wait...' : 'Sign In'}
          </button>
          {/*<a href={'#'} className={styles.link}>*/}
          {/*  Forgot Password?*/}
          {/*</a>*/}
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarLeft}>
        <Avatar />
      </div>
      <div className={styles.avatarLeftTwo}>
        <Avatar />
      </div>
      <div className={styles.moonRockLeft}>
        <MoonRock />
      </div>
      <div className={styles.moonRockTwoLeft}>
        <MoonRock />
      </div>
      {isAutoLogin ? (
        <h1 className={styles.wowzaTitle}>Welcome to Wowza Learning!</h1>
      ) : (
        <h1 className={styles.wowzaTitle}>Sign in to get started!</h1>
      )}
      <div className={styles.body}>
        <div className={styles.header}></div>
        <img src={wowza} alt={'wowza logo'} className={styles.logo} />
        {renderForm()}
      </div>
      <div className={styles.moonRockRight}>
        <MoonRock />
      </div>
      <div className={styles.moonRockTwoRight}>
        <MoonRock />
      </div>
      <div className={styles.avatarRight}>
        <Avatar />
      </div>
      <div className={styles.avatarRightTwo}>
        <Avatar />
      </div>
    </div>
  );
};

export default SignIn;
