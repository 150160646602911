import styles from './AvatarMoon.module.scss';
import moonMan from '../../images/manonMoon.png';
import React from "react";

function AvatarMoon() {
    return (
        <div className={`${styles.container}`}>
            <img src={moonMan} className={styles.image} alt="moonMan!"/>
        </div>

    );
}

export default AvatarMoon;
