import { useEffect, useState } from 'react';
import TeamGameEnd, { TeamEnum } from '../../atoms/TeamGameEnd/TeamGameEnd';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import confetti from 'canvas-confetti';

function GameWinner(props: { winningTeam: TeamEnum; gameCode?: string; isTeacher?: boolean }) {
  const [winnerText, setWinnerText] = useState<string>('Drumroll please...');
  const [active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setWinnerText('and the winner is...');
    }, 2000);
    setTimeout(() => {
      setActive(true);
    }, 10000);
    setTimeout(() => {
      if (props.winningTeam === TeamEnum.Thunder) {
        setWinnerText('Team Comet!');
      } else if (props.winningTeam === TeamEnum.Lightning) {
        setWinnerText('Team Meteor!');
      } else {
        setWinnerText("It's a Draw!");
      }

      confetti({
        particleCount: 300,
        spread: 200,
        gravity: 0.5,
        shapes: ['square'],
      });
    }, 4000);

    setTimeout(() => {
      confetti({
        particleCount: 300,
        spread: 200,
        gravity: 0.5,
        shapes: ['square'],
      });
    }, 6000);

    setTimeout(() => {
      confetti({
        particleCount: 300,
        spread: 200,
        gravity: 0.5,
        shapes: ['square'],
      });
    }, 8000);
  }, []);

  return (
    <div className="oneScreen textWhite centerText">
      <div className={'grid-x grid-margin-x grid-padding-x marginTopSmall'}>
        <div className={'small-12 medium-2 cell'}>
          <ZoinkLogo />
        </div>
      </div>
      <h1 className={'headerLarge mobileHeaderLargeReduce'}>{winnerText}</h1>
      <TeamGameEnd team={0} winners={winnerText === 'Team Comet!' || winnerText === "It's a Draw!"} />
      <TeamGameEnd team={1} winners={winnerText === 'Team Meteor!' || winnerText === "It's a Draw!"} />
      {active && props.gameCode && !props.isTeacher && (
        <button className={'button leaveFeedbackButton'}>
          <a
            href={
              'https://docs.google.com/forms/d/e/1FAIpQLSc_4XL5gFAjCIXZmmCuDPJQAdtCuXoVfk1IWjaxBVWBZTYuHw/viewform?usp=pp_url&entry.2094896879=' +
              props.gameCode
            }>
            Leave Feedback
          </a>
        </button>
      )}
      {active && props.gameCode && props.isTeacher && (
        <button className={'button leaveFeedbackButton'}>
          <a
            href={
              'https://docs.google.com/forms/d/e/1FAIpQLSc5D_hWLpW115pWYfmgfQabKEy4ldE_7I1IQJ7CQy98PK9a_w/viewform?usp=pp_url&entry.2086046588=' +
              props.gameCode
            }>
            Leave Feedback
          </a>
        </button>
      )}
    </div>
  );
}

export default GameWinner;
