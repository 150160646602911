import styles from './Incorrect.module.scss';
import moonMan from '../../images/incorrect.png';
import React from "react";

function Incorrect () {
    return (
        <div className={`${styles.container}`}>
            <img src={moonMan} className={styles.image} alt="incorrect!"/>
        </div>

    );
}

export default Incorrect;
