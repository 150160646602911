import React from 'react';
import PickZoinkster from '../../molecules/PickZoinkster/PickZoinkster';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';

function ZoinkSelect() {
  return (
    <div>
      <StudentHeader />
      <div className="grid-container">
        <div className="grid-x">
          <div className="cell">
            <PickZoinkster></PickZoinkster>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZoinkSelect;
