import React, { useEffect, useRef, useState } from 'react';
import styles from './Canvas.module.scss';

type CanvasProps = {
  onImageSave: (imageData: string) => void;
};

const Canvas: React.FC<CanvasProps> = ({ onImageSave }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [color, setColor] = useState('#000000');
  const [size, setSize] = useState(5);

  const handlePointerDown = (e: React.PointerEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    setLastX(e.nativeEvent.offsetX);
    setLastY(e.nativeEvent.offsetY);
  };

  const handlePointerUp = () => {
    setIsDrawing(false);
  };

  const handlePointerOut = () => {
    setIsDrawing(false);
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;

    ctx.beginPath();
    ctx.moveTo(lastX, lastY);
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.strokeStyle = color;
    ctx.lineWidth = size;
    ctx.stroke();

    setLastX(e.nativeEvent.offsetX);
    setLastY(e.nativeEvent.offsetY);
    handleSaveClick();
  };

  useEffect(() => {
    function handleResize() {
      const canvas = canvasRef.current;
      const mediaQuery = window.matchMedia('(max-width: 600px)');
      if (!canvas) return;

      if (mediaQuery.matches) {
        canvas.width = 400;
        canvas.height = 200;
      } else {
        canvas.width = 800;
        canvas.height = 400;
      }
    }

    handleResize(); // Initial call to set the canvas dimensions

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSize(Number(e.target.value));
  };

  const handleClearClick = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const handleSaveClick = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const image = canvas.toDataURL('image/png');
    onImageSave(image);
  };

  return (
    <div className={styles.canvasContainer}>
      <div className={styles.controls}>
        <input type="color" className={styles.colorPicker} value={color} onChange={handleColorChange} />
        <input type="range" className={styles.sizeSlider} min="1" max="20" value={size} onChange={handleSizeChange} />
        <button onClick={handleClearClick}>Clear</button>
      </div>
      <div className={styles.canvas}>
        <canvas
          ref={canvasRef}
          onPointerDown={(e) => {
            e.preventDefault(); // Prevent scrolling on touch devices
            handlePointerDown(e);
          }}
          onPointerUp={handlePointerUp}
          onPointerOut={handlePointerOut}
          onPointerMove={handlePointerMove}
        />
      </div>
    </div>
  );
};
export default Canvas;
