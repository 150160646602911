import React, {useEffect, useState} from 'react';
import Button, {ButtonAreaEnum, ButtonTypeEnum} from '../../atoms/Button/Button';
import SmallQuestionsBox from '../../atoms/SmallQuestionsBox/SmallQuestionsBox';
import AnswerFormat from '../../atoms/AnswerFormat/AnswerFormat';
import SetTime from '../../atoms/SetTime/SetTime';
import {API, graphqlOperation} from 'aws-amplify';
import {addQuestionSet, editQuestionSet} from '../../graphql/mutations';
import {useHistory} from 'react-router-dom';
import * as moment from 'moment';
import {getQuestionSet} from '../../graphql/queries';
import {teacherDeleteGame, teacherEditGame} from '../../redux/actions/teacherActions';
import {useDispatch} from 'react-redux';

const TeacherCreateQuestionSetPage = () => {
  document.body.classList.add('teacherArea');

  const history = useHistory();
  const dispatch = useDispatch();

  const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
  };

  //Top Level State Params
  const [title, setTitle] = useState<string>('');
  const [questions, setQuestions] = useState<
    {
      question: string;
      answer_type: string;
      time_limit: number;
      potential_points: number;
      fake_answers?: string[];
    }[]
  >([]);
  const [currentQuestionInView, setCurrentQuestionInView] = useState<number>(0);
  const [disableSaveAndExit, setDisableSaveAndExit] = useState<boolean>(true);
  const [questionUpdated, setQuestionUpdate] = useState<boolean>(true);
  const [disableNextQuestion, setDisableNextQuestion] = useState<boolean>(true);
  const [questionBox, setQuestionBox] = useState<any>();


  //State params for building the current question
  const [currentQuestion, setCurrentQuestion] = useState<{
    question: string;
    answer_type: string;
    time_limit: number;
    potential_points: number;
    fake_answers?: string[];
  }>({
    question: '',
    answer_type: '',
    time_limit: 0,
    potential_points: 10,
  });

  const deleteGame = async (questionSet: { object_id: string }) => {
    teacherDeleteGame(questionSet.object_id)(dispatch);
  };

  const editGame = async (questionSet: { object_id: string }) => {
    teacherEditGame(questionSet.object_id)(dispatch);
  }

  const saveQuestionSet = async () => {
    try {
      //If we were editing delete the old one first
      const wasEditingId = getParameterByName('editId');
      if (wasEditingId) {
        deleteGame({ object_id: wasEditingId });
      }
      //Save the current and then save all
      let theQuestions = [];
      if (currentQuestionInView === questions.length && isValid(currentQuestion)) {
        theQuestions = [...questions, currentQuestion];
      } else {
        theQuestions = [...questions];
      }
      await API.graphql(
        graphqlOperation(addQuestionSet, {
          input: {
            status: 'READY',
            name: title || 'Created ' + moment.default().format('MMM DD YYYY @ HH:mm'),
            questions: theQuestions,
          },
        })
      );
      history.push('/teacher/portal');
    } catch (err) {}
  };

  const nextQuestion = (goToQuestionIndexAfter?: number) => { 
    if (!isValid(currentQuestion)) {
      return;
    }

    if (currentQuestionInView === questions.length && !goToQuestionIndexAfter && goToQuestionIndexAfter !== 0)
    {
      setQuestions([...questions, currentQuestion]);
      setCurrentQuestionInView(currentQuestionInView + 1);
      setCurrentQuestion({
        time_limit: currentQuestion.time_limit,
        potential_points: 10,
        answer_type: currentQuestion.answer_type,
        question: '',
      });
      updateQuestionList();
    } else {
      let newQuestions = JSON.parse(JSON.stringify(questions));
      newQuestions[currentQuestionInView] = currentQuestion;
      setQuestions(newQuestions);
      setCurrentQuestionInView(goToQuestionIndexAfter || goToQuestionIndexAfter === 0 ? goToQuestionIndexAfter : questions.length);
      if (goToQuestionIndexAfter === 0 || goToQuestionIndexAfter) {
        setCurrentQuestion({
          time_limit: newQuestions[goToQuestionIndexAfter]?.time_limit,
          potential_points: 10,
          answer_type: newQuestions[goToQuestionIndexAfter]?.answer_type,
          question: newQuestions[goToQuestionIndexAfter]?.question,
          fake_answers: newQuestions[goToQuestionIndexAfter]?.fake_answers
        });
      }
    }
  };

  const updateCurrentQuestion = (event: any) => {
    setCurrentQuestion({
      ...currentQuestion,
      question: event,
    });

    setDisableSaveAndExit(
      !isValidQuestionCount() ||
        !isValid({
          ...currentQuestion,
          question: event,
        })
    );
    setDisableNextQuestion(
      !isValid({
        ...currentQuestion,
        question: event,
      })
    );
  };

  const updateCurrentFormat = (event: any) => {
    setCurrentQuestion({
      ...currentQuestion,
      answer_type: event.target.value,
    });

    setDisableSaveAndExit(
      !isValidQuestionCount() ||
        !isValid({
          ...currentQuestion,
          answer_type: event.target.value,
        })
    );
    setDisableNextQuestion(
      !isValid({
        ...currentQuestion,
        answer_type: event.target.value,
      })
    );
  };

  const updateCurrentTimeLimit = (seconds: number) => {
    setCurrentQuestion({
      ...currentQuestion,
      time_limit: seconds,
    });

    setDisableSaveAndExit(
      !isValidQuestionCount() ||
        !isValid({
          ...currentQuestion,
          time_limit: seconds,
        })
    );
    setDisableNextQuestion(
      !isValid({
        ...currentQuestion,
        time_limit: seconds,
      })
    );
  };

  const updateCurrentAnswerChoices = (event: any) => {
    const oldAnswers = currentQuestion.fake_answers;
    let fakeAnswers;

    if (event.target.name === 'answer1') {
      fakeAnswers = [event.target.value, oldAnswers?.[1], oldAnswers?.[2], oldAnswers?.[3]];
    } else if (event.target.name === 'answer2') {
      fakeAnswers = [oldAnswers?.[0], event.target.value, oldAnswers?.[2], oldAnswers?.[3]];
    } else if (event.target.name === 'answer3') {
      fakeAnswers = [oldAnswers?.[0], oldAnswers?.[1], event.target.value, oldAnswers?.[3]];
    } else if (event.target.name === 'answer4') {
      fakeAnswers = [oldAnswers?.[0], oldAnswers?.[1], oldAnswers?.[2], event.target.value];
    }

    setCurrentQuestion({
      ...currentQuestion,
      fake_answers: fakeAnswers,
    });

    setDisableSaveAndExit(
      !isValidQuestionCount() ||
        !isValid({
          ...currentQuestion,
          fake_answers: fakeAnswers,
        })
    );
    setDisableNextQuestion(
      !isValid({
        ...currentQuestion,
        fake_answers: fakeAnswers,
      })
    );
  };

  const createMarkup = (question: string) => {
    return {
      __html: question
    };
  }

  const updateQuestionList = () => {
    questionsSummarySection = questions.map((aQuestion, questionIndex) => {
      return (
          <div onClick={() => nextQuestion(questionIndex)}>
            <div
                className={'questionSummary' + (questionIndex === currentQuestionInView ? ' active' : '')}
                key={'questionSet' + questionIndex}>
              <span className="questionSummaryNumber activeQuest">Question {questionIndex +1}</span>
              <div className="padding">
                <div dangerouslySetInnerHTML={createMarkup(aQuestion.question)}></div>
                {/*<ReactMarkdown remarkPlugins={[gfm]} children={aQuestion.question} linkTarget={'_blank'}></ReactMarkdown>*/}
              </div>
            </div>
          </div>
      );
    });

    if (!questionsSummarySection) {
      questionsSummarySection = [];
    }

    if (questions.length - 1 < currentQuestionInView && !getParameterByName('editId')) {
      questionsSummarySection.push(
        <div className="questionSummary active" key={'questionSetNext'}>
          <span className="questionSummaryNumber">Question {currentQuestionInView + 1}</span>
          <div className="padding">
            &nbsp;
          </div>
        </div>
      );
    }

    questionsSummarySection.push(
      disableNextQuestion ? 
        <div className="questionSummary addNewQuestion disabledQuestion" key={'questionSetNext'}>
          <span className="questionSummaryNumber">Add New Question</span>
          <div className="padding">
              +
          </div>
        </div>: 
      <div onClick={() => nextQuestion(currentQuestionInView >= questions.length - 1 ? undefined : questions.length)}>
        <div className="questionSummary addNewQuestion" key={'questionSetNext'}>
          <span className="questionSummaryNumber">Add New Question</span>
          <div className="padding">
              +
          </div>
        </div>
      </div>
    );
  };

  const isValid = (questionToCheck: any) => {
    return !!(questionToCheck.question && questionToCheck.answer_type && questionToCheck.time_limit);
  };

  const isValidQuestionCount = () => {
    //A question count is valid if we have an even amount of questions that pass validation including the one that may not yet be added to the questions array
    const isEvenAmountOfQuestions = questions.length % 2 === 0;
    const isNewQuestionPending = currentQuestionInView === questions.length;
    const isNewQuestionPendingAndValid = currentQuestionInView === questions.length && isValid(currentQuestion);
    const isNewQuestionPendingAndInvalid = currentQuestionInView === questions.length && !isValid(currentQuestion);

    return !!questions.length && ((isEvenAmountOfQuestions && isNewQuestionPendingAndInvalid) || (!isEvenAmountOfQuestions && isNewQuestionPendingAndValid) || (isEvenAmountOfQuestions && !isNewQuestionPending));
  };

  const changeTitle = (event: any) => {
    setTitle(event.target.value);
  };

  const getChoicesSection = () => {
    return (
      <div key={'choices-section-' + currentQuestionInView} className="small-12 medium-7 cell moveItemUp">
        <h3 className="noPadding textWhite leftText">Choices</h3>
        <div className={'choicesSection'}>
          <input
            type="text"
            name="answer1"
            onChange={updateCurrentAnswerChoices}
            value={currentQuestion.fake_answers?.[0]}
            placeholder={'Add answer 1'}
          />
          <input
            type="text"
            name="answer2"
            onChange={updateCurrentAnswerChoices}
            value={currentQuestion.fake_answers?.[1]}
            placeholder={'Add answer 2'}
          />
          <input
            type="text"
            name="answer3"
            onChange={updateCurrentAnswerChoices}
            value={currentQuestion.fake_answers?.[2]}
            placeholder={'Add answer 3'}
          />
          <input
            type="text"
            name="answer4"
            onChange={updateCurrentAnswerChoices}
            value={currentQuestion.fake_answers?.[3]}
            placeholder={'Add answer 4'}
          />
        </div>
      </div>
    );
  };

  const updateQuestionBox = (startingText?: string) => {
    if (startingText) {
      setQuestionBox(
        <SmallQuestionsBox
          key={'small-q-box-preloaded-' + currentQuestionInView + '-startingText'}
          textChangedCallback={updateCurrentQuestion}
          questionIndex={currentQuestionInView}
          startingText={startingText}
        />
      );
    } else {
      setQuestionBox(
        <SmallQuestionsBox key={'small-q-box-' + currentQuestionInView} textChangedCallback={updateCurrentQuestion} questionIndex={currentQuestionInView} startingText={''} />
      );
    }
  };

  let questionsSummarySection;
  updateQuestionList();

  useEffect(() => {
    const fetchQuestionSet = async (editId: string) => {
      return API.graphql(
        graphqlOperation(getQuestionSet, {
          input: {
            object_id: editId,
          },
        })
      );
    };
    let editId = getParameterByName('editId');

    if (editId) {
      fetchQuestionSet(editId).then((questionSet: any) => {
        let questions: any = [];

        questionSet.data.get_Question_Set?.questions?.forEach((question: any) => {
          questions.push({
            question: question.question,
            time_limit: question.time_limit,
            answer_type: question.answer_type[0],
            fake_answers: question.fake_answers,
            potential_points: question.potential_points,
          });
        });

        if (questions) {
          setTitle(questionSet.data.get_Question_Set?.name);
          setCurrentQuestionInView(0);
          setCurrentQuestion(questions[0]);
          setQuestions(questions);
          updateQuestionList();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (questions && questions.length > currentQuestionInView && questions[currentQuestionInView].question) {
      updateQuestionBox(questions[currentQuestionInView].question);
    } else {
      updateQuestionBox()
    }
  }, [questions, currentQuestionInView])

  useEffect(() => {
    setDisableSaveAndExit(!isValidQuestionCount());
  }, [questions, currentQuestionInView, currentQuestion])

  return (
    <div className="teacherCreateQuestionSetWrapper">
      <div className="grid-x teacherBoxesBackground">
        <div className="small-12 medium-6 cell">
          <input
            type="text"
            placeholder="Type your game title here..."
            className="invisible-input"
            onChange={changeTitle}
            value={title}
          />
        </div>
        <div className="small-12 medium-2 medium-offset-2 cell rightText paddingRight">
          <div className="width-100">
            {!questionUpdated &&
                <Button
                    key={'next-button-' + currentQuestionInView}
                    text="Update Question"
                    type={ButtonTypeEnum.Primary}
                    area={ButtonAreaEnum.Teacher}
                    clickCallback={nextQuestion}
                    isDisabled={disableNextQuestion}
                />
            }
            {questionUpdated &&
                <Button
                    key={'next-button-' + currentQuestionInView}
                    text="Add New Question"
                    type={ButtonTypeEnum.Primary}
                    area={ButtonAreaEnum.Teacher}
                    clickCallback={nextQuestion}
                    isDisabled={disableNextQuestion}
                />
            }
          </div>

        </div>
        <div className="small-12 medium-2 cell rightText paddingRight">
          <div className="width-100">
          <Button
                key={'save-button-' + currentQuestionInView}
                text="Save and Exit"
                type={ButtonTypeEnum.Secondary}
                area={ButtonAreaEnum.Teacher}
                isDisabled={disableSaveAndExit}
                clickCallback={saveQuestionSet}
            />
          </div>
        </div>

      </div>

      <div className="grid-x grid-padding-x">
        <div className="small-12 medium-3 cell teacherBoxesBackground minFullHeight">
          {questionsSummarySection}
          {/* <Button
                key={'next-button-' + currentQuestionInView}
                text="Add Another Question"
                type={ButtonTypeEnum.Primary}
                area={ButtonAreaEnum.Teacher}
                clickCallback={nextQuestion}
                isDisabled={disableNextQuestion}
            /> */}
        </div>
        <div className="small-12 medium-9 cell paddingRight">
          {disableSaveAndExit ? (
            disableNextQuestion ? (
              <p className="evenQuestionsBar">Please ensure you complete all fields before saving your question or editing another.</p>
            ) : (
              <p className="evenQuestionsBar">
                You cannot save and exit on this question. Please ensure you have an even amount of questions for a fair
                game.
              </p>
            )
          ) : (
            ''
          )}
          <h2 className="textWhite marginBottom header-large">Question {currentQuestionInView + 1}</h2>
          {questionBox}
          <hr />
          {/*<h2 className="textWhite marginBottom centerText fontSize36">Answer</h2>*/}
          <div className="fullWidth inLine grid-x sectionBckQuestion ">
            <div className="small-7 medium-7 cell">
              {/*<h3 className="noPadding textWhite leftText fontSize26">Format:</h3>*/}
              <AnswerFormat optionChangedCallback={updateCurrentFormat} autoSelect={currentQuestion.answer_type} />
            </div>
            <div className="small-5 medium-5 cell time ">

              <h3 className="noPadding textWhite leftText">Time Limit</h3>
              <SetTime
                  minPlaceHolder="min"
                  secPlaceHolder="sec"
                  timeChangedCallback={updateCurrentTimeLimit}
                  defaultInSeconds={currentQuestion.time_limit}
                  key={currentQuestion.time_limit}
              />
            </div>
          </div>
          {/* <div className="grid-x">
            <div className="small-5 medium-5 small-offset-7 medium-offset-7 cell">
              <Button
                  key={'next-button-' + currentQuestionInView}
                  text="Next Question"
                  type={ButtonTypeEnum.Primary}
                  area={ButtonAreaEnum.Teacher}
                  clickCallback={nextQuestion}
                  isDisabled={disableNextQuestion}
              />
            </div>
          </div> */}
          <div className="grid-x grid-margin-x">
            {/*<div className="small-12 medium-5 cell">*/}
            {/*  <h3 className="noPadding textWhite leftText">Time Limit</h3>*/}
            {/*  <SetTime*/}
            {/*      minPlaceHolder="min"*/}
            {/*      secPlaceHolder="sec"*/}
            {/*      timeChangedCallback={updateCurrentTimeLimit}*/}
            {/*      defaultInSeconds={currentQuestion.time_limit}*/}
            {/*  />*/}
            {/*</div>*/}
            {currentQuestion.answer_type === 'MULTI' ? getChoicesSection() : <span></span>}
            <div
              className={
                'small-12 medium-4 cell' + (currentQuestion.answer_type === 'MULTI' ? ' medium-offset-4' : '')
              }>
            </div>
            {/*<div className="small-12 medium-4 cell rightText marginTopLarge">*/}
            {/*  <Button*/}
            {/*    key={'next-button-' + currentQuestionInView}*/}
            {/*    text="Next Question"*/}
            {/*    type={ButtonTypeEnum.Primary}*/}
            {/*    area={ButtonAreaEnum.Teacher}*/}
            {/*    clickCallback={nextQuestion}*/}
            {/*    isDisabled={disableNextQuestion}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCreateQuestionSetPage;
