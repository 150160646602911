import styles from './BeginGame.module.scss';
import React from 'react';
import TeamButtons, { TeamEnum } from '../../atoms/TeamButtons/TeamButtons';

function BeginGame() {
  document.body.classList.remove('teacherArea');
  return (
    <div className={`${styles.wrapper}`}>
      <h2 className={`${styles.large}`}>Let's Begin!</h2>
      <div className={`${styles.container}`}>
        <TeamButtons text={'team comet'} team={TeamEnum.Thunder}></TeamButtons>
        <h2 className={`${styles.verse}`}>VS</h2>
        <TeamButtons text={'team meteor'} team={TeamEnum.Lightning}></TeamButtons>
      </div>
    </div>
  );
}

export default BeginGame;
