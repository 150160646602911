import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import TeachersTeamsCreated from './TeachersTeamsCreated';
import TeachersQuestionView from './TeachersQuestionView';
import { useHistory } from 'react-router-dom';
import {
  teacherAllowSteal,
  teacherAwardPoints,
  teacherEndGame,
  teacherIncreaseRoundTimeLimit,
  teacherUpdateRound,
} from '../../redux/actions/teacherActions';
import TeacherGameStarting from './TeacherGameStarting';
import ZoinksterAnswer from '../StudentFlow/ZoinksterAnswer';
import AnnounceChooser from './AnnounceChooser';
import { TeamEnum } from '../../atoms/TeamGameEnd/TeamGameEnd';
import { Player, Team } from '../../redux/interfaces/storeInterface';
import { CorrectStatus } from '../../atoms/CorrectionBar/CorrectionBar';
import GameWinner from '../StudentFlow/GameWinner';
import IntroMusic from '../../atoms/IntroMusic/IntroMusic';
import { MemoizedPointsAward } from '../../molecules/PointsAward/PointsAward';

const TeacherPlayPage = (props: any) => {
  document.body.classList.remove('teacherArea');
  const history = useHistory();
  const dispatch = useDispatch();
  const [pointsAboutToAward, setPointsAboutToAward] = useState<{ numberOfPoints: number; team: string } | null>(null);
  const [lastAnswerStatus, setLastAnswerStatus] = useState<CorrectStatus | null>(null);

  const roundTimeUp = () => {
    teacherUpdateRound({
      model_id: props.TeacherStore.backendGameInfo.model_id,
      object_id: props.TeacherStore.backendGameInfo.object_id,
      round_id: props.TeacherStore.currentRound.object_id,
    })(dispatch);
  };

  const roundAddTime = (secondsToAdd: number) => {
    teacherIncreaseRoundTimeLimit({
      model_id: props.TeacherStore.backendGameInfo.model_id,
      round_id: props.TeacherStore.currentRound.object_id,
      time: secondsToAdd,
    })(dispatch);
  };

  const awardPoints = (answerStatus: CorrectStatus) => {
    let numberOfPoints = 0;
    let numberOfStealablePoints = 0;
    let pointsPerStudent: number;
    let zoinksterTeamId = '';
    let zoinksterTeamName = '';

    setLastAnswerStatus(answerStatus);

    props.TeacherStore.allPlayers?.forEach((player: Player) => {
      if (props.TeacherStore.currentRound.zoinkster.indexOf(player.object_id) === 0) {
        zoinksterTeamId = player.team;
      }
    });

    if (answerStatus === CorrectStatus.Correct || answerStatus === CorrectStatus.PartiallyCorrect) {
      if (answerStatus === CorrectStatus.Correct) {
        pointsPerStudent = 2;
      } else {
        pointsPerStudent = 1;
      }
      // Go through the team and add two points for each student that answered the question
      props.TeacherStore.teamStatus.forEach((team: Team) => {
        if (team.object_id === zoinksterTeamId) {
          zoinksterTeamName = team.name;
          team.players?.forEach((player) => {
            if (player.answer_status === 1) {
              numberOfPoints += pointsPerStudent;
            }
          });
        } else {
          team.players?.forEach((player) => {
            if (player.answer_status === 1) {
              numberOfStealablePoints += pointsPerStudent;
            }
          });
        }
      });
      //If we are in steal mode, take the points from the other team
      if (props.TeacherStore.stealInProgress === 'PARTIAL' && numberOfStealablePoints) {
        numberOfPoints = numberOfStealablePoints / 2;
      } else if (props.TeacherStore.stealInProgress && numberOfStealablePoints) {
        numberOfPoints = numberOfStealablePoints;
      }
      setPointsAboutToAward({
        numberOfPoints: numberOfPoints,
        team: zoinksterTeamName,
      });
    } else {
      numberOfPoints = 0;
      setPointsAboutToAward({
        numberOfPoints: 0,
        team: zoinksterTeamName,
      });
    }

    setTimeout(() => {
      //Hold to show points awarding then move on and award the points
      if (props.TeacherStore.stealInProgress && !numberOfPoints) {
        // If a steal has already happened this round then the stealer gets it wrong let's move on
        teacherUpdateRound({
          model_id: props.TeacherStore.backendGameInfo.model_id,
          object_id: props.TeacherStore.backendGameInfo.object_id,
          round_id: props.TeacherStore.currentRound.object_id,
        })(dispatch);

        if (
          props.TeacherStore.currentRound.object_id ===
          props.TeacherStore.backendGameInfo.rounds[props.TeacherStore.backendGameInfo.rounds.length - 1]
        ) {
          // If we are on the very last round, auto end the game without proceeding to lobby
          teacherEndGame({
            model_id: props.TeacherStore.backendGameInfo?.model_id,
            object_id: props.TeacherStore.backendGameInfo?.object_id,
          })(dispatch);
        }
      } else if (numberOfPoints) {
        // If points were awarded then award them and enable a steal for a partially correct answer (if we aren't already stealing)
        teacherAwardPoints({
          team_object_id: zoinksterTeamId,
          points: numberOfPoints,
          startASteal: answerStatus === CorrectStatus.PartiallyCorrect && !props.TeacherStore.stealInProgress,
          partiallyCorrect: answerStatus === CorrectStatus.PartiallyCorrect,
        })(dispatch);

        if (
          props.TeacherStore.currentRound.object_id ===
            props.TeacherStore.backendGameInfo.rounds[props.TeacherStore.backendGameInfo.rounds.length - 1] &&
          !(answerStatus === CorrectStatus.PartiallyCorrect && !props.TeacherStore.stealInProgress)
        ) {
          // If we are on the very last round and we are not starting a steal, auto end the game without proceeding to lobby
          teacherEndGame({
            model_id: props.TeacherStore.backendGameInfo?.model_id,
            object_id: props.TeacherStore.backendGameInfo?.object_id,
          })(dispatch);
        }
      } else {
        // If points were not awarded let the team steal
        //Set Choosing Team & update zoinkster
        teacherAllowSteal()(dispatch);
      }
    }, 3000);

    setTimeout(() => {
      setPointsAboutToAward(null);
    }, 5000);
  };

  const getWinningTeamAsEnum = () => {
    let pointsByTeam = {
      Thunder: 0,
      Lightning: 0,
    };
    props.TeacherStore.teamStatus?.forEach((team: Team) => {
      // @ts-ignore
      pointsByTeam[team.name] = team.awarded_points;
    });

    if (pointsByTeam.Thunder === pointsByTeam.Lightning) {
      return TeamEnum.Draw;
    } else {
      return pointsByTeam.Thunder > pointsByTeam.Lightning ? TeamEnum.Thunder : TeamEnum.Lightning;
    }
  };

  const getAmountOfPlayersAnsweredOnTeam = (teamName: TeamEnum) => {
    let amountOfPlayersAnswered = 0;
    let stringTeamName = '';
    if (teamName === TeamEnum.Thunder) {
      stringTeamName = 'Thunder';
    } else {
      stringTeamName = 'Lightning';
    }

    props.TeacherStore.teamStatus?.forEach((team: Team) => {
      if (team.name === stringTeamName) {
        amountOfPlayersAnswered = team.players?.filter((player) => player.answer_status === 1).length || 0;
      }
    });

    return amountOfPlayersAnswered;
  };

  if (props.TeacherStore?.backendGameInfo) {
    // If we have an active backend game
    if (props.TeacherStore.backendGameInfo.status === 'DONE') {
      // If the whole game is completed
      return (
        <div>
          <GameWinner winningTeam={getWinningTeamAsEnum()} gameCode={props.TeacherStore.backendGameInfo.object_id} />
          <IntroMusic soundEffect={'PLAYTIME'} isLoop={true} />
        </div>
      );
    } else if (props.TeacherStore.currentRound && props.TeacherStore.currentRound.status === 'STARTING') {
      // If a round is in starting mode, and needs to be kicked off by teacher
      return (
        <div>
          <TeachersTeamsCreated />;
        </div>
      );
    } else if (props.TeacherStore.currentRound && props.TeacherStore.currentRound.status === 'INPROGRESS') {
      // If a round is in progress
      return (
        <div>
          <TeachersQuestionView
            chooser={props.TeacherStore.backendGameInfo.chooser}
            question={props.TeacherStore.currentRound.question}
            timeAllowed={props.TeacherStore.currentRound.time_limit}
            startTimestamp={props.TeacherStore.currentRound.last_status_update}
            timeUpCallback={roundTimeUp}
            addTimeCallback={roundAddTime}
            thunderPlayersAnswered={getAmountOfPlayersAnsweredOnTeam(TeamEnum.Thunder)}
            lightningPlayersAnswered={getAmountOfPlayersAnsweredOnTeam(TeamEnum.Lightning)}
            questionNumber={
              props.TeacherStore.backendGameInfo.rounds.indexOf(
                props.TeacherStore.backendGameInfo.status.substring(
                  props.TeacherStore.backendGameInfo.status.indexOf(':') + 1
                )
              ) + 1
            }
            totalQuestions={props.TeacherStore.backendGameInfo.rounds.length}
            fakeAnswers={props.TeacherStore.currentRound.fake_answers}
          />
        </div>
      );
    } else if (props.TeacherStore.currentRound && props.TeacherStore.currentRound.status === 'FINISHING') {
      // If a round is finishing the zoinkster is being chosen or is chosen
      if (pointsAboutToAward) {
        return (
          <MemoizedPointsAward
            team={pointsAboutToAward.team}
            numberOfPoints={pointsAboutToAward.numberOfPoints}
            isCorrect={lastAnswerStatus === CorrectStatus.Correct}
          />
        );
      } else if (props.TeacherStore.currentRound.zoinkster && props.TeacherStore.currentRound.zoinkster_answer) {
        // If the zoinkster has been chosen and they have answered
        return (
          <div>
            <ZoinksterAnswer
              keyValue={props.TeacherStore.currentRound.object_id + '_' + props.TeacherStore.stealInProgress}
              question={props.TeacherStore.currentRound.question}
              answer={props.TeacherStore.currentRound.zoinkster_answer}
              answerExplanation={props.TeacherStore.currentRound.answer_explanation}
              isMultiChoice={
                props.TeacherStore.currentRound.fake_answers && props.TeacherStore.currentRound.fake_answers.length
              }
              answerType={props.TeacherStore.currentRound.answer_type}
              stealInProgress={props.TeacherStore.stealInProgress}
              answerStatusCallback={awardPoints}
              personAnswered={props.TeacherStore.currentRound?.zoinkster?.substring(
                props.TeacherStore.currentRound?.zoinkster.lastIndexOf(':') + 1
              )}
              personChoosing={props.TeacherStore.currentRound?.chooser?.substring(
                props.TeacherStore.currentRound?.chooser.lastIndexOf(':') + 1
              )}
            />
          </div>
        );
      } else if (props.TeacherStore.currentRound.zoinkster && !props.TeacherStore.currentRound.zoinkster_answer) {
        // If the zoinkster has been chosen and they have not answered
        return (
          <div>
            <ZoinksterAnswer
              keyValue={props.TeacherStore.currentRound.object_id + '_' + props.TeacherStore.stealInProgress}
              question={props.TeacherStore.currentRound.question}
              answer={"(Oops, they didn't answer the question!)"}
              answerExplanation={props.TeacherStore.currentRound.answer_explanation}
              answerType={props.TeacherStore.currentRound.answer_type}
              stealInProgress={props.TeacherStore.stealInProgress}
              answerStatusCallback={awardPoints}
              personAnswered={props.TeacherStore.currentRound?.zoinkster?.substring(
                props.TeacherStore.currentRound?.zoinkster.lastIndexOf(':') + 1
              )}
              personChoosing={props.TeacherStore.currentRound?.chooser?.substring(
                props.TeacherStore.currentRound?.chooser.lastIndexOf(':') + 1
              )}
            />
          </div>
        );
      } else {
        // If the zoinkster is being chosen
        return (
          <div>
            <AnnounceChooser />
          </div>
        );
      }
    } else if (props.TeacherStore.currentRound && props.TeacherStore.currentRound.status === 'DONE') {
      // If a round is done
      return (
        <div>
          <TeachersTeamsCreated />
        </div>
      );
    } else {
      return (
        <div>
          <TeacherGameStarting />
          <IntroMusic soundEffect={'GAMEPLAY'} isLoop={true} />
        </div>
      );
    }
  } else {
    //If there is no backend game info direct them to start a new game
    history.push('/teacher/portal');
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeacherPlayPage);
