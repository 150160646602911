import styles from './AnswerQuestionMulti.module.scss';
import { useEffect, useState } from 'react';
import Timer from '../../atoms/Timer/Timer';
import MultiChoiceButtons from '../../atoms/MultiChoiceButtons/MultiChoiceButtons';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';

function AnswerQuestionMulti(props: {
  startTimestamp: number;
  timeAllowed: number;
  submitAnswerCallback: (answer: string) => void;
  fakeAnswers: string[];
}) {
  const [text, setText] = useState<string>('');
  const [timerId, setTimerId] = useState<string>('');

  const updateTypedAnswer = (textValue: string) => {
    setText(textValue);
  };

  useEffect(() => {
    setTimerId(props.timeAllowed.toString() + '-' + props.startTimestamp.toString());
  }, [props.timeAllowed, props.startTimestamp]);

  return (
    <div>
      <div className={`${styles.wrapper}`}>
        <MultiChoiceButtons buttons={props.fakeAnswers} buttonSelectedCallback={updateTypedAnswer} />
        <div className={`${styles.wrap}`}>
          <div className={`${styles.grid}`}>
            <div className="grid-x">
              <div className="small-4 medium-4 cell">
                <div className={`${styles.timer}`}>
                  <Timer
                    key={timerId}
                    startTimestamp={props.startTimestamp}
                    timeAllowed={props.timeAllowed - 1}></Timer>
                </div>
              </div>
              <div className="small-7 medium-4 cell">
                <button className={`${styles.button}`} onClick={() => props.submitAnswerCallback(text)}>
                  I know this
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnswerQuestionMulti;
