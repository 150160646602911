/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGameUpdated = /* GraphQL */ `
  subscription OnGameUpdated($model_id: ID, $object_id: ID) {
    on_Game_Updated(model_id: $model_id, object_id: $object_id) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const onRoundUpdated = /* GraphQL */ `
  subscription OnRoundUpdated($model_id: ID) {
    on_Round_Updated(model_id: $model_id) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
      answer_explanation
    }
  }
`;
export const onTeamUpdated = /* GraphQL */ `
  subscription OnTeamUpdated($object_id: ID) {
    on_Team_Updated(object_id: $object_id) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
