import styles from './TeacherHeader.module.scss';
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import { teacherEndGame, teacherStartGame, teacherUpdateRound } from '../../redux/actions/teacherActions';

function TeacherHeader(props: any) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const startRound = async () => {
    if (
      props.TeacherStore.currentRound.status === 'DONE' &&
      props.TeacherStore.currentRound.object_id !==
        props.TeacherStore.backendGameInfo.rounds[props.TeacherStore.backendGameInfo.rounds.length - 1]
    ) {
      // Update the game status to move from a round to the next (exclude where we are on the last round)
      teacherStartGame({
        model_id: props.TeacherStore.backendGameInfo?.model_id,
        object_id: props.TeacherStore.backendGameInfo?.object_id,
        rounds: props.TeacherStore.backendGameInfo?.rounds,
      })(dispatch);
    } else if (
      props.TeacherStore.currentRound.status === 'DONE' &&
      props.TeacherStore.currentRound.object_id ===
        props.TeacherStore.backendGameInfo.rounds[props.TeacherStore.backendGameInfo.rounds.length - 1]
    ) {
      // Update the game status ONLY when we are on the very last round
      teacherEndGame({
        model_id: props.TeacherStore.backendGameInfo?.model_id,
        object_id: props.TeacherStore.backendGameInfo?.object_id,
      })(dispatch);
    } else {
      // Otherwise just update the round
      teacherUpdateRound({
        model_id: props.TeacherStore.backendGameInfo.model_id,
        object_id: props.TeacherStore.backendGameInfo.object_id,
        round_id: props.TeacherStore.currentRound.object_id,
      })(dispatch);
    }
  };

  const clickAction = () => {
    if (!loading) {
      setLoading(true);
      startRound();
    }
  };

  return (
    <div className={`${styles.zoinksHeaderWrapper} grid-x`}>
      <div className={`${styles.logoWrapper} small-12 medium-2 columns`}>
        {/*<ZoinkLogo></ZoinkLogo>*/}
        <h2 className={`${styles.gameCodeReference} textWhite`}>{props.TeacherStore.backendGameInfo.object_id}</h2>
        <h1 className={`${styles.gameCodeReferenceTitle}`}>play.zoinks.app</h1>
      </div>
      <div className={`${styles.teamNameWrapper} small-12 medium-2 medium-offset-8 columns`}>
        <button className={styles.launch} disabled={loading} onClick={() => clickAction()}>
          {loading ? (
            <div className="loaderContainer">
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          ) : (
            'Start Round'
          )}
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeacherHeader);
