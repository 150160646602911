import React from 'react';
import CorrectionLabel, { AnswerEnum } from '../../atoms/CorrectionLabel/CorrectionLabel';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
import IntroMusic from "../../atoms/IntroMusic/IntroMusic";

function AnswerStatus(props: { answerStatus: AnswerEnum; zoinksterName: string }) {
  const showAnswerStatus = () => {
    if (props.answerStatus === AnswerEnum.Correct) {
      return <div>
        {/*<IntroMusic soundEffect={'CORRECT'} isLoop={false}/>*/}
        <CorrectionLabel text={'Correct'} answer={AnswerEnum.Correct} />
      </div>;
    } else if (props.answerStatus === AnswerEnum.PartiallyCorrect) {
      return <div>
        {/*<IntroMusic soundEffect={'PARTIALCORRECT'} isLoop={false}/>*/}
        <CorrectionLabel text={'Partially Correct'} answer={AnswerEnum.PartiallyCorrect} />
        </div>;
    } else if (props.answerStatus === AnswerEnum.Incorrect) {
      return <div>
        {/*<IntroMusic soundEffect={'INCORRECT'} isLoop={false}/>*/}
        <CorrectionLabel text={'Incorrect'} answer={AnswerEnum.Incorrect} />
      </div>;
    }
  };

  return (
    <div>
      <StudentHeader />
      <div className="grid-container marginTop textWhite centerText">
        <div className="grid-x">
          <div className="cell marginTopSmall">
            <h1 className={'centerText textWhite'}>{props.zoinksterName} was...</h1>
          </div>
          <div className="cell marginTop zoinksLogoAnimate">{showAnswerStatus()}</div>
        </div>
      </div>
    </div>
  );
}

export default AnswerStatus;
