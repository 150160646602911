import styles from './AnswersSection.module.scss';
import StudentsAnswerSection from '../../atoms/StudentsAnswerSection/StudentsAnswerSection';
import ZoinksterAnswerSection from '../../atoms/ZoinksterAnswerSection/ZoinksterAnswerSection';
import AvatarQuestionHolding from '../../atoms/AvatarQuestionHolding/AvatarQuestionHolding';

function AnswersSection(props: {
  answered: boolean;
  myAnswer?: string;
  zoinksterAnswer?: string;
  zoinksterName?: string;
}) {
  return (
    <div className={`${styles.wrapper} paddingTop`}>
      <StudentsAnswerSection answered={props.answered} myAnswer={props.myAnswer} />
      {/*<ZoinksterAnswerSection zoinksterAnswer={props.zoinksterName ? props.zoinksterAnswer : '?'} zoinksterName={props.zoinksterName}/>*/}
    </div>
  );
}

export default AnswersSection;
