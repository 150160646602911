import { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import './pages/Page.scss';
import PlayPage from './pages/StudentFlow/PlayPage';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import TeacherPortalPage from './pages/TeacherFlow/TeacherPortalPage';
import TeacherCreateQuestionSetPage from './pages/TeacherFlow/TeacherCreateQuestionSetPage';
import TeacherStartGame from './pages/TeacherFlow/TeacherStartGame';
import StudentLoginPage from './pages/StudentFlow/StudentLoginPage';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import TeacherLaunchGame from './pages/TeacherFlow/TeacherLaunchGame';
import TeacherPlayPage from './pages/TeacherFlow/TeacherPlayPage';
import SignIn from './molecules/SignIn/Archive/SignIn';
import PurchaseManager from './pages/PurchaseManager';
import SoundManager from './pages/SoundManager';
import LogRocket from 'logrocket';
import TestPage from './pages/TestPage';
import Tutorial from './pages/Tutorial/Tutorial';
import TeacherLaunchPage from './pages/TeacherFlow/TeacherLaunchPage';

if (window.location.host.indexOf('localhost') === -1) {
  LogRocket.init('sxge5r/zoinks');
}

Amplify.configure(awsconfig);

function App() {
  const [musicRef, setMusicRef] = useState<string>('');

  useEffect(() => {
    // @ts-ignore
    const ourWindow: any = window;
    ourWindow.soundRef = ourWindow.soundRef ?? '';

    // setInterval(() => {
    //   if (ourWindow.soundRef !== musicRef) {
    //     setMusicRef(ourWindow.soundRef);
    //   }
    // }, 500);
  });

  return (
    <Provider store={configureStore()}>
      <Switch>
        <Route path="/teacher/launch" component={TeacherLaunchPage} />
        <Route path="/teacher/portal" component={TeacherPortalPage} />
        <Route path="/teacher/createQuestionSet" component={TeacherCreateQuestionSetPage} />
        <Route path="/teacher/launchGame" component={TeacherLaunchGame} />
        <Route path="/teacher/startGame" component={TeacherStartGame} />
        <Route path="/teacher/play" component={TeacherPlayPage} />
        <Route path="/teacher" component={SignIn} />
        <Route path="/tutorial" component={Tutorial} />
        <Route path="/token" component={SignIn} />
        <Route path="/login" component={StudentLoginPage} />
        <Route path="/play" component={PlayPage} />
        <Route path="/test" component={TestPage} />
        <Route path="/purchase" component={PurchaseManager} />
        <Route path="/" component={window.location.hostname.indexOf('play.') !== -1 ? PlayPage : SignIn} />
      </Switch>
      <SoundManager soundRef={musicRef} />
    </Provider>
  );
}

export default App;
