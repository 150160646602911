import styles from './ZoinkAnswer.module.scss';
import React from 'react';
import NoAnswerPage from '../../pages/TeacherFlow/NoAnswerPage';
import AnswerSelected from '../../atoms/AnswerSelected/AnswerSelected';
import IntroMusic from '../../atoms/IntroMusic/IntroMusic';

function ZoinkAnswer(props: {
  answer: string;
  answerType?: string;
  stealInProgress?: string | boolean;
  personAnswered?: string;
}) {
  const showQuestion = () => {
    if (props.answer === "(Oops, they didn't answer the question!)" && props.stealInProgress) {
      return (
        <div className={`${styles.noAnswer}`}>
          <NoAnswerPage
            headerText={"The Chooser didn't answer the question!"}
            footerText={'No points will be awarded this round'}
            inline={true}
          />
        </div>
      );
    } else if (props.answer === "(Oops, they didn't answer the question!)" && !props.stealInProgress) {
      return (
        <div className={`${styles.noAnswer}`}>
          <NoAnswerPage
            headerText={"The Zoinkster didn't answer the question!"}
            footerText={"Let's see if the Chooser can steal the points"}
            inline={true}
          />
          {/* <IntroMusic soundEffect={'INCORRECT'} isLoop={false} hideButton={true}/> */}
        </div>
      );
    } else {
      return (
        <div className={`${styles.question}`}>
          <AnswerSelected
            answer={props.answer}
            answerType={props.answerType}
            teacherView={true}
            personAnswered={props.personAnswered}
            stealInProgress={props.stealInProgress}
          />
          {/* <IntroMusic soundEffect={'GAMEPLAY'} isLoop={true} hideButton={true}/> */}
        </div>
      );
    }
  };

  return <div className={`${styles.wrapper}`}>{showQuestion()}</div>;
}

export default ZoinkAnswer;
