import styles from './AnswerQuestion.module.scss';
import React, { useEffect, useState } from 'react';
import AnswerBox from '../../atoms/AnswerBox/AnswerBox';
import Timer from '../../atoms/Timer/Timer';

import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
// import TeamMatesKnow from '../TeamMatesKnow/TeamMatesKnow';
function AnswerQuestion(props: {
  startTimestamp: number;
  timeAllowed: number;
  submitAnswerCallback: (answer: string) => void;
}) {
  const [text, setText] = useState<string>('');
  const [timerId, setTimerId] = useState<string>('');

  const updateTypedAnswer = (textValue: string) => {
    setText(textValue);
  };

  useEffect(() => {
    setTimerId(props.timeAllowed.toString() + '-' + props.startTimestamp.toString());
  }, [props.timeAllowed, props.startTimestamp]);

  return (
    <div>
      <div className={`${styles.wrapper}`}>
        <AnswerBox textChangeCallback={updateTypedAnswer} placeholderText={'Type your answer here'}></AnswerBox>
        <div className={`${styles.wrap}`}>
          <div className={`${styles.grid}`}>
            <div className="grid-x">
              <div className="small-4 medium-4 cell">
                <div className={`${styles.timer}`}>
                  <Timer key={timerId} startTimestamp={props.startTimestamp} timeAllowed={props.timeAllowed}></Timer>
                </div>
              </div>
              <div className="small-7 medium-4 cell">
                <button className={`${styles.button}`} onClick={() => props.submitAnswerCallback(text)}>
                  I know this
                </button>
              </div>
              <div className="small-12 medium-4 cell"></div>

              {/*<div className="small-12 medium-3 cell">*/}
              {/*  <TeamMatesKnow></TeamMatesKnow>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnswerQuestion;
