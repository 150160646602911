import styles from './AnswerBox.module.scss';
import React, { useState } from 'react';

const AnswerBox = (props: { placeholderText?: string; textChangeCallback?: (textValue: string) => void }) => {
  const [text, setText] = useState<string>('');

  const handleInputChange = (event: any) => {
    setText(event.target.value);
    if (props.textChangeCallback) {
      props.textChangeCallback(event.target.value);
    }
  };

  return (
    <textarea
      name={'text'}
      className={styles.inputField}
      placeholder={props.placeholderText}
      onChange={handleInputChange}
      value={text}
    />
  );
};

export default AnswerBox;
