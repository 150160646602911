import styles from './AnswerQuestionDraw.module.scss';
import React, { useEffect, useState } from 'react';
import AnswerBox from '../../atoms/AnswerBox/AnswerBox';
import Timer from '../../atoms/Timer/Timer';

import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
import Canvas from '../../atoms/Canvas/Canvas';
// import TeamMatesKnow from '../TeamMatesKnow/TeamMatesKnow';
function AnswerQuestion(props: {
  startTimestamp: number;
  timeAllowed: number;
  submitAnswerCallback: (answer: string) => void;
}) {
  const [drawing, setDrawing] = useState<string>('');
  const [timerId, setTimerId] = useState<string>('');
  const [drawnImage, setDrawnImage] = useState('');

  const handleImageSave = (imageData: React.SetStateAction<string>) => {
    setDrawnImage(imageData);
    // Perform any additional actions with the image data
    // such as saving it to the state or sending it to the server
  };
  const updateDrawing = (textValue: string) => {
    setDrawing(textValue);
  };

  useEffect(() => {
    setTimerId(props.timeAllowed.toString() + '-' + props.startTimestamp.toString());
  }, [props.timeAllowed, props.startTimestamp]);

  return (
    <div>
      <div className={`${styles.wrapper}`}>
        <div>
          <div>
            <Canvas onImageSave={handleImageSave} />
          </div>
        </div>
        {/* <AnswerBox textChangeCallback={updateTypedAnswer} placeholderText={'Type your answer here'}></AnswerBox> */}
        <div className={`${styles.wrap}`}>
          <div className={`${styles.grid}`}>
            <div className="grid-x">
              <div className="small-4 medium-4 cell">
                <div className={`${styles.timer}`}>
                  <Timer key={timerId} startTimestamp={props.startTimestamp} timeAllowed={props.timeAllowed}></Timer>
                </div>
              </div>
              <div className="small-7 medium-4 cell">
                <button className={`${styles.button}`} onClick={() => props.submitAnswerCallback(drawnImage)}>
                  I know this
                </button>
              </div>
              <div className="small-12 medium-4 cell"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnswerQuestion;
