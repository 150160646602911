import styles from './TeacherStatusHeader.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import { Player, Team } from '../../redux/interfaces/storeInterface';

const calculatePointsEarnable = (props: any) => {
  let numberOfPoints = 0;
  let pointsPerStudent: number;
  let zoinksterTeamId = '';
  props.TeacherStore.allPlayers?.forEach((player: Player) => {
    if (props.TeacherStore.currentRound?.chooser?.indexOf(player.object_id) !== 0) {
      zoinksterTeamId = player.team;
    }
  });

  if (zoinksterTeamId) {
    pointsPerStudent = 2;
    // Go through the team and add two points for each student that answered the question
    props.TeacherStore.teamStatus?.forEach((team: Team) => {
      if (team.object_id === zoinksterTeamId) {
        team.players?.forEach((player) => {
          if (player.answer_status === 1) {
            numberOfPoints += pointsPerStudent;
          }
        });
      }
    });
  }

  return numberOfPoints;
};

function getZoinksterTeamName(props: any) {
  let zoinksterTeam = '';

  props.TeacherStore.allPlayers?.forEach((player: any) => {
    if (
      player.object_id ===
      props.TeacherStore.currentRound?.chooser.substring(0, props.TeacherStore.currentRound?.chooser.lastIndexOf(':'))
    ) {
      props.TeacherStore.backendGameInfo?.teams.forEach((team: any) => {
        if (team.indexOf(player.team) === -1) {
          zoinksterTeam = team.substring(team.lastIndexOf(':') + 1);
        }
      });
    }
  });

  return zoinksterTeam === 'Lightning' ? 'Meteor' : 'Comet';
}

function TeacherStatusHeader(props: any) {
  return (
    <div className={`${styles.zoinksHeaderWrapper} grid-x`}>
      <div className={`small-12 medium-12 columns`}>
        <h2 className={`${styles.textWhite}`}>
          {getZoinksterTeamName(props)} has the chance to earn{' '}
          <span className={styles.points}>+{calculatePointsEarnable(props)} points</span>
        </h2>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeacherStatusHeader);
