function StudentReducer(state = {}, action: { type: string; payload: any }) {
  if (action.type === 'STUDENT_JOIN_GAME') {
    return {
      ...action.payload,
    };
  } else if (action.type === 'STUDENT_ADDED_TO_GAME') {
    return {
      ...action.payload,
      ...state,
      myPlayerId: action.payload.backendGameInfo.newest_player,
    };
  } else if (action.type === 'STUDENT_ADDED_TO_GAME_FAILED') {
    //Clear the state so we ask again
    return {
      error: "Sorry, we couldn't find the game you requested. Please try again",
    };
  } else if (action.type === 'STUDENT_KICKED') {
    let newState = { kicked: true };

    return newState;
  } else if (action.type.indexOf('STUDENT_') !== -1) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return state;
  }
}

export default StudentReducer;
