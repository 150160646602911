import styles from './PickZoinkster.module.scss';
import React, { useEffect, useState } from 'react';
import AvatarLongLine from '../AvatarLongLine/AvatarLongLine';
import AvatarChooser from '../../atoms/AvatarChooser/AvatarChooser';
import { connect, useDispatch } from 'react-redux';

function PickZoinkster(props: any) {
  const [isShowcaseMode, setIsShowcaseMode] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsShowcaseMode(false);
    }, 2000);
  }, []);

  const getContent = () => {
    if (isShowcaseMode) {
      return (
        <div>
          {props.StudentStore.currentRound.chooser.substring(
            0,
            props.StudentStore.currentRound.chooser.lastIndexOf(':')
          ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) && (
            <div className={`${styles.wrapper} paddingTop`}>
              <h2 className="fontSize36 lineHeight36">You are the Chooser!</h2>
              <div className="marginTopLarge">
                <AvatarChooser studentScreen={true} />
              </div>
              <h3 className="fontSize26">Quick! Pick a Zoinkster!</h3>
            </div>
          )}
          {!(
            props.StudentStore.currentRound.chooser.substring(
              0,
              props.StudentStore.currentRound.chooser.lastIndexOf(':')
            ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':'))
          ) && (
            <div className={`${styles.wrapper} paddingTop`}>
              <h2 className="fontSize36 lineHeight36">Who will be chosen?</h2>
              <div className="marginTopLarge">
                <AvatarChooser studentScreen={false} />
              </div>
              {/*<h3 className="fontSize26">!</h3>*/}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={`${styles.wrapper}`}>
          {!(
            props.StudentStore.currentRound.chooser.substring(
              0,
              props.StudentStore.currentRound.chooser.lastIndexOf(':')
            ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':'))
          ) && <h2 className="fontSize36 paddingTop">The Zoinkster is being chosen</h2>}

          {props.StudentStore.currentRound.chooser.substring(
            0,
            props.StudentStore.currentRound.chooser.lastIndexOf(':')
          ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) && (
            <h2 className="fontSize36 paddingTop">Select the Zoinkster</h2>
          )}
          {props.StudentStore.currentRound.chooser.substring(
            0,
            props.StudentStore.currentRound.chooser.lastIndexOf(':')
          ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) && (
            <div className={`${styles.students}`}>
              <AvatarLongLine></AvatarLongLine>
            </div>
          )}
        </div>
      );
    }
  };

  return getContent();
}
const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(PickZoinkster);
