import { useEffect, useState } from 'react';
import Button, { ButtonAreaEnum, ButtonTypeEnum } from '../../atoms/Button/Button';
import Timer from '../../atoms/Timer/Timer';
import QuestionView from '../../molecules/QuestionView/QuestionView';
import Slider from '../../atoms/Slider/Slider';

const TeachersQuestionView = (props: {
  chooser: string;
  question: string;
  startTimestamp: number;
  timeAllowed: number;
  thunderPlayersAnswered: number;
  lightningPlayersAnswered: number;
  timeUpCallback?: () => void;
  addTimeCallback?: (secondsToAdd: number) => void;
  questionNumber?: number;
  totalQuestions?: number;
  fakeAnswers?: string[];
}) => {
  document.body.classList.remove('teacherArea');
  const [timeAllowed, setTimeAllowed] = useState<number>(0);
  const [timerReference, setTimerReference] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const forceCallback = () => {
    if (props.timeUpCallback) {
      props.timeUpCallback();
    }
  };

  const addTime = () => {
    if (props.addTimeCallback) {
      //Update the backend if we can
      props.addTimeCallback(10);
    } else {
      //Fake it
      setTimeAllowed(timeAllowed + 10);
      setTimerReference(timerReference + 1);
    }
  };

  const getNewTimer = () => {
    if (timeAllowed) {
      return (
        <div>
          <Timer
            key={timerReference}
            startTimestamp={props.startTimestamp}
            timeAllowed={timeAllowed}
            timeUpCallback={props.timeUpCallback}
            setTimeLeft={setTimeLeft}
          />
        </div>
      );
    }

    return '';
  };

  const getGameId = () => {
    let sessionData = sessionStorage.getItem('currentGameDetails');

    if (!sessionData) {
      sessionData = '{}';
    }
    return <span>{JSON.parse(sessionData)?.object_id}</span>;
  };

  useEffect(() => {
    setTimeAllowed(props.timeAllowed);
  }, [props.timeAllowed]);

  return (
    <div className="container">
      <div className="fullWidth marginTopSmall">
        <div className="grid-x">
          <div className="small-12 medium-4 cell">
            <div className="textWhite gameId">
              <p>Game Code: {getGameId()}</p>
            </div>
          </div>
          <div className="small-12 medium-5 medium-offset-3 cell rightText rightPadding">
            <div className="rightText paddingRightLarge">
              <h2 className="textWhite headerMedium rightText">
                Question {props.questionNumber} of {props.totalQuestions}
              </h2>
            </div>
            <div className="displayInlineBlock paddingRight">
              <Button
                disableLoader={true}
                text="Add 10 Seconds"
                area={ButtonAreaEnum.Teacher}
                type={ButtonTypeEnum.Primary}
                clickCallback={addTime}
              />
            </div>
            <div className="displayInlineBlock">
              <Button
                text="End Timer"
                area={ButtonAreaEnum.Teacher}
                type={ButtonTypeEnum.Secondary}
                clickCallback={forceCallback}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth">
        <div className="grid-x">
          <Slider chooser={props.chooser}></Slider>
          <QuestionView
            question={props.question}
            thunderPlayersAnswered={props.thunderPlayersAnswered}
            lightningPlayersAnswered={props.lightningPlayersAnswered}
            fakeAnswers={props.fakeAnswers}
            timeLimit={props.timeAllowed}
            timeLeft={timeLeft}></QuestionView>
        </div>
        <div className="small-12 medium-3 cell">{getNewTimer()}</div>
      </div>
    </div>
  );
};

export default TeachersQuestionView;
