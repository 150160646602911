import React, { useEffect, useState } from 'react';
import wowza from '../../images/wowzaLogoBlue.svg';
import Button, { ButtonAreaEnum, ButtonTypeEnum } from '../../atoms/Button/Button';
import SideBanner from '../../atoms/SideBanner/SideBanner';
import CreateQuestions from '../../atoms/CreateQuestions/CreateQuestions';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getAllQuestionSets } from '../../graphql/queries';
import QuestionSets from '../../atoms/QuestionSets/QuestionSets';
import { teacherCreateGame, teacherDeleteGame, teacherEditGame } from '../../redux/actions/teacherActions';
import { useDispatch } from 'react-redux';
import image from "../../images/question.png";

const TeacherPortalPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  document.body.classList.add('teacherArea');

  const [questionSets, setQuestionSets] = useState<any[]>([]);
  const [hasFinishedFetching, setHasFinishedFetching] = useState<boolean>(false);

  const toTutorials = () => {
    window.location.href = '/tutorial'
  }

  const createNewQuestionSet = (editId?: string) => {
    let historyPath = '/teacher/createQuestionSet';

    if (editId) {
      historyPath += '?editId=' + editId;
    }

    history.push(historyPath);
  };

  const fetchQuestionSets = async () => {
    return API.graphql(graphqlOperation(getAllQuestionSets));
  };

  const launchGame = async (questionSet: { object_id: string }) => {
    // @ts-ignore
    window.soundRef = 'intro';
    teacherCreateGame(questionSet.object_id)(dispatch);
    history.push('/teacher/startGame');
  };

  const deleteGame = async (questionSet: { object_id: string }) => {
    teacherDeleteGame(questionSet.object_id)(dispatch);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

   const editGame = async (questionSet: { object_id: string }) => {
     // @ts-ignore
    teacherEditGame(questionSet.object_id)(dispatch);
    history.push('/teacher/createQuestionSet?editId=' + questionSet.object_id);
  };

  useEffect(() => {
    fetchQuestionSets().then((questionSets: any) => {
      setQuestionSets(questionSets.data.get_All_QuestionSets);
      setHasFinishedFetching(true);
    });
  }, []);

  let questionSetArea;
  if (questionSets.length && hasFinishedFetching) {
    questionSetArea = questionSets.map((questionSet) => {
      return (
        <div className="marginBottom" key={questionSet.object_id}>
          <QuestionSets
            questionSet={questionSet}
            launchGameCallback={launchGame}
            deleteGameCallback={deleteGame}
            editGameCallback={editGame}
          />
        </div>
      );
    });
  } else if (hasFinishedFetching) {
    questionSetArea = (
      <div className="marginTopLarge">
        <CreateQuestions clickCallback={createNewQuestionSet} />
      </div>
    );
  } else {
    questionSetArea = <div></div>;
  }

  return (
    <div>
      <div className={'help fixed'}>
          <button className={'helpButton'} onClick={toTutorials}>
              <span>Tutorial</span> <img src={image} className={'helpIcon'} alt="helpIcon"/>
          </button>
      </div>
      <div className="grid-container">
        <div className="grid-x">
          <div className="small-12 medium-2 cell marginTopSmall">
            <img src={wowza} alt={'wowza logo'} className="standardLogo" />
            {/*<ZoinkLogo />*/}
          </div>
          <div className={`small-12 medium-6 medium-offset-2 cell marginTopSmall rightText`}>
            <Button
              text="Create New Set"
              type={ButtonTypeEnum.Primary}
              area={ButtonAreaEnum.Teacher}
              clickCallback={createNewQuestionSet}
            />
          </div>
        </div>

        <div className="grid-x">
          <div className="small-12 medium-4 cell marginTopSmall">
            <SideBanner teacherName={'Teacher'} />
          </div>
          <div className="small-12 medium-8 cell">
            <h2 className="textWhite marginBottom noPaddingTop fontSize26 marginTop">Question Sets</h2>
            {questionSetArea}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherPortalPage;
