import styles from './SetTime.module.scss';
import React, { useEffect, useState } from 'react';

const SetTime = (props: {
  minPlaceHolder?: string;
  secPlaceHolder?: string;
  timeChangedCallback: any;
  defaultInSeconds?: number;
}) => {
  const [minutes, setMinutes] = useState<string>('0 ' + props.minPlaceHolder);
  const [seconds, setSeconds] = useState<string>('0 ' + props.secPlaceHolder);

  const updateMinutes = (event: any) => {
    let newValue = event.target.value.match(/\d+/g)?.join('');

    if (newValue !== minutes.match(/\d+/g)?.join('')) {
      setMinutes(parseFloat(newValue) + ' ' + props.minPlaceHolder);
      props.timeChangedCallback(parseFloat(newValue) * 60 + parseFloat(seconds));
    } else if (newValue) {
      setMinutes(newValue.substring(0, newValue.length - 2) + ' ' + props.minPlaceHolder);
    }
  };

  const updateSeconds = (event: any) => {
    let newValue = event.target.value.match(/\d+/g)?.join('');

    if (newValue !== seconds.match(/\d+/g)?.join('')) {
      setSeconds(parseFloat(newValue) + ' ' + props.secPlaceHolder);
      props.timeChangedCallback(parseFloat(minutes) * 60 + parseFloat(newValue));
    } else if (newValue) {
      setSeconds(newValue.substring(0, newValue.length - 2) + ' ' + props.secPlaceHolder);
    }
  };

  useEffect(() => {
    if (props.defaultInSeconds) {
      updateMinutes({
        target: {
          value: Math.floor(props.defaultInSeconds / 60).toString(),
        },
      });

      if (props.defaultInSeconds % 60) {
        updateSeconds({
          target: {
            value: (props.defaultInSeconds % 60).toString(),
          },
        });
      }
    }
  }, []);

  return (
    <div className={styles.body}>
      <input type="text" name="minutes" className={styles.inputField} onChange={updateMinutes} value={minutes} />
      <input type="text" name="seconds" className={styles.inputFieldSec} onChange={updateSeconds} value={seconds} />
    </div>
  );
};

export default SetTime;
