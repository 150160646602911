import QuestionBox from '../../atoms/QuestionBox/QuestionBox';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
import AnswerQuestion from '../../molecules/AnswerQuestion/AnswerQuestion';
import AnswerQuestionDraw from '../../molecules/AnswerQuestionDraw/AnswerQuestionDraw';
import AnswerQuestionMulti from '../../molecules/AnswerQuestionMulti/AnswerQuestionMulti';

function StudentAnswerQuestion(props: {
  question: string;
  answerType: string;
  startTimestamp: number;
  timeAllowed: number;
  fakeAnswers?: string[];
  submitAnswerCallback: (answer: string) => void;
}) {
  return (
    <div>
      <StudentHeader />
      <QuestionBox question={props.question}></QuestionBox>
      <div className="grid-container">
        {props.fakeAnswers && props.fakeAnswers.length ? (
          <div>
            <AnswerQuestionMulti
              startTimestamp={props.startTimestamp}
              timeAllowed={props.timeAllowed}
              submitAnswerCallback={props.submitAnswerCallback}
              fakeAnswers={props.fakeAnswers}></AnswerQuestionMulti>
          </div>
        ) : props.answerType[0] === 'DRAW' ? (
          <AnswerQuestionDraw
            startTimestamp={props.startTimestamp}
            timeAllowed={props.timeAllowed}
            submitAnswerCallback={props.submitAnswerCallback}></AnswerQuestionDraw>
        ) : (
          <AnswerQuestion
            startTimestamp={props.startTimestamp}
            timeAllowed={props.timeAllowed}
            submitAnswerCallback={props.submitAnswerCallback}></AnswerQuestion>
        )}
      </div>
    </div>
  );
}

export default StudentAnswerQuestion;
