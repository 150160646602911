import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  closeWebsockets,
  studentAddedToGame,
  studentGameUpdated,
  studentJoinGame,
  studentRoundUpdated,
  studentUpdateDetails,
  studentUpdateRoundAnswer,
} from '../../redux/actions/studentActions';
import AvatarEnterCode from '../../molecules/AvatarEnterCode/AvatarEnterCode';
import AvatarEnterName from '../../molecules/AvatarEnterName/AvatarEnterName';
import StudentPickAvatar from './StudentPickAvatar';
import StudentGetReady from '../../molecules/StudentGetReady/StudentGetReady';
import StudentAnswerQuestion from './StudentAnswerQuestion';
import NoAnswerPage from '../TeacherFlow/NoAnswerPage';
import AnswerSelected from '../../atoms/AnswerSelected/AnswerSelected';
import ZoinkSelect from './ZoinkSelect';
import AnswerStatus from './AnswerStatus';
import { AnswerEnum } from '../../atoms/CorrectionLabel/CorrectionLabel';
import GameWinner from './GameWinner';
import { TeamEnum } from '../../atoms/TeamGameEnd/TeamGameEnd';
import { Player, StudentStore } from '../../redux/interfaces/storeInterface';
import StealInProgress from './StealInProgress';
import { API, graphqlOperation } from 'aws-amplify';
import { getGame, getRound } from '../../graphql/queries';
import * as Sentry from '@sentry/react';
// import LogRocket from 'logrocket';
import axios from 'axios';
import AnswersSection from '../../molecules/AnswersSection/AnswersSection';
import StudentsAnswerSection from '../../atoms/StudentsAnswerSection/StudentsAnswerSection';
import ZoinksterSelected from '../../atoms/ZoinksterSelected/ZoinksterSelected';
import Feedback from '../../atoms/Feedback/Feedback';

// Timeout for GetGame and GetRound, reduced to allow for retries
axios.interceptors.request.use(
  (config) => {
    if (config.data.indexOf('GetRound') !== -1 || config.data.indexOf('GetGame') !== -1) {
      config.timeout = 5000;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const PlayPage = (props: any) => {
  const dispatch = useDispatch();
  document.body.classList.remove('teacherArea');

  const studentHadEnteredJoinCode = (gameCode: string) => {
    studentJoinGame(gameCode)(dispatch);
    // eg. LogRocket.identify('123456', {
    //   name: 'Jane Smith',
    //   email: 'janesmith@logrocket.com',
    //   age: 43,
    //   favoriteColor: 'blue',
    //   currentSubscription: 'professional-plan',
    // });
  };

  const studentHadEnteredName = (firstName: string, lastInitial: string) => {
    studentUpdateDetails(firstName, lastInitial)(dispatch);
  };

  const submitStudentAnswer = (answer: string) => {
    if (!answer) {
      answer = ' ';
    }
    studentUpdateRoundAnswer(answer)(dispatch);
  };

  const getZoinkstersTeamId = () => {
    let theTeamId = '';
    props.StudentStore.fullTeamInfo?.forEach((team: StudentStore['fullTeamInfo']) => {
      team.players.forEach((player: Player) => {
        if (player.player_object_id === props.StudentStore.currentRound.zoinkster) {
          theTeamId = team.object_id;
        }
      });
    });

    return theTeamId;
  };

  const getZoinkstersTeamEnum = () => {
    let theTeamName = '';
    props.StudentStore.fullTeamInfo?.forEach((team: StudentStore['fullTeamInfo']) => {
      team.players.forEach((player: Player) => {
        if (player.player_object_id === props.StudentStore.currentRound.zoinkster) {
          theTeamName = team.name;
        }
      });
    });

    return theTeamName === 'Thunder' ? TeamEnum.Thunder : TeamEnum.Lightning;
  };

  const getWinningTeamEnum = () => {
    let thunderPoints = 0;
    let lightningPoints = 0;
    props.StudentStore.fullTeamInfo?.forEach((team: StudentStore['fullTeamInfo']) => {
      if (team.name === 'Thunder' && team.awarded_points) {
        thunderPoints = team.awarded_points;
      } else if (team.awarded_points) {
        lightningPoints = team.awarded_points;
      }
    });

    if (thunderPoints !== lightningPoints) {
      return thunderPoints > lightningPoints ? TeamEnum.Thunder : TeamEnum.Lightning;
    } else {
      return TeamEnum.Draw;
    }
  };

  const getAnswerStatus = () => {
    let theTeamId: string;

    // Set the zoinksters team id
    theTeamId = getZoinkstersTeamId();

    // Check if my team has been awarded the points
    if (
      props.StudentStore.currentRound.awarded_points.find(
        (awardedPoints: any) => awardedPoints.team_object_id === theTeamId
      )?.full_points
    ) {
      return AnswerEnum.Correct;
    } else if (
      props.StudentStore.currentRound.awarded_points.find(
        (awardedPoints: any) => awardedPoints.team_object_id === theTeamId
      )?.points
    ) {
      return AnswerEnum.PartiallyCorrect;
    } else {
      // Incorrect
      return AnswerEnum.Incorrect;
    }
  };

  const restoreGame = async (currentGameCode: string) => {
    return API.graphql(
      graphqlOperation(getGame, {
        input: {
          object_id: currentGameCode,
        },
      })
    );
  };

  const restoreRound = async (currentRoundObjectId: string) => {
    return API.graphql(
      graphqlOperation(getRound, {
        input: {
          object_id: currentRoundObjectId,
        },
      })
    );
  };

  useEffect(() => {
    const restoreGameFromSession = () => {
      const retryCount = sessionStorage.getItem('retryConnectionCount') ?? '0';
      const currentGameCode = sessionStorage.getItem('gameCode');
      const myPlayerId = sessionStorage.getItem('myPlayerId');

      if (currentGameCode && myPlayerId) {
        sessionStorage.setItem('retryConnectionCount', (parseFloat(retryCount) + 1).toString());

        restoreGame(currentGameCode).then(
          (currentGame: any) => {
            // if (currentGame.data?.get_Game?.status !== 'DONE') {
            currentGame.data.get_Game.newest_player = myPlayerId;
            closeWebsockets()(dispatch);
            studentAddedToGame(currentGame.data.get_Game)(dispatch);
            studentGameUpdated(currentGame.data.get_Game)(dispatch);

            // If there is a round in progress get the round
            if (
              currentGame.data.get_Game.status.substring(currentGame.data.get_Game.status.indexOf(':') + 1) !== 'READY'
            ) {
              restoreRound(
                currentGame.data.get_Game.status.substring(currentGame.data.get_Game.status.indexOf(':') + 1)
              ).then(
                (currentRound: any) => {
                  if (currentRound.data?.get_Round?.status) {
                    studentRoundUpdated(currentRound.data.get_Round)(dispatch);
                  } else {
                    throw new Error(
                      'Round data not present - for game code:' +
                        currentGameCode +
                        ' for player:' +
                        myPlayerId +
                        ' data: ' +
                        currentRound
                    );
                  }
                },
                (error) => {
                  // Retry connection up to 10 times
                  if (parseFloat(retryCount) < 10) {
                    console.log('would attempt retry for round');
                    // Sentry.captureMessage(
                    //   'Retry GetRound (' +
                    //     retryCount +
                    //     ') - for game code:' +
                    //     currentGameCode +
                    //     ' for player:' +
                    //     myPlayerId +
                    //     ' error: ' +
                    //     error
                    // );
                    // restoreGameFromSession();
                  } else {
                    console.log('would not attempt retry for round');
                    // sessionStorage.removeItem('gameCode');
                    // sessionStorage.removeItem('myPlayerId');

                    // Sentry.captureException(
                    //   'Retry 10 failed (last fail get round) - for game code:' +
                    //     currentGameCode +
                    //     ' for player:' +
                    //     myPlayerId +
                    //     ' error: ' +
                    //     error
                    // );
                    //
                    // window.location.reload();
                  }

                  // try {
                  //   throw new Error(
                  //     'GetRound Failure: ' +
                  //       currentGameCode +
                  //       ' for round: ' +
                  //       currentGame.data.get_Game.status.substring(currentGame.data.get_Game.status.indexOf(':') + 1) +
                  //       ' for player:' +
                  //       myPlayerId +
                  //       ' - stringify error: ' +
                  //       JSON.stringify(error)
                  //   );
                  // } catch (e) {
                  //   throw new Error(
                  //     'GetRound Failure: ' +
                  //       currentGameCode +
                  //       ' for round: ' +
                  //       currentGame.data.get_Game.status.substring(currentGame.data.get_Game.status.indexOf(':') + 1) +
                  //       ' for player:' +
                  //       myPlayerId +
                  //       ' - non-stringify error: ' +
                  //       error +
                  //       e
                  //   );
                  // }
                }
              );
            }
            // } else {
            //   sessionStorage.removeItem('gameCode');
            //   sessionStorage.removeItem('myPlayerId');
            //   throw new Error(
            //     'Current Game Status DONE - closing game: ' + currentGameCode + ' for player:' + myPlayerId
            //   );
            // }
          },
          (error) => {
            // Retry connection up to 10 times
            if (parseFloat(retryCount) < 10) {
              console.log('would attempt retry for game');
              // Sentry.captureMessage(
              //   'Retry GetGame (' +
              //     retryCount +
              //     ') - for game code:' +
              //     currentGameCode +
              //     ' for player:' +
              //     myPlayerId +
              //     ' error: ' +
              //     error
              // );
              // restoreGameFromSession();
            } else {
              // sessionStorage.removeItem('gameCode');
              // sessionStorage.removeItem('myPlayerId');
              console.log('would not attempt retry for game');
              // Sentry.captureException(
              //   'Retry 10 failed (last fail get game) - for game code:' +
              //     currentGameCode +
              //     ' for player:' +
              //     myPlayerId +
              //     ' error: ' +
              //     error
              // );

              // window.location.reload();
            }
          }
        );
      }
    };

    restoreGameFromSession();
    //If the window loses focus and comes back again let's reconnect

    // Set the name of the hidden property and the change event for visibility
    // let hidden;
    // let visibilityChange: any;
    // if (typeof document.hidden !== 'undefined') {
    //   // Opera 12.10 and Firefox 18 and later support
    //   hidden = 'hidden';
    //   visibilityChange = 'visibilitychange';
    // } else {
    //   // @ts-ignore
    //   if (typeof document.msHidden !== 'undefined') {
    //     hidden = 'msHidden';
    //     visibilityChange = 'msvisibilitychange';
    //     // @ts-ignore
    //   } else if (typeof document.webkitHidden !== 'undefined') {
    //     hidden = 'webkitHidden';
    //     visibilityChange = 'webkitvisibilitychange';
    //   }
    // }

    // if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    //   //Not required for this browser so lets just reconnect on focus
    //   window.addEventListener('focus', restoreGameFromSession);
    // } else {
    //   document.addEventListener(visibilityChange, restoreGameFromSession, false);
    // }

    //FOR TESTING MULTIPLE THROWAWAY USERS
    // var cookies = document.cookie.split(';');
    // for (var i = 0; i < cookies.length; i++) {
    //   var cookie = cookies[i];
    //   var eqPos = cookie.indexOf('=');
    //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //   document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    // }
    // studentHadEnteredJoinCode('EVCQLC');
    // studentHadEnteredName('Auto', 'Test');
  }, []);

  const getCurrentContent = () => {
    if (props.StudentStore.currentRound?.status === 'STARTING') {
      // If a round is starting
      return <StudentGetReady />;
    } else if (props.StudentStore.currentRound?.status === 'INPROGRESS') {
      // If a round is in progress
      if (
        props.StudentStore.myLastAnswer?.answer &&
        props.StudentStore.myLastAnswer?.round === props.StudentStore.currentRound?.object_id
      ) {
        // If the student has submitted an answer
        return (
          <StudentsAnswerSection
            answerType={props.StudentStore.currentRound.answer_type}
            answered={props.StudentStore.myLastAnswer?.answer}
            myAnswer={props.StudentStore.myLastAnswer.answer}
          />
        );
      } else {
        // If the student has not submitted an answer
        return (
          <StudentAnswerQuestion
            question={props.StudentStore.currentRound.question}
            timeAllowed={props.StudentStore.currentRound.time_limit}
            answerType={props.StudentStore.currentRound.answer_type}
            fakeAnswers={props.StudentStore.currentRound.fake_answers}
            startTimestamp={props.StudentStore.currentRound.last_status_update}
            submitAnswerCallback={submitStudentAnswer}
          />
        );
      }
    } else if (props.StudentStore.currentRound?.status === 'FINISHING') {
      // If the round is finishing
      if (
        props.StudentStore.currentRound.chooser.substring(
          0,
          props.StudentStore.currentRound.chooser.lastIndexOf(':')
        ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) &&
        !props.StudentStore.currentRound.zoinkster
      ) {
        // If this student is the chooser and no answer is set for the round
        return <ZoinkSelect />;
      } else if (
        props.StudentStore.currentRound.chooser.substring(
          0,
          props.StudentStore.currentRound.chooser.lastIndexOf(':')
        ) !== props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) &&
        !props.StudentStore.currentRound.zoinkster
      ) {
        // If this student is NOT the chooser and no answer is set for the round
        return <ZoinkSelect />;
      } else if (props.StudentStore.currentRound.chooser === props.StudentStore.currentRound.zoinkster) {
        // If the chooser is the zoinkster it's a steal so let them know there is a steal in progress
        return <StealInProgress stealingTeam={getZoinkstersTeamEnum()} />;
      } else if (props.StudentStore.currentRound.zoinkster) {
        // If a zoinkster has been chosen lets show who was selected
        return (
          <div>
            <ZoinksterSelected
              zoinksterName={props.StudentStore.currentRound?.zoinkster?.substring(
                props.StudentStore.currentRound?.zoinkster?.lastIndexOf(':') + 1
              )}
              zoinksterTeam={getZoinkstersTeamEnum()}
              pointsToWin={3}
            />
          </div>
          // <AnswersSection
          //     answered={props.StudentStore.myLastAnswer?.answer}
          //     myAnswer={props.StudentStore.myLastAnswer.answer}
          //     zoinksterName={props.StudentStore.currentRound?.zoinkster?.substring(
          //         props.StudentStore.currentRound?.zoinkster?.lastIndexOf(':') + 1
          //     )}
          //     zoinksterAnswer={props.StudentStore.currentRound?.zoinkster_answer}
          // />
        );
      } else if (props.StudentStore.myLastAnswer?.answer) {
        // If this student knows the answer
        return (
          <AnswersSection
            answered={props.StudentStore.myLastAnswer?.answer}
            myAnswer={props.StudentStore.myLastAnswer.answer}
            zoinksterName={props.StudentStore.currentRound?.zoinkster?.substring(
              props.StudentStore.currentRound?.zoinkster?.lastIndexOf(':') + 1
            )}
            zoinksterAnswer={props.StudentStore.currentRound?.zoinkster_answer}
          />
        );
      } else {
        // If this student doesnt know the answer
        // return <NoAnswerPage />;
        return (
          <AnswersSection
            answered={false}
            zoinksterName={props.StudentStore.currentRound?.zoinkster?.substring(
              props.StudentStore.currentRound?.zoinkster?.lastIndexOf(':') + 1
            )}
            zoinksterAnswer={props.StudentStore.currentRound?.zoinkster_answer}
          />
        );
      }
    } else if (props.StudentStore.currentRound?.status === 'DONE') {
      // If the round is done
      if (props.StudentStore.backendGameInfo?.status === 'DONE') {
        // If the game has completely finished
        return <GameWinner winningTeam={getWinningTeamEnum()} gameCode={props.StudentStore.activeGameCode} />;
      } else if (getAnswerStatus() === AnswerEnum.Correct) {
        // If the answer was fully correct
        return (
          <AnswerStatus
            answerStatus={AnswerEnum.Correct}
            zoinksterName={props.StudentStore.currentRound?.zoinkster.substring(
              props.StudentStore.currentRound?.zoinkster.lastIndexOf(':') + 1
            )}
          />
        );
      } else if (getAnswerStatus() === AnswerEnum.PartiallyCorrect) {
        // If the answer was partially correct
        return (
          <AnswerStatus
            answerStatus={AnswerEnum.PartiallyCorrect}
            zoinksterName={props.StudentStore.currentRound?.zoinkster.substring(
              props.StudentStore.currentRound?.zoinkster.lastIndexOf(':') + 1
            )}
          />
        );
      } else {
        // If the answer was incorrect
        return (
          <AnswerStatus
            answerStatus={AnswerEnum.Incorrect}
            zoinksterName={props.StudentStore.currentRound?.zoinkster.substring(
              props.StudentStore.currentRound?.zoinkster.lastIndexOf(':') + 1
            )}
          />
        );
      }
    } else if (props.StudentStore.currentRound?.status === 'READY') {
      // If a round is ready
      return <StudentGetReady />;
    } else if (props.StudentStore?.activeGameCode && props.StudentStore?.myPlayerId) {
      // If no rounds have started yet and the player name and game code have been set
      return <StudentPickAvatar />;
    } else if (props.StudentStore?.activeGameCode) {
      // If we have a game code only let's collect the student name
      return (
        <AvatarEnterName
          submitStudentDetailsCallback={(firstName, lastInitial) => studentHadEnteredName(firstName, lastInitial)}
        />
      );
    } else {
      // If there is no game code entered let's start at the very beginning
      return (
        <AvatarEnterCode
          error={
            props.StudentStore?.error ||
            (props.StudentStore.kicked === true ? 'You were removed from the game by the host.' : null)
          }
          submitPlayCodeCallback={(gameCode) => studentHadEnteredJoinCode(gameCode)}
        />
      );
    }
  };

  return (
    <div>
      {getCurrentContent()}
      {props.StudentStore.currentRound?.status === 'DONE' && <Feedback />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(PlayPage);
