import styles from './AvatarLongLine.module.scss';
import React from 'react';
import AvatarName from '../../atoms/AvatarName/AvatarName';
import { connect, useDispatch } from 'react-redux';
import { studentUpdateZoinkster } from '../../redux/actions/studentActions';
import Timer from '../../atoms/Timer/Timer';
import chooser from '../../images/chooserStudent.png';

const AvatarLongLine = (props: any) => {
  const dispatch = useDispatch();

  const chooseZoinkster = (playerObjectId: string) => {
    studentUpdateZoinkster(playerObjectId.substring(0, playerObjectId.lastIndexOf(':')))(dispatch);
    return;
  };

  const randomSelectZoinkster = () => {
    //LET THE TEACHER DO THIS TO BE MORE RESILENT
  };

  const createAvatars = () => {
    const chooserPlayerId = props.StudentStore.currentRound?.chooser;
    let zoinksterOptions: { player_object_id: string; answer_status: number }[] = [];
    let zoinksterOptionsThatAnswered: { player_object_id: string; answer_status: number }[] = [];
    let myTeamIndex: number | null = null;

    props.StudentStore.fullTeamInfo?.forEach((teamInfo: any, teamIndex: number) => {
      teamInfo.players.forEach((player: any) => {
        if (player.player_object_id === chooserPlayerId) {
          // This is the team the chooser is on
          myTeamIndex = teamIndex;
        }
      });
    });

    if (myTeamIndex === 0) {
      zoinksterOptions = props.StudentStore.fullTeamInfo[1].players;
    } else if (myTeamIndex === 1) {
      zoinksterOptions = props.StudentStore.fullTeamInfo[0].players;
    }

    zoinksterOptionsThatAnswered = zoinksterOptions.filter((zoinksterOption) => zoinksterOption.answer_status === 1);

    if (zoinksterOptionsThatAnswered?.length > 0) {
      zoinksterOptions = zoinksterOptionsThatAnswered;
    }

    return zoinksterOptions.map((studentFromList: { player_object_id: string }) => {
      // If this student is the chooser then show a selectable component, otherwise disabled
      if (
        props.StudentStore.currentRound.chooser.substring(
          0,
          props.StudentStore.currentRound.chooser.lastIndexOf(':')
        ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':'))
      ) {
        return (
          <div
            className="small-12 medium-3 cell"
            onClick={() => chooseZoinkster(studentFromList.player_object_id)}
            key={studentFromList.player_object_id}>
            <AvatarName
              studentName={studentFromList.player_object_id.substring(
                studentFromList.player_object_id.lastIndexOf(':') + 1
              )}
              isSelectable={true}
            />
          </div>
        );
      } else {
        return (
          <div className="small-12 medium-3 cell greyAvatar" key={studentFromList.player_object_id}>
            <AvatarName
              studentName={studentFromList.player_object_id.substring(
                studentFromList.player_object_id.lastIndexOf(':') + 1
              )}
              isSelectable={false}
            />
          </div>
        );
      }
    });
  };

  return (
    <div>
      <div className={`${styles.timer}`}>
        {props.StudentStore.currentRound.chooser.substring(
          0,
          props.StudentStore.currentRound.chooser.lastIndexOf(':')
        ) === props.StudentStore.myPlayerId.substring(0, props.StudentStore.myPlayerId.lastIndexOf(':')) && (
          <Timer timeAllowed={15} timeUpCallback={randomSelectZoinkster} />
        )}
      </div>
      <div className={`${styles.mainContent} grid-x`}>{createAvatars()}</div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(AvatarLongLine);
