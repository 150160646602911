import React from 'react';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import BigRock from '../../atoms/BigRock/BigRock';
import BeginGame from '../../molecules/BeginGame/BeginGame';

const TeacherGameStarting = () => {
  document.body.classList.add('teacherArea');

  return (
    <div className="container">
      <div className="grid-container">
        <div className="grid-x">
          <div className="small-12 medium-2 cell paddingTop">
            <ZoinkLogo />
          </div>
        </div>
      </div>
      <BeginGame></BeginGame>
      <div className="fixedBottom">
        <BigRock></BigRock>
      </div>
    </div>
  );
};

export default TeacherGameStarting;
