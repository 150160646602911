import styles from './AvatarLine.module.scss';
import React from 'react';
import AvatarName from '../../atoms/AvatarName/AvatarName';
import { Player } from '../../redux/interfaces/storeInterface';

function AvatarLine(props: { allPlayers: Player[] }) {
  return (
    <div className={`${styles.mainContent}`}>
      {props.allPlayers?.map((studentFromList: Player) => {
        return (
          <div className={`${styles.avatar}`} key={studentFromList.object_id}>
            <AvatarName studentName={studentFromList.name} />
          </div>
        );
      })}
    </div>
  );
}

export default AvatarLine;
