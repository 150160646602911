import styles from './MoonRock.module.scss';
import moon from '../../images/moon.png';


function MoonRock() {
    return (
        <img src={moon} className={styles.moon} alt={'Decorative Moon Rock'} width={'100%'}/>
    );
}

export default MoonRock;
