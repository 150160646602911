import { TeacherStore } from '../interfaces/storeInterface';

function TeacherReducer(state: TeacherStore, action: { type: string; payload: any }) {
  if (action.type === 'TEACHER_ROUND_TIME_UPDATED') {
    let newState = { ...state };

    if (newState?.currentRound && action.payload.currentRound) {
      newState.currentRound = action.payload.currentRound;
    }

    return newState;
  } else if (action.type === 'TEACHER_ZOINKSTER_SWITCHED') {
    let newState = { ...state };

    if (newState?.currentRound) {
      newState.stealInProgress = true;
    }

    if (action.payload.partiallyCorrect) {
      newState.stealInProgress = 'PARTIAL';
    }

    return newState;
  } else if (action.type === 'TEACHER_TEAM_UPDATED') {
    let newState = { ...state };
    let updatedExistingRecord = false;
    newState.teamStatus?.forEach((teamStatus, teamIndex) => {
      if (teamStatus.object_id === action.payload.teamStatus.object_id) {
        // If the team is already in the teamStatus array let's use that team
        if (newState.teamStatus) {
          newState.teamStatus[teamIndex] = action.payload.teamStatus;
        }
        updatedExistingRecord = true;
      }
    });

    if (!updatedExistingRecord) {
      // If the team wasn't there already push a new team in
      if (!newState.teamStatus) {
        newState.teamStatus = [];
      }
      newState.teamStatus.push(action.payload.teamStatus);
    }
    return newState;
  } else if (action.type === 'TEACHER_ROUND_UPDATED') {
    let newState = { ...state, ...action.payload };

    if (newState.currentRound?.status === 'DONE') {
      newState.teamStatus?.forEach((teamStatus: any, teamIndex: number) => {
        teamStatus.players?.forEach((player: any, playerIndex: number) => {
          newState.teamStatus[teamIndex].players[playerIndex].answer_status = 0;
        });
      });
      newState.stealInProgress = false;
      newState.partiallyCorrect = false;
    }

    //Stop the answer explanation going missing due to some backend bug
    if (action.payload?.currentRound?.object_id === state.currentRound?.object_id) {
      // If the round is the same as last update
      if (!action.payload?.currentRound?.answer_explanation && state.currentRound?.answer_explanation) {
        // If it goes missing retain it in the store
        newState.currentRound.answer_explanation = state.currentRound?.answer_explanation;
      }
    }

    return newState;
  } else if (action.type === 'TEACHER_UPDATE_ROUND_TIME_LIMIT') {
    let newState = { ...state };

    if (newState.currentRound?.time_limit) {
      newState.currentRound.time_limit = newState.currentRound.time_limit + action.payload.add;
    }

    return newState;
  } else if (action.type === 'TEACHER_CREATE_GAME') {
    let newState = { question_set_object_id: state?.question_set_object_id };

    return newState;
  } else if (action.type.indexOf('TEACHER_') !== -1) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return state || {};
  }
}

export default TeacherReducer;
