import styles from './Button.module.scss';
import { useRef, useState } from 'react';

export enum ButtonTypeEnum {
  Primary,
  Secondary,
  longButton
}

export enum ButtonAreaEnum {
  Teacher,
  Student,
}

function Button(props: {
  text: string;
  type?: ButtonTypeEnum;
  area?: ButtonAreaEnum;
  size?:  ButtonTypeEnum;
  isDisabled?: boolean;
  disableLoader?: boolean;
  clickCallback: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const clickAction = (props: any) => {
    if (!props.isDisabled && !loading) {
      if (!props.disableLoader) {
        setLoading(true);
      }
      
      props.clickCallback();
    }
  }

  return (
    <button
      className={`${props.area === ButtonAreaEnum.Teacher ? styles.smallButton : styles.largeButton}
                ${props.type === ButtonTypeEnum.Primary ? styles.primary : styles.secondary}
                ${props.size === ButtonTypeEnum.longButton ? styles.primary : styles.secondary}
                ${props.isDisabled ? styles.isDisabled : ''}
                `}
      disabled={props.isDisabled || loading}
      onClick={() => clickAction(props)}>
      {loading ? <div className="loaderContainer">
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                </div> : props.text}
    </button>
  );
}

export default Button;
