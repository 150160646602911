import React from 'react';
import StudentHeader from '../../atoms/StudentHeader/StudentHeader';
import AnswersSection from "../../molecules/AnswersSection/AnswersSection";
import { Player, StudentStore } from '../../redux/interfaces/storeInterface';
import Incorrect from "../../atoms/Incorrect/Incorrect";
import IntroMusic from "../../atoms/IntroMusic/IntroMusic";

function IncorrectAnswerPage(props: { headerText?: string; footerText?: string; inline?: boolean }) {
  // document.body.classList.remove('teacherArea');
  return (
    <div>
      {!props.inline ? <StudentHeader /> : ''}
      <div className="container">
        <IntroMusic soundEffect={'INCORRECT'} isLoop={false} hideButton={true}/>

        <div className={'fullWidth centerText' + (props.inline ? '' : ' marginTopLarge')}>
          <div className="grid-x">
            <div className="small-10 small-offset-1 medium-8 medium-offset-2 cell">
              <h1 className="textWhite fontSize46 marginTopSmall">
                {props.headerText ? props.headerText : 'Incorrect Answer'}
              </h1>
              <div className="marginTop">
                <Incorrect />
              </div>
              <h2 className="fontSize26 lineHeight36 textWhite">
                {props.footerText ? props.footerText : 'Your team didn\'t earn any points!'}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncorrectAnswerPage;
