import React, { useEffect } from 'react';
import useSound from 'use-sound';
// @ts-ignore
import introAudio from '../audio/intro.mp3';

function SoundManager(props: { soundRef?: string }) {
  const [playIntro, { stop: stopIntro }] = useSound(introAudio, {
    interrupt: true,
  });
  let stopLastMusic: any = () => {};

  useEffect(() => {
    if (props.soundRef === 'intro') {
      playIntro();
      stopLastMusic = stopIntro;
    } else {
      stopLastMusic();
    }
  }, [props.soundRef]);

  const stopMusic = () => {
    stopLastMusic();
    // @ts-ignore
    window.soundRef = '';
  };

  return (
    <p>
      {/*<span*/}
      {/*  onClick={() => {*/}
      {/*    stopMusic();*/}
      {/*  }}>*/}
      {/*  STOP MUSIC*/}
      {/*</span>*/}
    </p>
  );
}

export default SoundManager;
