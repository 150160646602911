import styles from './AvatarEnterCode.module.scss';
import React, { useState } from 'react';
import AvatarHolding from '../../atoms/AvatarHolding/AvatarHolding';
import ZoinkLogo from '../../atoms/ZoinkLogo/ZoinkLogo';
import image from "../../images/question.png";

const AvatarEnterCode = (props: { error?: string; submitPlayCodeCallback: (gameCode: string) => void }) => {
  const [gameCode, setGameCode] = useState('');

  const submitPlayCode = () => {
    if (gameCode) {
      props.submitPlayCodeCallback(gameCode);
    }
  };

  const toTutorials = () => {
      window.location.href = '/tutorial'
  }
  return (
    <div>
      <div className={'help'}>
          <button className={'helpButton'} onClick={toTutorials}>
              <span>Tutorial</span> <img src={image} className={'helpIcon'} alt="helpIcon"/>
          </button>
      </div>
      <div className={`${styles.logo}`}>
        <ZoinkLogo></ZoinkLogo>
      </div>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.body}`}>
          <AvatarHolding></AvatarHolding>
          <div className={`${styles.inputSection}`}>
            <input
              id={'gameCode'}
              type={'text'}
              name={'text'}
              className={styles.enterCodeField}
              placeholder={'Enter Code'}
              onChange={(event) => setGameCode(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  submitPlayCode();
                }
                if (event.key === ' ') {
                  event.preventDefault();
                }
              }}
              maxLength={6}
              aria-autocomplete={'none'}
              autoComplete={'off'}
              autoFocus={true}
            />
          </div>
          <button className={`${styles.button}`} onClick={submitPlayCode}>
            Join Game
          </button>
            {props.error ? <p className={`${styles.errorMessage}`} >{props.error}</p> : ''}

        </div>
      </div>
    </div>
  );
};

export default AvatarEnterCode;
