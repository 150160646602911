import styles from './TeamBox.module.scss';
import React from 'react';
import AvatarLine from '../AvatarLine/AvatarLine';
import { connect } from 'react-redux';
import { TeacherStore } from '../../redux/interfaces/storeInterface';
import zoinkster from '../../images/zoinkster.png';
import chooser from '../../images/chooser.png';

export enum TeamEnum {
  Thunder,
  Lightning,
}

function TeamBox(props: {
  score: number;
  name: string;
  team?: TeamEnum;
  header?: string;
  chooserName?: string;
  TeacherStore?: TeacherStore;
}) {
  const getPlayersForTeam = () => {
    const currentTeamName = props.team === TeamEnum.Lightning ? 'Lightning' : 'Thunder';
    let currentTeamRef: string;
    props.TeacherStore?.backendGameInfo?.teams?.forEach((teamInStore) => {
      if (teamInStore.indexOf(currentTeamName) !== -1) {
        currentTeamRef = teamInStore.substring(0, teamInStore.lastIndexOf(':'));
      }
    });
    return props.TeacherStore?.allPlayers?.filter(
      (studentFromList: { name: string; object_id?: string; team: string }) => studentFromList.team === currentTeamRef
    );
  };

  const getZoinksterTeamName = (props: any) => {
    let zoinksterTeam = '';

    props.TeacherStore.allPlayers?.forEach((player: any) => {
      if (
        player.object_id ===
        props.TeacherStore.currentRound?.chooser.substring(0, props.TeacherStore.currentRound?.chooser.lastIndexOf(':'))
      ) {
        props.TeacherStore.backendGameInfo?.teams.forEach((team: any) => {
          if (team.indexOf(player.team) === -1) {
            zoinksterTeam = team.substring(team.lastIndexOf(':') + 1);
          }
        });
      }
    });

    return zoinksterTeam;
  };

  const showTeamSection = (props: any) => {
    //backendGameInfo chooser
    if (props.TeacherStore.currentRound.status !== 'DONE') {
      if (props.team === TeamEnum.Lightning && getZoinksterTeamName(props) === 'Lightning') {
        return (
          <div>
            <img src={zoinkster} className={styles.chooserAvatar} alt={'Zoinkster'} />
            <h2 className={styles.chooserText}>The Zoinksters</h2>
          </div>
        );
      } else if (props.team === TeamEnum.Thunder && getZoinksterTeamName(props) === 'Thunder') {
        return (
          <div>
            <img src={zoinkster} className={styles.chooserAvatar} alt={'Zoinkster'} />
            <h2 className={styles.chooserText}>The Zoinksters</h2>
          </div>
        );
      } else {
        return (
          <div>
            {props.chooserName ? <h2 className={styles.chooserText}>{props.chooserName}</h2> : ''}
            <img src={chooser} className={styles.chooserAvatar} alt={'Chooser'} />
            {!props.chooserName ? <h2 className={styles.chooserText}>The Choosers</h2> : ''}
          </div>
        );
      }
    } else {
      return <AvatarLine allPlayers={getPlayersForTeam() || []} />;
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div
        className={`${styles.teamWrapper}
             ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}`}>
        <div
          className={`${styles.header}
              ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''}`}>
          <h1 className={`${styles.teamName}`}>{props.name}</h1>
          <div className={`${styles.score}`}>
            <p className={`${styles.teamScore}`}>{props.score || 0}</p>
          </div>
        </div>
        {props.header ? <h3>{props.header}</h3> : ''}

        <div className={`${styles.contain}`}>{showTeamSection(props)}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(TeamBox);
