import styles from './TeamGameEnd.module.scss';
import green from '../../images/greenRaisedAvatar.png';
import yellow from '../../images/yellowRaisedAvatar.png';
import blue from '../../images/blueRaisedAvatar.png';
import purple from '../../images/purpleRaisedAvatar.png';
import darkPurple from '../../images/darkPurpleRaisedAvatar.png';
import red from '../../images/redRaisedAvatar.png';

export enum TeamEnum {
  Thunder,
  Lightning,
  Draw,
}

function TeamGameEnd(props: { name?: string; team?: TeamEnum; winners?: boolean }) {
  return (
    <div
      className={`${styles.teamWrapper}
            ${props.team === TeamEnum.Lightning ? styles.teamLightning : ''}
                ${props.team === TeamEnum.Thunder ? styles.teamThunder : ''} 
                ${props.winners ? styles.winningTeam : ''}`}>
      <img src={blue} alt={'avatar'} className={`${styles.avatarTop}`} />
      <img src={yellow} alt={'avatar2'} className={`${styles.avatarTop2}`} />
      <img src={green} alt={'avatar3'} className={`${styles.avatarTop3}`} />
      <img src={darkPurple} alt={'avatar4'} className={`${styles.avatar}`} />
      <img src={red} alt={'avatar5'} className={`${styles.avatar}`} />
      <img src={purple} alt={'avatar6'} className={`${styles.avatar}`} />
    </div>
  );
}

export default TeamGameEnd;
