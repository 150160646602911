/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addPlayerToGame = /* GraphQL */ `
  mutation AddPlayerToGame($input: Add_Player_To_Game_Input!) {
    add_Player_To_Game(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const addPlayerToTeam = /* GraphQL */ `
  mutation AddPlayerToTeam($input: Add_Remove_Player_From_Team_Input!) {
    add_Player_To_Team(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const addQuestionSet = /* GraphQL */ `
  mutation AddQuestionSet($input: Add_Question_Set_Input!) {
    add_Question_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const addQuestionsToSet = /* GraphQL */ `
  mutation AddQuestionsToSet($input: Add_Questions_To_Set_Input!) {
    add_Questions_To_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const addTime = /* GraphQL */ `
  mutation AddTime($input: Add_Time_Input!) {
    add_Time(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const awardPointsToTeam = /* GraphQL */ `
  mutation AwardPointsToTeam($input: Add_Team_Points_Input!) {
    award_Points_To_Team(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const changePassword = /* GraphQL */ `
  mutation ChangePassword($input: Change_Password_Input!) {
    change_Password(input: $input)
  }
`;
export const cleanGame = /* GraphQL */ `
  mutation CleanGame($input: Clean_Game_Input!) {
    clean_Game(input: $input)
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame($input: Create_Game_Input!) {
    create_Game(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const editQuestionInSet = /* GraphQL */ `
  mutation EditQuestionInSet($input: Edit_Question_In_Set_Input!) {
    edit_Question_In_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const editQuestionSet = /* GraphQL */ `
  mutation EditQuestionSet($input: Edit_Question_Set_Input!) {
    edit_Question_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($input: Forgot_Password_Input!) {
    forgot_Password(input: $input)
  }
`;
export const removePlayerFromGame = /* GraphQL */ `
  mutation RemovePlayerFromGame($input: Remove_Player_From_Game_Input!) {
    remove_Player_From_Game(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const removePlayerFromTeam = /* GraphQL */ `
  mutation RemovePlayerFromTeam($input: Add_Remove_Player_From_Team_Input!) {
    remove_Player_From_Team(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const removeQuestionFromSet = /* GraphQL */ `
  mutation RemoveQuestionFromSet($input: Remove_Question_From_Set_Input!) {
    remove_Question_From_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const removeQuestionSet = /* GraphQL */ `
  mutation RemoveQuestionSet($input: Remove_Question_Set_Input!) {
    remove_Question_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const resetAnswerStatuses = /* GraphQL */ `
  mutation ResetAnswerStatuses($input: Reset_Answer_Status_Input!) {
    reset_Answer_Statuses(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const resetRound = /* GraphQL */ `
  mutation ResetRound($input: Reset_Round_Input!) {
    reset_Round(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const setCanCopyPaste = /* GraphQL */ `
  mutation SetCanCopyPaste($input: Set_Can_Copy_Paste!) {
    set_Can_Copy_Paste(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const setChoosingTeam = /* GraphQL */ `
  mutation SetChoosingTeam($input: Set_Choosing_Team_Input!) {
    set_Choosing_Team(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const setPlayerAnswer = /* GraphQL */ `
  mutation SetPlayerAnswer($input: Set_Player_Answer_Input) {
    set_Player_Answer(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const setPlayerColor = /* GraphQL */ `
  mutation SetPlayerColor($input: Set_Player_Color_Input) {
    set_Player_Color(input: $input) {
      answer
      color
      model_id
      name
      object_id
      team
    }
  }
`;
export const setPlayerName = /* GraphQL */ `
  mutation SetPlayerName($input: Set_Player_Name_Input) {
    set_Player_Name(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const setQuestionSetCategory = /* GraphQL */ `
  mutation SetQuestionSetCategory($input: Set_Question_Set_Category_Input!) {
    set_Question_Set_Category(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const setQuestionSetPublic = /* GraphQL */ `
  mutation SetQuestionSetPublic($input: Set_Question_Set_Public_Input!) {
    set_Question_Set_Public(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const setRoundWinner = /* GraphQL */ `
  mutation SetRoundWinner($input: Set_Round_Winner_Input!) {
    set_Round_Winner(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const setZoinksterAnswer = /* GraphQL */ `
  mutation SetZoinksterAnswer($input: Set_Zoinkster_Answer_Input!) {
    set_Zoinkster_Answer(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const shuffleTeams = /* GraphQL */ `
  mutation ShuffleTeams($input: Model_Id_Input!) {
    shuffle_Teams(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const updateChooser = /* GraphQL */ `
  mutation UpdateChooser($input: Update_Chooser_Input!) {
    update_Chooser(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const updateGameStatus = /* GraphQL */ `
  mutation UpdateGameStatus($input: Update_Game_Status_Input!) {
    update_Game_Status(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const updatePlayerStatus = /* GraphQL */ `
  mutation UpdatePlayerStatus($input: Update_Player_Status_Input!) {
    update_Player_Status(input: $input) {
      answer
      color
      model_id
      name
      object_id
      team
    }
  }
`;
export const updateRoundStatus = /* GraphQL */ `
  mutation UpdateRoundStatus($input: Update_Round_Status_Input!) {
    update_Round_Status(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
      answer_explanation
    }
  }
`;
export const updateTeamName = /* GraphQL */ `
  mutation UpdateTeamName($input: Set_Team_Name_Input!) {
    update_Team_Name(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const updateZoinkster = /* GraphQL */ `
  mutation UpdateZoinkster($input: Update_Zoinkster_Input!) {
    update_Zoinkster(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
      answer_explanation
    }
  }
`;
