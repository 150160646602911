import styles from './CorrectionBar.module.scss';
import React, { useState } from 'react';
import IntroMusic from "../IntroMusic/IntroMusic";

export enum CorrectStatus {
  Incorrect,
  PartiallyCorrect,
  Correct,
}



function CorrectionBar(props: {
  answerStatusCallback: (amountOfPoints: number) => void;
  hidePartialCorrect?: boolean;
}) {
  const [correctLoading, setCorrectLoading] = useState(false);
  const [partiallyCorrectLoading, setPartiallyCorrectLoading] = useState(false);
  const [incorrectLoading, setIncorrectLoading] = useState(false);
    const [incorrectMusic, setIncorrectMusic] = useState(false);
    const [partiallyMusic, setPartiallyMusic] = useState(false);


    function submitIncorrect() {
        setTimeout(() => {
            setIncorrectMusic(true)
        }, 3000);
        props.answerStatusCallback(CorrectStatus.Incorrect);
        // setIncorrectMusic(true);

    }
    function submitPartiallyCorrect() {
        setTimeout(() => {
        setPartiallyMusic(true)
    }, 3000);
        props.answerStatusCallback(CorrectStatus.PartiallyCorrect);
        // setIncorrectMusic(true);

    }
  return (
    <div className={`${styles.wrapper} ${props.hidePartialCorrect ? styles.hidePartiallyCorrect : ''}`}>
        {/*<IntroMusic soundEffect={'GAMEPLAY'} isLoop={true}/>*/}

        {/*{incorrectMusic && <IntroMusic soundEffect={'INCORRECT'} isLoop={false} hideButton={true}/>}*/}
        {/*{partiallyMusic && <IntroMusic soundEffect={'PARTIALCORRECT'} isLoop={false} hideButton={true}/>}*/}
        <button
        className={`${styles.partiallyCorrect} ${props.hidePartialCorrect ? styles.hidden : ''}`}
        onClick={() => {setPartiallyCorrectLoading(true);submitPartiallyCorrect()}}>
        <p className={`${styles.title}`}>
        {partiallyCorrectLoading ? <div className="loaderContainer">
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                </div> : <span>Partially <span className={`${styles.nextLine}`}>Correct</span></span>}
        </p>
      </button>
      <button className={`${styles.correct}`} onClick={() => {setCorrectLoading(true);props.answerStatusCallback(CorrectStatus.Correct)}}>
        <p className={`${styles.title}`}>{correctLoading ? <div className="loaderContainer">
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                </div> : 'Correct'}</p>
      </button>
      <button className={`${styles.incorrect}`} onClick={() => {setIncorrectLoading(true);submitIncorrect()}}>
        <p className={`${styles.title}`}>{incorrectLoading ? <div className="loaderContainer">
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                </div> : 'Incorrect'}</p>
      </button>
    </div>
  );
}

export default CorrectionBar;
