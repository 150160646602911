import { useState } from 'react';

function MultiChoiceButtons(props: { buttons?: string[]; buttonSelectedCallback: (buttonString: string) => any }) {
  const [selectedButton, setSelectedButton] = useState<number | null>(null);

  const buttonSelected = (button: string, index: number) => {
    setSelectedButton(index);
    props.buttonSelectedCallback(button);
  };

  const choices = props.buttons?.map((button: string, index: number) => {
    return (
      <button
        className={(selectedButton === index ? 'selected ' : '') + 'button multi-choice multi-choice-option-' + index}
        onClick={() => buttonSelected(button, index)}>
        {button}
      </button>
    );
  });

  return <div>{choices}</div>;
}

export default MultiChoiceButtons;
