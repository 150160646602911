import { removePlayerFromTeam } from './../../graphql/mutations';
import { put, takeEvery, select, call } from 'redux-saga/effects';
import { StudentStore, TeacherStore } from '../interfaces/storeInterface';
import { API, graphqlOperation } from 'aws-amplify';
import {
  addPlayerToGame,
  awardPointsToTeam,
  createGame,
  setPlayerName,
  removePlayerFromGame,
  removeQuestionSet,
  resetAnswerStatuses,
  setChoosingTeam,
  setPlayerAnswer,
  updateGameStatus,
  updateRoundStatus,
  updateZoinkster,
  addTime,
} from '../../graphql/mutations';
import { getAllPlayers, getAllTeams, getPlayer } from '../../graphql/queries';
import LogRocket from 'logrocket';

const getCurrentStudentState = (state: any) => state.StudentStore;
const getCurrentTeacherState = (state: any) => state.TeacherStore;

const addUserToGame = async (gameObjectId?: string, firstName?: string, lastInitial?: string) => {
  return API.graphql(
    graphqlOperation(addPlayerToGame, {
      input: {
        game_object_id: gameObjectId,
        name: firstName + ' ' + lastInitial,
      },
    })
  );
};

const updatePlayerName = async (
  gameModelId?: string,
  playerObjectId?: string,
  firstName?: string,
  lastInitial?: string
) => {
  return API.graphql(
    graphqlOperation(setPlayerName, {
      input: {
        game_object_id: gameModelId,
        player_object_id: playerObjectId,
        name: firstName + ' ' + lastInitial,
      },
    })
  );
};
const removeUserFromGame = async (gameObjectId?: string, playerObjectId?: string, currentTeam?: string) => {
  const playerToRemove: any = await API.graphql(
    graphqlOperation(getPlayer, {
      input: {
        object_id: playerObjectId,
      },
    })
  );
  // if the player is assigned a team remove them from team before removing player
  if (playerToRemove?.data?.get_Player.team) {
    await API.graphql(
      graphqlOperation(removePlayerFromTeam, {
        input: {
          team_object_id: playerToRemove.data.get_Player.team,
          player_object_id: playerObjectId,
        },
      })
    );
  }
  return API.graphql(
    graphqlOperation(removePlayerFromGame, {
      input: {
        game_object_id: gameObjectId,
        player_object_id: playerObjectId,
      },
    })
  );
};

const getTeams = async (modelId?: string) => {
  return API.graphql(
    graphqlOperation(getAllTeams, {
      input: {
        model_id: modelId,
      },
    })
  );
};

const getPlayers = async (modelId?: string) => {
  return API.graphql(
    graphqlOperation(getAllPlayers, {
      input: {
        model_id: modelId,
      },
    })
  );
};

const getCurrentPlayer = async (objectId?: string) => {
  return API.graphql(
    graphqlOperation(getPlayer, {
      input: {
        object_id: objectId,
      },
    })
  );
};

function* editStudentName(data: any): any {
  try {
    const currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    yield call(
      updatePlayerName,
      currentTeacherState.backendGameInfo?.object_id,
      data.payload.player_object_id,
      data.payload.name
    );

    yield put({
      type: 'TEACHER_UPDATED_PLAYER_NAME',
    });
  } catch (e) {
    yield put({ type: 'TEACHER_UPDATED_PLAYER_NAME_FAILED' });
  }
}

function* removeStudentFromGame(data: any): any {
  try {
    const currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    yield call(
      removeUserFromGame,
      currentTeacherState.backendGameInfo?.object_id,
      data.payload.player_object_id,
      data.payload.team_object_id
    );

    yield put({
      type: 'TEACHER_REMOVED_PLAYER',
    });
  } catch (e) {
    yield put({ type: 'TEACHER_REMOVED_PLAYER_FAILED' });
  }
}

function* joinStudentToGame(): any {
  try {
    const currentStudentState: StudentStore = yield select(getCurrentStudentState);
    const addUserToGameResponse = yield call(
      addUserToGame,
      currentStudentState.activeGameCode?.toUpperCase(),
      currentStudentState.firstName,
      currentStudentState.lastInitial
    );

    if (currentStudentState.activeGameCode) {
      sessionStorage.setItem('gameCode', currentStudentState.activeGameCode?.toUpperCase());
      sessionStorage.setItem('myPlayerId', addUserToGameResponse.data.add_Player_To_Game.newest_player);
      if (window.location.host.indexOf('localhost') === -1) {
        LogRocket.identify(
          currentStudentState.activeGameCode?.toUpperCase() +
            '-' +
            addUserToGameResponse.data.add_Player_To_Game.newest_player
        );
      }
    }

    yield put({
      type: 'STUDENT_ADDED_TO_GAME',
      payload: {
        backendGameInfo: addUserToGameResponse.data.add_Player_To_Game,
      },
    });
  } catch (e) {
    yield put({ type: 'STUDENT_ADDED_TO_GAME_FAILED' });
  }
}

const createNewGame = async (object_id: string) => {
  return API.graphql(
    graphqlOperation(createGame, {
      input: {
        question_set_object_id: object_id,
        team_count: 2,
        team_names: ['Thunder', 'Lightning'],
      },
    })
  );
};

const deleteGame = async (object_id: string) => {
  return API.graphql(
    graphqlOperation(removeQuestionSet, {
      input: {
        question_set_object_id: object_id,
      },
    })
  );
};

const updateAGameStatus = async (gameModelId: string, gameObjectId: string) => {
  return API.graphql(
    graphqlOperation(updateGameStatus, {
      input: {
        game_model_id: gameModelId,
        game_object_id: gameObjectId,
      },
    })
  );
};

const updateARoundStatus = async (gameModelId?: string, gameObjectId?: string, roundObjectId?: string) => {
  return API.graphql(
    graphqlOperation(updateRoundStatus, {
      input: {
        game_model_id: gameModelId,
        game_object_id: gameObjectId,
        round_object_id: roundObjectId,
      },
    })
  );
};

const addTimeToRound = async (gameModelId?: string, time?: number, roundObjectId?: string) => {
  return API.graphql(
    graphqlOperation(addTime, {
      input: {
        game_model_id: gameModelId,
        time: time,
        round_object_id: roundObjectId,
      },
    })
  );
};

const updateAnAnswer = async (gameModelId?: string, answer?: string, roundObjectId?: string) => {
  return API.graphql(
    graphqlOperation(setPlayerAnswer, {
      input: {
        game_model_id: gameModelId,
        round_object_id: roundObjectId,
        answer: answer,
      },
    })
  );
};

const updateAZoinkster = async (gameModelId?: string, roundObjectId?: string, playerObjectId?: string) => {
  return API.graphql(
    graphqlOperation(updateZoinkster, {
      input: {
        game_model_id: gameModelId,
        round_object_id: roundObjectId,
        player_object_id: playerObjectId,
      },
    })
  );
};

const updateAPointsAward = async (
  gameObjectId?: string,
  teamObjectId?: string,
  points?: number,
  partiallyCorrect?: boolean
) => {
  return API.graphql(
    graphqlOperation(awardPointsToTeam, {
      input: {
        game_object_id: gameObjectId,
        team_object_id: teamObjectId,
        points,
        full_points: !partiallyCorrect,
      },
    })
  );
};

const switchChoosingTeam = async (gameObjectId?: string, gameModelId?: string) => {
  return API.graphql(
    graphqlOperation(setChoosingTeam, {
      input: {
        game_object_id: gameObjectId,
        game_model_id: gameModelId,
      },
    })
  );
};

const resetAllAnswerStatuses = async (gameModelId?: string, gameObjectId?: string, teamCount?: number) => {
  return API.graphql(
    graphqlOperation(resetAnswerStatuses, {
      input: {
        game_model_id: gameModelId,
        game_object_id: gameObjectId,
        team_count: teamCount,
      },
    })
  );
};

const setAZoinkster = async (gameModelId?: string, roundObjectId?: string, playerObjectId?: string) => {
  return API.graphql(
    graphqlOperation(updateZoinkster, {
      input: {
        game_model_id: gameModelId,
        round_object_id: roundObjectId,
        player_object_id: playerObjectId,
      },
    })
  );
};

function* createAGame(data: any): any {
  try {
    const createGameResponse = yield call(createNewGame, data.payload.question_set_object_id);
    yield put({
      type: 'TEACHER_GAME_CREATED',
      payload: {
        backendGameInfo: createGameResponse.data.create_Game,
      },
    });
    sessionStorage.setItem('currentGameDetails', JSON.stringify(createGameResponse.data.create_Game));
  } catch (e) {
    yield put({ type: 'TEACHER_GAME_CREATED_FAILED' });
  }
}

function* deleteAGame(data: any): any {
  try {
    yield call(deleteGame, data.payload.question_set_object_id);
    yield put({
      type: 'TEACHER_GAME_DELETED',
      payload: {},
    });
  } catch (e) {
    yield put({ type: 'TEACHER_GAME_DELETED_FAILED' });
  }
}

function* teacherUpdateGameStatus(data: any): any {
  try {
    const updateAGameStatusResponse = yield call(
      updateAGameStatus,
      data.payload.gameDetails.model_id,
      data.payload.gameDetails.object_id
    );

    yield call(resetAllAnswerStatuses, data.payload.gameDetails.model_id, data.payload.gameDetails.object_id, 2);

    const updateARoundStatusResponse = yield call(
      updateARoundStatus,
      data.payload.gameDetails.model_id,
      data.payload.gameDetails.object_id,
      updateAGameStatusResponse.data.update_Game_Status.status.substring(
        updateAGameStatusResponse.data.update_Game_Status.status.lastIndexOf(':') + 1
      )
    );
    updateAGameStatusResponse.data.update_Game_Status.currentRound =
      updateARoundStatusResponse.data.update_Round_Status;
    yield put({
      type: 'TEACHER_ROUND_STARTED',
      payload: {
        backendGameInfo: updateAGameStatusResponse.data.update_Game_Status,
      },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_GAME_CREATED_FAILED' });
  }
}

function* teacherEndGameUpdateStatus(data: any): any {
  try {
    const updateAGameStatusResponse = yield call(
      updateAGameStatus,
      data.payload.gameDetails.model_id,
      data.payload.gameDetails.object_id
    );

    yield put({
      type: 'TEACHER_GAME_ENDED',
      payload: {
        backendGameInfo: updateAGameStatusResponse.data.update_Game_Status,
      },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_GAME_ENDED_FAILED' });
  }
}

function* teacherUpdateRoundStatus(data: any): any {
  try {
    const updateARoundStatusResponse = yield call(
      updateARoundStatus,
      data.payload.gameDetails.model_id,
      data.payload.gameDetails.object_id,
      data.payload.gameDetails.round_id
    );
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    currentTeacherState.currentRound = updateARoundStatusResponse.data.update_Round_Status;

    yield put({
      type: 'TEACHER_ROUND_UPDATED',
      payload: {
        backendGameInfo: currentTeacherState.backendGameInfo,
      },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_ROUND_UPDATED_FAILED' });
  }
}

function* teacherIncreaseRoundTimeLimitStatus(data: any): any {
  try {
    const updateARoundTimeLimitResponse = yield call(
      addTimeToRound,
      data.payload.gameDetails.model_id,
      data.payload.gameDetails.time,
      data.payload.gameDetails.round_id
    );
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    currentTeacherState.currentRound = updateARoundTimeLimitResponse.data.add_Time;

    yield put({
      type: 'TEACHER_ROUND_TIME_UPDATED',
      payload: {
        currentRound: currentTeacherState.currentRound,
      },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_ROUND_TIME_UPDATE_FAILED' });
  }
}

function* studentGameUpdated(): any {
  try {
    let currentStudentState: StudentStore = yield select(getCurrentStudentState);
    const teams = yield call(getTeams, currentStudentState.backendGameInfo?.model_id);
    yield put({
      type: 'STUDENT_TEAM_UPDATED',
      payload: {
        fullTeamInfo: teams.data.get_All_Teams,
      },
    });
  } catch (e) {
    yield put({ type: 'STUDENT_TEAM_UPDATED_FAILED' });
  }
}

function* teacherGameUpdated(): any {
  try {
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    const currentPlayer = yield call(getPlayers, currentTeacherState.backendGameInfo?.model_id);
    yield put({
      type: 'TEACHER_PLAYERS_UPDATED',
      payload: {
        allPlayers: currentPlayer.data.get_All_Players,
      },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_PLAYERS_UPDATED_FAILED' });
  }
}

function* studentUpdateAnswer(data: any): any {
  try {
    let currentStudentState: StudentStore = yield select(getCurrentStudentState);
    const updateAnAnswerResponse = yield call(
      updateAnAnswer,
      currentStudentState.backendGameInfo?.model_id,
      data.payload.answer,
      currentStudentState.currentRound?.object_id
    );

    updateAnAnswerResponse.data.set_Player_Answer.answer = data.payload.answer;
    updateAnAnswerResponse.data.set_Player_Answer.round = currentStudentState.currentRound?.object_id;

    yield put({
      type: 'STUDENT_ROUND_ANSWER_UPDATED',
      payload: {
        backendGameInfo: currentStudentState.backendGameInfo,
        myLastAnswer: updateAnAnswerResponse.data.set_Player_Answer,
      },
    });
  } catch (e) {
    yield put({ type: 'STUDENT_ROUND_ANSWER_UPDATED_FAILED' });
  }
}

function* studentUpdateZoinkster(data: any): any {
  try {
    let currentStudentState: StudentStore = yield select(getCurrentStudentState);
    yield call(
      updateAZoinkster,
      currentStudentState.backendGameInfo?.model_id,
      currentStudentState.currentRound?.object_id,
      data.payload.playerObjectId
    );

    yield put({ type: 'STUDENT_UPDATED_ZOINKSTER' });
  } catch (e) {
    yield put({ type: 'STUDENT_UPDATE_ZOINKSTER_FAILED' });
  }
}

function* teacherForceUpdateZoinkster(): any {
  try {
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);

    if (!currentTeacherState.currentRound?.zoinkster) {
      //Randomly select a zoinkster
      let chooserTeamId: string;
      const chooserPlayerId = currentTeacherState.currentRound?.chooser?.substring(
        0,
        currentTeacherState.currentRound.chooser.lastIndexOf(':')
      );
      let zoinksterOptions: string[] = [];

      currentTeacherState.allPlayers?.forEach((teamInfo: any) => {
        if (teamInfo.object_id === chooserPlayerId) {
          chooserTeamId = teamInfo.team;
        }
      });

      currentTeacherState.allPlayers?.forEach((teamInfo: any) => {
        if (teamInfo.team !== chooserTeamId) {
          // This is the team to choose the Zoinkster from
          zoinksterOptions.push(teamInfo.object_id);
        }
      });

      const randomIndex = Math.floor(Math.random() * zoinksterOptions.length);

      yield call(
        updateAZoinkster,
        currentTeacherState.backendGameInfo?.model_id,
        currentTeacherState.currentRound?.object_id,
        zoinksterOptions[randomIndex]
      );

      yield put({ type: 'TEACHER_UPDATED_ZOINKSTER' });
    }
  } catch (e) {
    yield put({ type: 'TEACHER_UPDATED_ZOINKSTER_FAILED' });
  }
}

function* teacherAwardPoints(data: any): any {
  try {
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    yield call(
      updateAPointsAward,
      currentTeacherState.backendGameInfo?.object_id,
      data.payload.awardPointsDetails.team_object_id,
      data.payload.awardPointsDetails.points,
      data.payload.awardPointsDetails.partiallyCorrect
    );

    yield put({ type: 'TEACHER_AWARDED_POINTS' });

    // If this was a steal swap the team order back again after steal complete
    // if (currentTeacherState.stealInProgress) {
    //   yield call(
    //     switchChoosingTeam,
    //     currentTeacherState.backendGameInfo?.object_id,
    //     currentTeacherState.backendGameInfo?.model_id
    //   );
    // }

    if (data.payload.awardPointsDetails.startASteal) {
      yield put({
        type: 'TEACHER_ALLOW_STEAL',
        payload: { partiallyCorrect: data.payload.awardPointsDetails.partiallyCorrect },
      });
    } else {
      yield call(
        updateARoundStatus,
        currentTeacherState.backendGameInfo?.model_id,
        currentTeacherState.backendGameInfo?.object_id,
        currentTeacherState.currentRound?.object_id
      );
    }
  } catch (e) {
    yield put({ type: 'TEACHER_AWARDED_POINTS_FAILED' });
  }
}

function* teacherStartStealProcess(data: any): any {
  try {
    let currentTeacherState: TeacherStore = yield select(getCurrentTeacherState);
    // yield call(
    //   switchChoosingTeam,
    //   currentTeacherState.backendGameInfo?.object_id,
    //   currentTeacherState.backendGameInfo?.model_id
    // );

    yield call(
      setAZoinkster,
      currentTeacherState.backendGameInfo?.model_id,
      currentTeacherState.currentRound?.object_id,
      currentTeacherState.currentRound?.chooser?.substring(
        0,
        currentTeacherState.currentRound?.chooser.lastIndexOf(':')
      )
    );

    yield put({
      type: 'TEACHER_ZOINKSTER_SWITCHED',
      payload: { partiallyCorrect: data?.payload?.partiallyCorrect },
    });
  } catch (e) {
    yield put({ type: 'TEACHER_ZOINKSTER_SWITCHED_FAILED' });
  }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* appEffectsSagas() {
  yield takeEvery('STUDENT_UPDATE_DETAILS', joinStudentToGame);
  yield takeEvery('STUDENT_GAME_UPDATED', studentGameUpdated);
  yield takeEvery('STUDENT_UPDATE_ROUND_ANSWER', studentUpdateAnswer);
  yield takeEvery('STUDENT_UPDATE_ZOINKSTER', studentUpdateZoinkster);

  yield takeEvery('TEACHER_CREATE_GAME', createAGame);
  yield takeEvery('TEACHER_DELETE_GAME', deleteAGame);
  yield takeEvery('TEACHER_START_GAME', teacherUpdateGameStatus);
  yield takeEvery('TEACHER_GAME_UPDATED', teacherGameUpdated);
  yield takeEvery('TEACHER_UPDATE_ROUND', teacherUpdateRoundStatus);
  yield takeEvery('TEACHER_UPDATE_ROUND_TIME_LIMIT', teacherIncreaseRoundTimeLimitStatus);
  yield takeEvery('TEACHER_REMOVE_PLAYER', removeStudentFromGame);
  yield takeEvery('TEACHER_UPDATED_PLAYER_NAME', editStudentName);
  yield takeEvery('TEACHER_AWARD_POINTS', teacherAwardPoints);
  yield takeEvery('TEACHER_ALLOW_STEAL', teacherStartStealProcess);
  yield takeEvery('TEACHER_END_GAME', teacherEndGameUpdateStatus);
  yield takeEvery('TEACHER_FORCE_UPDATE_ZOINKSTER', teacherForceUpdateZoinkster);
}

export default appEffectsSagas;
