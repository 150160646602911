import styles from './SideBanner.module.scss';
import person from '../../images/icons/person.svg';
import circle from '../../images/icons/circle.svg';
import question from '../../images/icons/questions.svg';
import support from '../../images/icons/support.svg';
import React from 'react';
import { wowzaCreatePortalSession } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

function SideBanner(props: { teacherName: string }) {
  const setupCustomerPortal = async () => {
    return API.graphql(graphqlOperation(wowzaCreatePortalSession));
  };

  const navigateToCustomerPortal = () => {
    setupCustomerPortal().then((response: any) => {
      window.location.href = response.data.wowza_Create_Portal_Session.url;
    });
    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.header}>
          <img src={person} alt={'person'} className={styles.icons} />
          <p className={styles.name}>{props.teacherName}</p>
        </div>

        <div className={styles.questionSec}>
          <img src={circle} alt={'person'} className={styles.circle} />
          <img src={question} alt={'person'} className={styles.questionIcons} />
          <p className={styles.question}>Question Sets</p>
        </div>
        <div className={styles.questionSec}>
          <div className={styles.circleNotVisible} />
          <a href={'#'} onClick={navigateToCustomerPortal}>
            <img src={support} alt={'person'} className={styles.questionIcons} />
            <p className={styles.question}>Manage Account</p>
          </a>
        </div>
        <div className={styles.questionSec}>
          <div className={styles.circleNotVisible} />
          <a href={'https://support.wowzalearning.com'}>
            <img src={support} alt={'person'} className={styles.questionIcons} />
            <p className={styles.question}>Support</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SideBanner;
