/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllGameSummaries = /* GraphQL */ `
  query GetAllGameSummaries {
    get_All_Game_Summaries {
      answers {
        name
      }
      model_id
      object_id
      time_started
    }
  }
`;
export const getAllPlayers = /* GraphQL */ `
  query GetAllPlayers($input: Model_Id_Input!) {
    get_All_Players(input: $input) {
      answer
      color
      model_id
      name
      object_id
      team
    }
  }
`;
export const getAllQuestionSets = /* GraphQL */ `
  query GetAllQuestionSets {
    get_All_QuestionSets {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const getAllRounds = /* GraphQL */ `
  query GetAllRounds($input: Model_Id_Input!) {
    get_All_Rounds(input: $input) {
      answer
      answer_type
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
      answer_explanation
    }
  }
`;
export const getAllTeams = /* GraphQL */ `
  query GetAllTeams($input: Model_Id_Input!) {
    get_All_Teams(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($input: Object_Id_Input!) {
    get_Game(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const getGameById = /* GraphQL */ `
  query GetGameById($input: Model_Object_Id_Input!) {
    get_Game_By_Id(input: $input) {
      chooser
      model_id
      newest_player
      object_id
      players
      rounds
      status
      teams
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($input: Object_Id_Input!) {
    get_Player(input: $input) {
      answer
      color
      model_id
      name
      object_id
      team
    }
  }
`;
export const getPublicQuestionSets = /* GraphQL */ `
  query GetPublicQuestionSets($input: Get_Public_Question_Sets_Input) {
    get_Public_Question_Sets(input: $input) {
      items {
        category
        date_created
        description
        grades
        last_updated
        model_id
        name
        object_id
        plays
        public
        status
      }
      nextToken
    }
  }
`;
export const getQuestionSet = /* GraphQL */ `
  query GetQuestionSet($input: Object_Id_Input!) {
    get_Question_Set(input: $input) {
      category
      date_created
      description
      grades
      last_updated
      model_id
      name
      object_id
      plays
      public
      questions {
        answer
        answer_type
        fake_answers
        potential_points
        question
        time_limit
        answer_explanation
      }
      status
    }
  }
`;
export const getRound = /* GraphQL */ `
  query GetRound($input: Object_Id_Input!) {
    get_Round(input: $input) {
      answer
      answer_type
      answer_explanation
      awarded_points {
        full_points
        points
        team_object_id
      }
      chooser
      fake_answers
      is_stealing
      last_status_update
      model_id
      name
      object_id
      question
      status
      time_limit
      winner
      zoinkster
      zoinkster_answer
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($input: Object_Id_Input!) {
    get_Team(input: $input) {
      awarded_points
      model_id
      name
      object_id
      players {
        answer_status
        color
        name
        player_object_id
      }
    }
  }
`;
export const wowzaCreateCheckoutSession = /* GraphQL */ `
  query WowzaCreateCheckoutSession($input: Wowza_Create_Checkout_Session_Input!) {
    wowza_Create_Checkout_Session(input: $input) {
      payment_intent_id
      url
    }
  }
`;
export const wowzaCreatePortalSession = /* GraphQL */ `
  query WowzaCreatePortalSession {
    wowza_Create_Portal_Session {
      url
    }
  }
`;
